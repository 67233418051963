import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b14823',
  templateUrl: './blogs-details-b14823.component.html',
  styleUrls: ['./blogs-details-b14823.component.css']
})
export class BlogsDetailsB14823Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}
function init(context: BlogsDetailsB14823Component) {
  context.title = "Digital Immune System: Cybersecurity's Evolution in the Digital Era | Skillikz Blog";
  context.description = "Discover the concept of the Digital Immune System, its significance in modern cybersecurity, and why it's the future of safeguarding digital ecosystems in our ever-evolving digital age.";
  context.keywords = 'Digital Immune System, Cybersecurity Resilience, Threat Detection, Threat Intelligence, Incident Response, Continuous Monitoring, Perimeter Security, Endpoint Security, Identity and Access Management (IAM), Security Information and Event Management (SIEM), Cybersecurity Awareness, Real-Time Surveillance, Data Breaches, Robust Security Controls, Collaboration in Cybersecurity, Regular Security Audits, Customer Experience (CX), System Downtime Prevention, Vulnerability Management, Resilient Digital Ecosystems';
  context.seoTags();
}