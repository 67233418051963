import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})

export class TermsOfUseComponent extends BaseClass implements OnInit {
  constructor() {
    super()
   }

  ngOnInit(): void {
    init(this);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}

function init(context: TermsOfUseComponent) {  
  context.title = 'Terms of Use - SKILLIKZ';
  context.description = 'Contact Skillikz for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'contact us, Skillikz, technology solutions, consulting services, IT product development, modernization, digital transformation';
  context.seoTags();
}
