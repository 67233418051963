import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b4823',
  templateUrl: './blogs-details-b4823.component.html',
  styleUrls: ['./blogs-details-b4823.component.css']
})
export class BlogsDetailsB4823Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}
function init(context: BlogsDetailsB4823Component) {  
  context.title = "Business Automation in 2023: From 'Nice-To-Have' to Necessity - Skillikz Blogs";
  context.description = "Explore how Business automation has transformed from a luxary to a necessity in 2023, driving efficiency, competitiveness, and sustainability. Understand the benifits, challenges, and how to navigate the shift.";
  context.keywords = 'Business Automation, Robotic Process Automation (RPA), AI-Powered Solutions, Machine Learning, Operational Efficiency, Process Streamlining, Real-Time Analytics, Data-Driven Decisions, Automation Tools, Remote Work Enablement, Digital Transformation, Resource Allocation, Customer Service Automation, Employee Reskilling, Sustainability Goals, Cost Reduction, Competitive Edge, Trend Forecasting, Automated Collaboration, Strategic Innovation';
  context.seoTags();
}
