<div class="breadcrumb-wp">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/" class="linkHomeIcon">
          <img src="../../../../assets/images/icons/home-icon.svg" alt="Home">
        </a></li>
      <li class="breadcrumb-item active" *ngFor="let url of urlList;let rec = index" routerLink="/{{url.page}}">
        <span class="hyperLink" *ngIf="rec!=urlList.length-1">{{url.name}}</span>
        <!-- <a *ngIf="rec!=urlList.length-1">{{url.name}}</a> -->
        <span *ngIf="rec==urlList.length-1">{{url.name}}</span>
      </li>
    </ol>
  </nav>
</div>