<h2 class="large">Why Work with Us</h2>
<div class="images-wrapper scroll-elem fade-in-bottom">
    <div class="">
        <div class="card-images">
            <img src="assets/images/people-img.webp" alt="People img" width="504" height="805"
                onerror="this.src='assets/images/people-img.png'">
            <div class="">
                <h3>People</h3>
                <p>We listen, learn and laugh and create together.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/growth-img.webp" alt="Growth img" width="504" height="805"
                onerror="this.src='assets/images/growth-img.png'">
            <div class="">
                <h3>Growth</h3>
                <p>It's fast, exciting, innovative and long-standing.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/culture-img.webp" alt="Logistics img" width="504" height="805"
                onerror="this.src='assets/images/culture-img.png'">
            <div class="">
                <h3>Culture</h3>
                <p>It's open, collaborative, and builds friendships.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/voice-img.webp" alt="Voice img" width="504" height="805"
                onerror="this.src='assets/images/voice-img.png'">
            <div class="">
                <h3>Voice</h3>
                <p>We listen, learn and laugh and create together.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/buzz-img.webp" alt="Buzz img" width="504" height="805"
                onerror="this.src='assets/images/buzz-img.png'">
            <div class="">
                <h3>Buzz</h3>
                <p>Fast-paced and challenging, but always rewarding.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/trust-img.webp" alt="Trust img" width="504" height="805"
                onerror="this.src='assets/images/trust-img.png'">
            <div class="">
                <h3>Trust</h3>
                <p>We all have a job to do, and we're trusted to do it.</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/perks-img.webp" alt="Perks img" width="504" height="805"
                onerror="this.src='assets/images/perks-img.png'">
            <div class="">
                <h3>Perks</h3>
                <p>Get an extra day off on your birthday!</p>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card-images">
            <img src="assets/images/development-img.webp" alt="Development img" width="504" height="805"
                onerror="this.src='assets/images/development-img.png'">
            <div class="">
                <h3>Development</h3>
                <p>We're support in growing your career.</p>
            </div>
        </div>
    </div>
</div>