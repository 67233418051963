import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details15',
  templateUrl: './blogs-details15.component.html',
  styleUrls: ['./blogs-details15.component.css']
})
export class BlogsDetails15Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails15Component) {  
  context.title = 'Importance of ERP Systems in Modern Business Operations ';
  context.description = 'Discover how ERP systems can increase efficiency, improve data management, enhance customer service, and ensure regulatory compliance in your business';
  context.keywords = 'Enterprise Resource Planning (ERP), Efficiency Improvement, Productivity Enhancement, Data Management, Centralized Data Repository, Real-time Insights, Workflow Automation, Customer Service Improvement, Cross-selling Opportunities, Upselling Opportunities, Supply Chain Management, Inventory Management, Production Scheduling, Shipping Information, Cost Reduction, Delivery Optimization, Regulatory Compliance, Auditing Reports, Legal Requirements, Competitive Advantage';
  context.seoTags();
}
