import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study9',
  templateUrl: './case-study9.component.html',
  styleUrls: ['./case-study9.component.css']
})
export class CaseStudy9Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy9Component) {  
  context.title = 'Underwriting Automation | Tech Consulting Services | Skillikz';
  context.description = "Explore how Skillikz's tech consulting services revolutionized underwriting for a lending finance organization. Discover our innovative solutions, including predictive analysis, open banking data integration, and cognitive services, leading to faster loan approvals, reduced risk, and improved compliance.";
  context.keywords = 'Underwriting Technology Consulting, Insurance Underwriting Solutions, Digital Transformation in Underwriting, Underwriting Automation Consulting, AI in Underwriting, Tech-Driven Underwriting Solutions, Underwriting Process Optimization, Data Analytics in Underwriting, Cloud-Based Underwriting Solutions, Underwriting Risk Management, Digital Underwriting Platforms, Underwriting Automation Software, Underwriting System Integration, Underwriting Data Security Solutions, Underwriting Decision Support Systems, Machine Learning in Underwriting, Insurtech Consulting for Underwriting, Underwriting AI Solutions, End-to-End Underwriting Automation, Underwriting Technology Consulting Services';
  context.seoTags();
}
