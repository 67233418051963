import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-other-course-catalogues',
  templateUrl: './other-course-catalogues.component.html',
  styleUrls: ['./other-course-catalogues.component.css']
})

export class OtherCourseCataloguesComponent implements OnInit {

  @Input()url;
  constructor(public router: Router) { }

  ngOnInit(): void {
  }
}
