import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study10',
  templateUrl: './case-study10.component.html',
  styleUrls: ['./case-study10.component.css']
})
export class CaseStudy10Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy10Component) {  
  context.title = 'Customer Experience Case Study | Skillikz';
  context.description = 'Discover how Skillikz transformed the online presence of a mid-sized e-commerce company, improving user experience, reducing bounce rates, and increasing conversions. Explore our customer experience (CX) case study.';
  context.keywords = 'Customer Experience Solutions, Customer Experience Strategy, Personalized Customer Experience, Omnichannel Customer Experience, Customer Experience Management (CXM), Customer Journey Mapping, Customer Engagement Solutions, Customer Satisfaction Optimization, Seamless Customer Experience, Customer Loyalty Programs, Customer Feedback Tools, Voice of the Customer (VoC), Digital Customer Experience, CX Optimization, Customer Retention Strategies, AI-Powered Customer Experience, Multichannel Customer Support, User Experience (UX) Design, Customer Experience Analytics, Customer Service Excellence';
  context.seoTags();
}
