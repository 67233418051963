import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-product-engineering',
  templateUrl: './product-engineering.component.html',
  styleUrls: ['./product-engineering.component.css']
})
export class ProductEngineeringComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: ProductEngineeringComponent) {  
  context.title = 'IT Product Engineering Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers comprehensive IT product engineering services including custom software development, web application development, and mobile app development. Our agile software development approach ensures that your project is delivered on time, within budget, and with high quality. Contact us today to learn more.';
  context.keywords = 'Product Engineering Services, Product Lifecycle Management, Product Development, Agile Product Engineering, Rapid Prototyping, Product Innovation, Digital Product Engineering, IoT Product Development, Software Product Engineering, Embedded Systems Engineering, Product Modernization, Full-Stack Development, UX/UI Design for Products, End-to-End Product Solutions, Scalable Product Architecture, Product Engineering Consulting, Custom Product Development, DevOps in Product Engineering, Cloud Product Engineering, AI-Driven Product Development';
  context.seoTags();
}