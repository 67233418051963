import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-quallity-engineer-it',
  templateUrl: './quallity-engineer-it.component.html',
  styleUrls: ['./quallity-engineer-it.component.css']
})
export class QuallityEngineerItComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: QuallityEngineerItComponent) {
  context.title = 'Quality Engineer Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for experienced QA professional with 5-9 years' expertise in diverse testing methodologies, automation, Agile environments, database proficiency, andCI/CD tools for efficient quality assurance as a Quality Engineer.`;
  context.keywords = 'Quality Engineer Services, Quality Assurance Engineer, Quality Engineering Solutions, Software Quality Engineer, Quality Control Engineering, QA/QC Engineer, Quality Assurance Testing, Product Quality Engineer, Reliability Engineering, Process Improvement Engineer, Automation Testing Engineer, Engineering Quality Standards, Quality Management System (QMS), Quality Engineer Certification, Root Cause Analysis Engineer, Continuous Improvement Engineer, ISO Quality Standards, Six Sigma Quality Engineer, Quality Assurance Process, Lean Manufacturing Engineer';
  context.seoTags();
}
