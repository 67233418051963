import { Component } from '@angular/core';

@Component({
  selector: 'app-why-work-with-us',
  templateUrl: './why-work-with-us.component.html',
  styleUrls: ['./why-work-with-us.component.css']
})
export class WhyWorkWithUsComponent {

}
