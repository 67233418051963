import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { EmailContactUs } from '../../../modals/email-contact-us.modal';
import { DataTransferService } from '../../../modules/core/base/api.service';
import { BaseClass } from '../../../modules/core/base/base-class';
import { Constants } from '../../../modules/core/base/constants';
import { ContactUsService } from '../../contact-us/contact-us.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends BaseClass implements OnInit {

  contactUsFormGroup: FormGroup;
  submitted: boolean = false;
  emailContactUs: EmailContactUs = new EmailContactUs();
  showCopied: boolean = false;
  constructor(public contactUsService: ContactUsService, public constants: Constants, public dataTransfer: DataTransferService) { super() }

  ngOnInit(): void {
    this.gotoTopFunction();
    window.addEventListener('scroll', this.showScrolltopbtn, true);
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);
    
    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  onSuccess(response: any) {
    // thank you page
    this.dataTransfer.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }

  onError(errorCode: number, errorMsg: string) {
    // error page
  }

  copyToClipboard(text: string) {
    this.copyTextToClipboard(text);
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000); // Hide the message after 2 seconds
  }

  copyTextToClipboard(text: string) {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
  sendToCal(){
    var d = new Date().getFullYear().toString() + '-' + '0' + (new Date().getMonth() + 1).toString().slice(-2);
    const url = 'https://calendly.com/skillikz/30min?back=1&month='+d;
    window.open(url, '_blank');
  }
}
function init(context: FooterComponent) {
  context.title = 'Get in Touch with Skillikz Ltd for Expert IT Solutions | Contact Us';
  context.description = 'Contact Skillikz for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'contact us, Skillikz, technology solutions, consulting services, IT product development, modernization, digital transformation';
  context.seoTags();

  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);

}

function addFormValidation(context: FooterComponent){
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, Validators.required),
    lastName: new FormControl(context.emailContactUs.lastName),
    phoneNumber: new FormControl(context.emailContactUs.phoneNumber),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });
}
