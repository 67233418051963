<section class="sectionInnerBanner topMargin">
    <div class="container-main">

     <app-breadcrumb></app-breadcrumb>

      <div class="topInnerBanner">
        <div class="itemTopContent">
          <div class="">
            <h1><span>Application</span> Management Service</h1>
            <div class="onlyForMbl">
              <img src="assets/images/ams-img.webp" alt="AMS img" width="336" height="336"
                onerror="this.src='assets/images/ams-img.png'">
            </div>
            <p class="md bold mb-0">During the Business Digital Transformation (BDT) for one of the FinTech clients, which
              included modernization of 20+ legacy applications, Skillikz proposed to set up a 24*7 application and infrastructure
              support model to support business continuity and growth. This was in alignment with Client’s long-term objective of
              moving to new-edge solutions with minimal disruption to business operations.</p>
          </div>
        </div>
        <div class="itemsImage">
          <div class="">
            <img src="assets/images/ams-img.webp" alt="AMS img" 
            width="336" height="336" onerror="this.src='assets/images/ams-img.png'">
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="section-client-details">
    <div class="container-main">
      <ul>
        <li><span>Client: </span>Car Dealership & Lending Firm</li>
        <li><span>Country: </span>UK</li>
        <li><span>Industry: </span>Fin Tech</li>
        <li><span>Engagement Type: </span>BDT / Staff Aug / Project Based / Technology Consulting</li>
      </ul>
    </div>
  </section>

  <section class="sectionCommonLightBlue border-b">
    <div class="container-main">
      <div class="mb-40">
        <h2 class="large">The Challenge</h2>
        <div class="row-flex">
          <div class="items-col-8 mb-15 itemCenter">
            <h3 class="regular-text noMargin">The client’s business was growing at a rapid pace. Existing systems were not fit to
              support this growth. However, existing applications needed to be well-supported and performant until the sunset
              timeline. Some of the challenges faced by the Client are below:</h3>
          </div>
          <div class="items-col-1">
            <hr class="border">
            <br>
            <ul class="regular bullet listCol-2">
              <li>Lack of well-defined and measured SLAs and SLTs.</li>
              <li>Business users needed help in user Training and Onboarding.</li>
              <li>Few legacy applications running on outdated technologies cause business risk.</li>
              <li>Some processes require manual intervention and high cost of ownership.</li>
              <li>Lack of process-oriented approach resulting into people dependency, keeping business at risk all the time.</li>
              <li>Absence of key operation metrices like First Contact Resolution Rate (FCRR), Mean Time to Resolve (MTTR) etc.</li>
              <li>No round-the-clock assistance, ensuring that customers can access help whenever they need it, regardless of time zones.</li>
            </ul>
          </div>
        </div>
      </div>


      <div class="">
        <h2 class="large">Drive to accomplishment</h2>
        <div class="row-flex">
          <div class="items-col-8 mb-15">
            <h3 class="regular-text mb-15">In collaboration with our Client, Skillikz proposed an end-2-end Application Managed
              Service (AMS) including 20+ applications and Client's complete on-premises and Cloud infrastructure. The goals was
              to streamline the support process, stabilize the existing applications and reduce the cost of support and operations
              by 25% year on year.</h3>
            <p class="mb-0">Followings are some of the works carried out by Skillikz team:</p>
          </div>
          <div class="items-col-1 mb-15">
            <hr class="border mb-15">
            <ul class="regular bullet listCol-2">
              <li>Led the Client's organizational change management process to adapt to ITIL standards and processes (Incident
                management, Problem management, Change Management)</li>
              <li>Established a joint Service Integration and Management office focusing on IT infrastructure library (ITIL) process
                integration and standardization.</li>
              <li>Documented the business processes, technology implementation and process baseline of more than 20+ applications
                during the transition phase and created a knowledge management portal.</li>
              <li>Set up an Academy for the Client with proven Knowledge Management framework to enable business ready resources at
                an optimum cost. This resulted in minimal business impact due to ramp-up / ramp-down of the team.</li>
                <li>Application monitoring and implement changes through change management process</li>
                <li>Preventive maintenance - Root-cause analysis (RCA) for recurring issues</li>
                <li>24 x 7 Application Management & Support Services</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="commonSection bgWhite marginNone border-b">
    <div class="container-main">
      <h2 class="large">The Outcome</h2>
      <div class="row-flex">
        <div class="items-col-8 mb-15 itemCenter">
          <h3 class="regular-text mb-0">The implementation of process-oriented AMS yielded remarkable results</h3>          
        </div>
        <div class="items-col-1">
          <hr class="border mb-15">
          <ul class="regular bullet listCol-2">
            <li>Quick issue resolution and guidance from support teams enable users to work efficiently and complete tasks
              without interruptions, boosting overall productivity.</li>
            <li>Businesses can concentrate on their core activities and goals instead of diverting resources and efforts
              towards troubleshooting technical issues.</li>
            <li>Reduced total cost of ownership for Client as AMS cost reduced by 25% at the beginning of Year 2 </li>
            <li>Reduced recurring high priority incidents by 20% in the first year of AMS</li>
            <li>Mitigate risk by 'Shift left' approach detected flaws at early stage, and provided more than 50 L1 knowledge
              articles to the service desk teams for improving triage, ticket prioritization, and assignment, thereby improving
              customer satisfaction to 95%+</li>
          </ul>
        </div>
      </div>
    </div>
  </section>



  <section class="sectionForm">
    <div class="container-main">
      <div class="row-flex">
        <div class="custom-col-55">
          <div class="formContent scroll-elem slide-left">
            <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's redefine
                your online presence together.</span></h2>
            <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone, email or
              text message.</p>
          </div>
        </div>
        <div class="custom-col-45">
          <!-- Contact Us form component -->
          <app-form></app-form>
        </div>
      </div>
    </div>
  </section>


<!-- ---Read More component here--- -->
<app-read-more></app-read-more>
