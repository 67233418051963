import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details18',
  templateUrl: './blogs-details18.component.html',
  styleUrls: ['./blogs-details18.component.css']
})
export class BlogsDetails18Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetails18Component) {  
  context.title = 'New Trends in DevOps and Agile Development for Efficient Software Development';
  context.description = 'DevOps, Agile development, AIOps, low-code, no-code development, DevSecOps, value stream management, software development, collaboration, automation, efficiency, quality, cloud-native development.';
  context.keywords = 'DevOps, Agile Methodology, Software Development, IT Operations, Automation, Collaboration, Communication, Time-to-Market, Software Quality, AIOps, Artificial Intelligence (AI), Machine Learning (ML), Low-Code Development, No-Code Development, DevSecOps, Security Integration, Continuous Delivery, Value Stream Management, Development Lifecycle, Team Collaboration';
  context.seoTags();
}