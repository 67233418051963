import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { BaseClass } from 'src/app/modules/core/base/base-class';
import { EmailContactUs } from '../../modals/email-contact-us.modal';
import { DataTransferService } from '../../modules/core/base/api.service';
import { Constants } from '../../modules/core/base/constants';
import { ContactUsService } from './contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends BaseClass implements OnInit {
  contactUsFormGroup: FormGroup;
  emailContactUs: EmailContactUs = new EmailContactUs();
  submitted: boolean = false;
  constructor(public contactUsService: ContactUsService, 
    public constants: Constants,
    public dataTransfer: DataTransferService) { super() }

  ngOnInit(): void {
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  onSuccess(response: any) {
    // thank you page
    this.dataTransfer.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }

  onError(errorCode: number, errorMsg: string) {
    // error page
  }
}
function init(context: ContactUsComponent) {
  context.title = 'Get in Touch with Skillikz Ltd for Expert IT Solutions | Contact Us';
  context.description = 'Contact Skillikz for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'Contact Information, Get in Touch, Reach Us, Contact Form, Customer Support Contact, Contact Us Page, Connect with Us, Business Inquiries, Request a Call, Contact Details, Talk to Our Team, Get Support, Email Us, Customer Service Contact, Live Chat Support, Contact Our Office, Schedule a Meeting, Contact Sales Team, Contact Us for Services, How to Reach Us';
  context.seoTags();

  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);

}

function addFormValidation(context: ContactUsComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    lastName: new FormControl(context.emailContactUs.lastName),
    // phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });
}
