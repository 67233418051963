import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-chaos-resiliency-test-engineer',
  templateUrl: './chaos-resiliency-test-engineer.component.html',
  styleUrls: ['./chaos-resiliency-test-engineer.component.css']
})
export class ChaosResiliencyTestEngineerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ChaosResiliencyTestEngineerComponent) {  
  context.title = 'Chaos & Resiliency Test Engineer - SKILLIKZ';
  context.description = 'We are looking for someone with Strong SRE & DevOps experience to help us test the resiliency of our application by applying the Chaos engineering best practices.';
  context.keywords = 'Chaos Engineering, Resiliency Testing, Fault Injection, System Reliability, Failure Simulations, High Availability Testing, Distributed Systems Testing, Fault Tolerance, Load Testing, Stress Testing, Continuous Testing, Failure Recovery, Disaster Recovery Testing, Microservices Resiliency, Load Balancer Testing, Capacity Testing, System Failover, Kubernetes Resiliency, Circuit Breaker Testing, Observability (Monitoring & Logging)';
  context.seoTags();
}
