<section class="commonSection topMargin pt-20">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1>Quality Engineer</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>UK</span></li>
                        <li>Experience: <span>5-9 years</span></li>
                        <li>Skills: <span>Postgres, Cassandra, Elasticsearch, Asynchronous messaging services (e.g. Kafka, RabbitMQ etc), Cucumber/Godog or other BDD tools, Kubernetes, Docker, Rest Assured, Other REST API testing tools, Wire mock/Mountebank or other HTTP stubbing tools, Continuous integration (CI), continuous deployment (CD) and continuous testing (CT), Tools such as Jenkins, Rally and/or JIRA and version control such as GIT or SVN</span>
                        </li>
                        <!-- <li>Update On: <span>19 September, 2023</span></li> -->
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
                <app-share-on-details></app-share-on-details>
            </div>

            <div class="items-lg-col-8">
                <hr class="border mb-15">

                <div class="related-service mb-20">
                    <h2 class="text-black mb-0">We are looking for experienced QA professional with 5-9 years' expertise
                        in diverse testing methodologies, automation, Agile environments, database proficiency, and
                        CI/CD tools for efficient quality assurance as a Quality Engineer.</h2>
                </div>
                <hr class="border mb-20">

                <div class="">
                    <h2 class="text-black">Required: </h2>
                    <ul class="list-black">
                        <li>5-9 years of experience in Technology, with an emphasis on QA, and confirmed ability in
                            writing test cases, running functional, automated, or performance tests, and managing
                            defects</li>
                        <li>The candidate should have excellent soft skills, strong technical ability with an extensive
                            passion to learn with a growth mindset.</li>
                        <li>Comfortable with modern datastores like postgres, cassandra, elasticsearch</li>
                        <li>Comfortable/experienced with back-end micro-service automation specifically REST and
                            asynchronous messaging services (e.g. Kafka, RabbitMQ etc)</li>
                        <li>Demonstratable experience with Golang as a programming Language</li>
                        <li>Comfortable/experience within a Scrum framework working with as part of a team to deliver
                            business functions and customer journeys that are tested and automated throughout the CICD
                            pipeline to production</li>
                        <li>Proven ability in writing test cases, running functional, automated, or performance tests,
                            and managing defects.</li>
                        <li>Solid understanding of test-driven development, including unit, component, functional,
                            system integration and regression tests</li>
                        <li>Knowledge of software engineering methodology (Agile, incl Scrum, Kanban, SAFe, Test-Driven
                            Development (TDD), Behavior Driven Development (BDD) and Waterfall)</li>
                        <li>Knowledge of the following tools : Cucumber/Godog or other BDD tools, Kubernetes, Docker,
                            Rest Assured / Resty or other REST API testing tools, Wiremock/Mountebank or other HTTP
                            stubbing tools.</li>
                        <li>Experienced in continuous integration (CI), continuous deployment (CD) and continuous
                            testing (CT), including tools such as Jenkins, Rally and/or JIRA and version control such as
                            GIT or SVN</li>
                    </ul>

                    <h2 class="text-black">Responsibilities: </h2>
                    <ul class="list-black">
                        <li>Responsible for all testing activities aligned to the portfolio and collaborates with all
                            stakeholders to achieve high-quality deliverables</li>
                        <li>Collaborates with development teams and product teams to drive improvements in overall
                            quality, automation, and testing processes and techniques</li>
                        <li>Implementing portfolio solutions for Automation and Testing and have a DevOps mindset</li>
                        <li>Understand platforms and applications, adhere to program test principles, exception testing,
                            which pertains to global functionality keeping test suites lean</li>
                        <li>Fully understands the platform roadmap and quality priorities to proactively envision
                            scalable, resilient high performing solutions; able to identify reusability at platform
                            level and drive the tooling and overall delivery strategy to stay ahead of technology
                            standards</li>
                        <li>Support the CI/CD capabilities for the portfolio, works with enterprise teams to improve
                            CI/CD capabilities and metrics at the portfolio/enterprise level; drives best practices and
                            standards, including root cause analysis</li>
                        <li>Setting and managing the product quality standards and metrics for the platform, collect,
                            maintain and report metrics at the portfolio level</li>
                        <li>Identify patterns, gaps/risk and opportunities from the metrics to drive innovation and
                            improvement, platform trend analysis</li>
                        <li>Works with the team to identify project/portfolio challenges and report/escalate as needed
                        </li>
                        <li>Uphold standards to ensure quality customer experiences through seamless, robust and
                            resilient journeys cutting across platforms</li>
                        <li>Maintain a healthy and efficient CI/CD pipeline, drive the strategy and the governance of
                            automation and testing in a CI/CD environment, ensure development pipeline standards</li>

                    </ul>
                </div>

                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="list-black mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career.
                            By merging hands-on
                            client projects with vast resources, Skillikz offers a unique platform for growth, learning,
                            and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant
                            experience. We thank all
                            applicants for their interest, but only those selected for an interview will be contacted.
                            Skillikz Ltd is an
                            equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>


<section class="gradientTopTo">
    <section class="commonSection marginNone border-b bottomBg-">
        <div class="container-main">
            <div class="mb-60">
                <app-apply-form></app-apply-form>
            </div>
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<section class="sectionForm border-b">
    <div class="container-main">
        <div class="row-flex">
            <div class="custom-col-55">
                <div class="formContent scroll-elem slide-left">
                    <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                            redefine your online presence together.</span></h2>
                    <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                        email or text message.</p>
                </div>
            </div>
            <div class="custom-col-45">
                <!-- Contact Us form component -->
                <app-form></app-form>
            </div>
        </div>
    </div>
</section>