<section class="sectionCommonWhite border-b bottomBg" style="display: none;">
    <div class="container-main">
      <h2 class="large">Read More</h2>
      <div class="row-flex mb-20">
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-left">
            <h3>Solution Name</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
            <div class="btn-fixed">
              <a href="#">
                <button type="button" class="btn btn-white" aria-label="Explore Backend Services">Explore Backend Services</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem fade-in-bottom">
            <h3>Solution Name</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
            <div class="btn-fixed">
              <a href="#">
                <button type="button" class="btn btn-white" aria-label="Explore Backend Services">Explore Backend Services</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-right">
            <h3>Solution Name</h3>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
            <div class="btn-fixed">
              <a href="#">
                <button type="button" class="btn btn-white" aria-label="Explore Backend Services">Explore Backend Services</button>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
