import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-staff-augmentation',
  templateUrl: './staff-augmentation.component.html',
  styleUrls: ['./staff-augmentation.component.css']
})
export class StaffAugmentationComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: StaffAugmentationComponent) {  
  context.title = 'Staff Augmentation Services | IT Staffing Solutions | Skillikz';
  context.description = 'Skillikz offers flexible and cost-effective IT staff augmentation services. Access a top IT talent pool, prevent recruitment delays, save costs, and increase productivity.';
  context.keywords = 'Staff Augmentation Services, IT Staff Augmentation, On-Demand Staffing, Temporary Staffing Solutions, Skilled IT Talent, Remote Staff Augmentation, Flexible Staffing, Dedicated Development Teams, Software Development Staffing, Short-Term IT Staffing, Project-Based Staffing, Contract-to-Hire, Outsourced Staffing Solutions, Team Extension Services, Offshore Staff Augmentation, Technical Talent Pool, Workforce Augmentation, IT Staffing Solutions, Resource Augmentation, Staff Augmentation Consulting';
  context.seoTags();
}
