import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details13',
  templateUrl: './blogs-details13.component.html',
  styleUrls: ['./blogs-details13.component.css']
})
export class BlogsDetails13Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails13Component) {  
  context.title = 'AI-Powered Compliance Systems: Benefits for Businesses | Skillikz';
  context.description = 'Learn how AI-powered compliance systems help businesses navigate complex regulations and laws, improve accuracy, increase efficiency, and enable proactive risk management';
  context.keywords = 'AI-Powered Compliance, Machine Learning, Natural Language Processing, Regulatory Compliance, Compliance Monitoring, Risk Management, Proactive Risk Mitigation, Automation, Compliance Reporting, Data Analysis, Real-time Monitoring, Accuracy Improvement, Compliance Automation, Customizable Solutions, Industry Regulations, Compliance Issues, Compliance Tools, Efficiency in Compliance, Regulatory Reporting, Compliance Risk Management';
  context.seoTags();
}
