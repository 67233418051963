import { Component } from '@angular/core';

@Component({
  selector: 'app-our-approach-shared',
  templateUrl: './our-approach-shared.component.html',
  styleUrls: ['./our-approach-shared.component.css']
})
export class OurApproachSharedComponent {

}
