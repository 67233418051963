<section class="sectionInnerBanner topMargin">
  <div class="container-main">

    <app-breadcrumb></app-breadcrumb>

    <div class="topInnerBanner">
      <div class="itemTopContent">
        <div class="">
          <h1><span>Product</span> Engineering</h1>
          <div class="onlyForMbl">
            <img src="assets/images/product-engineering-img.webp" alt="Product Engineering" width="336" height="336"
              onerror="this.src='assets/images/product-engineering-img.png'">
          </div>
          <p class="xl">“Stay ahead of competition through innovation led engineering services.”</p>
          <p class="md bold">Continuous change necessitates higher levels of corporate agility.</p>

          <p>With arising technology trends and dynamic market requirements, enterprises require to keep improving their
            Systems & Processes and put the Digital factor at the intersection of the Business Operation and Technology.
          </p>
          <p class="mb-0">With our robust Product Engineering service offerings, we are best positioned to respond to real-time
            customer needs and to deliver solutions derived by innovation to support business growth and scalability.
          </p>
        </div>
      </div>
      <div class="itemsImage">
        <div class="">
          <img src="assets/images/product-engineering-img.webp" alt="Product Engineering" width="336" height="336"
            onerror="this.src='assets/images/product-engineering-img.png'">
        </div>
      </div>
    </div>

  </div>
</section>




<section class="sectionCommonLightBlue">
  <div class="container-main">
    <h2 class="large">Product Strategy and roadmap</h2>
    <div class="row-flex">
      <div class="items-col-2 itemCenter">
        <p>With our industry proven Product Strategy & Roadmap definition framework, we ensure all functional, non-functional and core technical decisions are aligned with increasing business value and return on investment. The roadmap must lead to the execution of the product strategy and achieving business objectives. </p>
        <!-- <h3 class="large">“Analysing Process, Platform and Data journeys and enabling customers with business value driven roadmap”.</h3>            -->
      </div>
      <div class="items-col-2">
        <!-- <p>a</p> -->
      </div>
    </div>
    <div class="steps-img-wp mb-15">
      <img src="assets/images/product-strategy-steps.svg" class="forWeb" alt="Product Strategy and roadmap" width="1280"
        height="400">
      <img src="assets/images/product-strategy-steps-for-mbl.svg" class="forMobile" alt="Product Strategy and roadmap" width="348"
        height="910">
    </div>
  </div>
</section>



<section class="sectionCommonWhite border-b">
  <div class="container-main">
    <h2 class="large">Product Experience & Design</h2>
    <div class="row-flex">
      <div class="items-col-2 scroll-elem slide-left">
        <p>Leveraging our experience and expertise in UX, we align product mind-set and technology expertise with digital experience to deliver end-2-end customer experiences. We help customer experience built-into product strategy, leading to product design and development to drive meaningful engagement.</p>
      </div>
      <div class="items-col-2"></div>
    </div>

    <div class="steps-img-wp mb-15 scroll-elem fade-in-bottom">
      <img src="assets/images/flow-diagram.svg" class="forWeb" alt="Product Experience & Design" width="1260" height="400">
      <img src="assets/images/flow-diagram-for-mbl.svg" class="forMobile" alt="Product Experience & Design" width="330" height="974">
    </div>
  </div>
</section>




<section class="sectionCommonWhite gradientBg border-b">



  <div class="container-main mb-60">
    <h2 class="large">Product Development & Testing</h2>

    <div class="contentFlex">
      <div class="itemsCont scroll-elem slide-left">
        <p>We use Design Thinking, LEAN and Agile product development practices, starting with Proof of Concept (POC) or Proof of Value (POV), leading to Minimum Viable Product (MVP) or Minimum Sellable Product (MSP) and scale to a comprehensive solution with fresh digital product experiences.</p>
        <p>To achieve a faster go-to-market, we focus on Continuous Integration, Automated Testing and Continuous & Predictable Delivery.</p>
      </div>
      <div class="itemsGraphic scroll-elem slide-right">
        <div class="">
          <img src="assets/images/product-development-testing-img.webp" alt="Product Development & Testing" width="370" height="212"
          onerror="this.src='assets/images/product-development-testing-img.png'">
        </div>
      </div>
    </div>

  </div>


  <div class="container-main">
    <h2 class="large">Product Operations</h2>
    <div class="row-flex">
      <div class="items-col-2 scroll-elem slide-left">
        <p>Based on industry proven ITIL processes, we have a robust product support and operation framework to keep the
          business running with zero to minimum disruptions. By applying Incident Management, Problem Management, Change
          Management and Continuous Service Improvement processes, we provide required support as per the business
          requirement (24x7, 16x7, 8x7, 8x5).</p>
        <p>We set a target to reduce Product Operation cost for customers by 15-30% YOY and overachieve it constantly.
        </p>
      </div>
      <div class="items-col-2"></div>
    </div>

    <div class="steps-img-wp mb-15 scroll-elem fade-in-bottom">
      <img src="assets/images/stepper-diagram.svg" class="forWeb" alt="Product Operations"  width="1260" height="395">
      <img src="assets/images/stepper-diagram-for-mbl.svg" class="forMobile" alt="Product Operations" width="310" height="1034">
    </div>

  </div>

</section>

<!-- Explore Technology component -->
<app-explore-technology></app-explore-technology>