 <!-- ---Popup modal beginning here-- -->
 <div class="pupupModal">
    <div class="modal-flex">
        <div class="modalTitle">
            <h2>{{selectedMessages?.header}}</h2>
            <!-- <a href="javascript:void(0)" role="button" onclick="closePopupModal()" title="Close"><img src="assets/images/icons/cross-icon.svg" alt="cancel" width="" height=""></a> -->
        </div>

        <div class="modalBody">
            <div class="">                
              <div class="text-center mb-10">
                <img src="assets/images/check-mark-success.gif" alt="check mark success" width="120" height="120">
            </div>
                <p class="mb-20">{{selectedMessages?.message}}</p>
            </div>
        </div>

        <div class="modal-footer">
            <div class="row-flex">
                <div class="items-col-1">
                  <button type="button" class="btn-blue" aria-label="Close" (click)="closePopupModal()">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- ---Popup modal end here-- -->
