import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-salesforce-practice',
  templateUrl: './salesforce-practice.component.html',
  styleUrls: ['./salesforce-practice.component.css']
})
export class SalesforcePracticeComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: SalesforcePracticeComponent) {  
  context.title = 'Salesforce Consulting Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers expert Salesforce consulting services to help businesses optimize their customer relationship management (CRM) processes and drive sales growth. Contact us today to learn more.';
  context.keywords = 'Salesforce consulting, Salesforce implementation, Salesforce customization, Salesforce development, Salesforce integration, Salesforce migration, Salesforce optimization, Salesforce support, Salesforce training, Salesforce managed services, Salesforce solutions, Salesforce experts, Salesforce consultants, Salesforce partner, Salesforce CRM, Salesforce administration, Salesforce app development, Salesforce platform, Skillikz, Salesforce consulting services, Salesforce implementation, Salesforce optimization, UK';
  context.seoTags();
}
