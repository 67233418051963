<section class="commonSection topMargin pt-20">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1><span>Golang Software</span> Engineer</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>UK</span></li>
                        <li>Experience: <span>3-9 years</span></li>
                        <li>Skills: <span>Golang, Microservices, Agile, Kafka, Docker, REST Web Services, CI/CD</span></li>
                        <!-- <li>Update On: <span>19 September, 2023</span></li> -->
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>                
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
                <app-share-on-details></app-share-on-details>
            </div>

            <div class="items-lg-col-8">
                <hr class="border mb-15">

                <div class="related-service mb-20">
                    <h2 class="text-black mb-0">We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.</h2>
                </div>
                <hr class="border mb-20">
                
                <div class="">
                    <h2 class="text-black">Required: </h2>
                    <ul class="list-black">
                        <li>Demonstrable experience in at least one back-end type safe programming language (Golang Preferred but other experience can be considered)</li>
                        <li>Comfortable/experienced with back-end micro-service architecture and communication, specifically REST and asynchronous messaging services (e.g., Kafka, RabbitMQ etc.)
                        </li>
                        <li>Comfortable/experience within a Scrum framework working with as part of a team to deliver business functions and customer journeys that are tested and automated throughout the CICD pipeline to production.</li>
                         
                    </ul>
    
                    <h2 class="text-black">Desired: </h2>
                    <ul class="list-black">
                        <li>Bachelor’s Degree in computer science, computer engineering, or other technical discipline, or equivalent work experience.</li>
                        <li>Experience in professional software development.
                        </li>
                        <li>Solid understanding of test-driven development, including unit, component, functional, system integration and regression tests.</li>
                        <li>Knowledge of software engineering methodology (Agile, incl Scrum, Kanban, SAFe, Test-Driven Development (TDD), Behavior Driven Development (BDD) and Waterfall)</li>
                        <li>Knowledge of any or all of the following technologies is desired: Kafka, Postgres, Golang, Git, gRPC, Docker, GraphQL</li>
                        <li>Experienced in continuous integration (CI), continuous deployment (CD) and continuous testing (CT), including tools such as Jenkins, Rally and/or JIRA and version control such as GIT or SVN </li>
                         
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">What do we look for?</h2>
                    <ul class="list-black">
                        <li>At least five years of experience as an SME in Business Intelligence disciplines.</li>
                        <li>At least two years of deep experience with Big Data solutions.</li>
                        <li>Basic expertise in working in a global environment.</li>
                        <li>Proven experience (minimum of 3 years) in managing complex, multi-dimensional demands.</li>
                        <li>Practical experience with customer onboarding, especially within the realm of financial data.</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="list-black mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career. By merging hands-on
                        client projects with vast resources, Skillikz offers a unique platform for growth, learning, and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant experience. We thank all
                        applicants for their interest, but only those selected for an interview will be contacted. Skillikz Ltd is an
                        equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>  
    </div>
  </section>


<section class="gradientTopTo">
    <section class="commonSection marginNone border-b bottomBg-">
        <div class="container-main">
            <div class="mb-60">
                <app-apply-form></app-apply-form>
            </div>
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<section class="sectionForm border-b">
    <div class="container-main">
        <div class="row-flex">
            <div class="custom-col-55">
                <div class="formContent scroll-elem slide-left">
                    <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                            redefine your online presence together.</span></h2>
                    <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                        email or text message.</p>
                </div>
            </div>
            <div class="custom-col-45">
                <!-- Contact Us form component -->
                <app-form></app-form>
            </div>
        </div>
    </div>
</section>