import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: CareerComponent) {  
  context.title = 'Career Opportunities at Skillikz Ltd for Innovative IT Experts | Join Us';
  context.description = 'Looking to join a team of experts in technology consulting, product engineering, and digital transformation? Skillikz is hiring! Find out more.';
  context.keywords = 'Career Opportunities, Job Openings, Careers at [Company Name], Career Development, Job Search, Career Growth, Professional Opportunities, Talent Acquisition, Job Listings, Employment Opportunities, Career Pathways, Join Our Team, Work with Us, Remote Career Opportunities, Internship Opportunities, Career Advancement, Career Benefits, Recruitment Services, Employee Success, Career Support';
  context.seoTags();
}
