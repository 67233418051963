import { Component } from '@angular/core';

@Component({
  selector: 'app-explore-technology',
  templateUrl: './explore-technology.component.html',
  styleUrls: ['./explore-technology.component.css']
})
export class ExploreTechnologyComponent {

}
