<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Leadership</span> & Management</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Stay current with regulations and effective business practices through our CPD accredited courses. Designed for managers, the training offers flexibility, an intuitive interface, interactive scenarios, and immediate practical application. </p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/leadership-and-management-img.webp" alt="Leadership & Management image" width="485"
              height="432" onerror="this.src='assets/images/leadership-and-management-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Business Compliance Essentials</h3>
            <p>Equip yourself and your team with Cyber Security, Data Protection, Fraud Protection, and more to comply
              with current legislation.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:263,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Conflict Resolution</h3>
            <p>Identify and resolve workplace conflicts effectively. Seek guidance when needed for optimal conflict
              management.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:262,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Customer Service</h3>
            <p>Master the art of exceptional customer experiences, measuring satisfaction, and meeting customer
              expectations.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:264,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Effective Remote Working</h3>
            <p>Thrive in a remote work environment by building online relationships, communicating flexibly, and
              organizing effectively.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:265,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Adult at Risk - Level 3</h3>
            <p>
              This course is intended for team leaders and managers and those with designated safeguarding
              responsibilities. It will help promote awareness of safeguarding duties within an organisation and...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:235,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>HR Compliance & Wellbeing Essentials</h3>
            <p>Covering equality, diversity, conflict resolution, mental health, modern slavery, sexual harassment, and
              whistleblower protection. Elevate workplace standards and customer benefit.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:266,group:32" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
      </div>



      <!-- --------Hidden courses wrapper beginning here----------- -->
      <!-- <div class="">

          <div class="hidden-wrapper">
            <div class="row-flex">
              <div class="items-col-3">
                <div class="course-card-inner">
                  <h3>Health and Safety - Level 2</h3>
                  <p>This course consists of why health and safety are important in a workplace.</p>
                  <div class="btn-fixed">
                    <a href="https://lms.skillikz.com/catalog/info/id:216" target="_blank">
                      <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div class="show-all-wp">
            <div class="">
              <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
                Courses</button>
            </div>
          </div>
          
        </div> -->
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>

  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>


      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Leadership & Management Courses</p>
                <ol>
                  <li>The six fundamental principles of Data Protection</li>
                  <li>The role of the Data Protection Officer and Data Protection Officers</li>
                  <li>Personal data breach policy</li>
                  <li>Consent, contract, and legal obligation</li>
                  <li>Changes brought about by new GDPR legislation</li>
                  <li>GDPR compliance for businesses</li>
                </ol>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=3F7FdzD6HaM&list=PLAy395buT7V6gNBFSGxLE67h9qtAoi9U-" target="_blank">
              <div class="">
                <img src="assets/images/social-media-youtube-img6.webp"  alt="social Media Youtube img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-youtube-img6.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=3F7FdzD6HaM&list=PLAy395buT7V6gNBFSGxLE67h9qtAoi9U-" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Embrace Digital Transformation: Unlock Your Business's Full Potential</p>
                <p>Are you ready to take your business to the next level in the digital age?</p>
                <p>Discover the power of a well-defined digital transformation framework and gain insights into your organization's digital maturity.</p>
                <p>In our latest article, "Leveraging Digital Transformation Framework for Effective Technology Adoption," we delve into the critical competencies and levels of digital maturity that can drive success in today's competitive landscape.</p>
                <p>From fostering a digital-first culture to selecting and implementing the right technologies, this article provides a roadmap for your digital transformation journey.</p>
                <p>Don't miss out on this opportunity to navigate the complexities of digitalization, unlock new growth opportunities, and empower your business for sustainable success.</p>
                <p>Read our article <a href="https://buff.ly/3PrbJYD" target="_blank">https://buff.ly/3PrbJYD</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7079398685255557120-G4PK/?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img5.webp" alt="social Media img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-img5.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7079398685255557120-G4PK/?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Digital Maturity in UK Adult Social Care: Bridge the Gap</p>
                <p>Adopting digital technologies in adult social care presents immense opportunities, but a significant digital divide persists. Key challenges include limited access to technologies, inadequate internet connectivity, low adoption of digital records, and a lack of digital maturity.</p>
                <p>To bridge the gap:</p>
                <p>Invest in infrastructure</p>
                <p>Provide comprehensive training</p>
                <p>Promote best practices</p>
                <p>Foster collaboration and support</p>
                <p>Achieving digital maturity improves care quality and operational efficiency.</p>
                <p>Read article at <a href="https://lnkd.in/e8vDZWnt" target="_blank">https://lnkd.in/e8vDZWnt</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_transform-uk-adult-social-care-activity-7076861356439674880-0SLH?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img6.webp" alt="social Media img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-img6.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_transform-uk-adult-social-care-activity-7076861356439674880-0SLH?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

  </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/leadership-and-management'"></app-other-course-catalogues>

  </div>
</section>