import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-share-on',
  templateUrl: './share-on.component.html',
  styleUrls: ['./share-on.component.css']
})
export class ShareOnComponent extends BaseClass implements OnInit {

  constructor(public router: Router) { super() }

  ngOnInit(): void {
  }

  showFacebookLink() {
    let FacebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(FacebookLink,"_new")
  }
  showTwitterLink() {
    let twitterLink = "https://twitter.com/intent/tweet?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(twitterLink, "_new")
  }
  showLinkedinLink() {
    let linkedinLink = "https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(linkedinLink, "_new")
  }
  

}
