import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b8623',
  templateUrl: './blogs-details-b8623.component.html',
  styleUrls: ['./blogs-details-b8623.component.css']
})
export class BlogsDetailsB8623Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetailsB8623Component) {  
  context.title = 'Digital Maturity in UK Adult Social Care: Bridging the Technological Divide | Skillikz Blog';
  context.description = 'Explore the concept of digital maturity in the UK adult social care sector and discover strategies for bridging the technological divide. Gain valuable insights on leveraging technology for improved care delivery in the Skillikz blog.';
  context.keywords = 'Digital Maturity, Adult Social Care, Digital Divide, Technology Adoption, Infrastructure Investment, Broadband Connectivity, Digital Social Care Records, Operational Efficiency, Training and Upskilling, Digital Transformation, Internet Connectivity, Digital Tools, Patient Care Improvement, Capacity Building, Collaboration Strategies, Data Protection, Record-Keeping Automation, Best Practices Sharing, Technological Readiness, Stakeholder Engagement';
  context.seoTags();
}