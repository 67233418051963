<section class="sectionBanner topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="row-flex">
      <div class="items-lg-col-2 banner-content">
        <div class="">
          <h1>Up your Skill, <span>Learn 24/7</span></h1>
          <p class="mb-25">We provide the latest online learning system and material that will help you stay ahead.
          </p>
          <div class="buttons-flex">
            <button type="button" class="btn btn-blue" aria-label="Get Started"  (click)="sendToCal()">Get In Touch</button>
            <!-- <button type="button" class="btn btn-white" aria-label="Learn More">Learn More</button> -->
          </div>
        </div>
      </div>
      <div class="items-lg-col-2 banner-img">
        <div class="">
          <img src="assets/images/learning-services-img.webp" alt="Learning Services" width="580" height="530"
                      onerror="this.src='assets/images/learning-services-img.png'">
        </div>
      </div>
    </div>
    <div class="row-flex">
      <div class="items-col-1">
        <p class="mb-0">Access Anywhere | Meets Industry Standards | Delivering real-world skills</p>
      </div>
    </div>
  </div>

  <div class="circleBg"></div>
</section>



<section class="sectionCourseCatalogue">
  <div class="container-main">

      <div class="row-flex mb-15">
        <div class="items-col-1">
          <h2 class="large">Course Catalogue</h2>
        </div>
      </div>


      <div class="course-catalogue-row">
        
        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Health & Safety</h3>
              <p class="course-text">35 Courses</p>
              <p class="details-text">Our health and safety courses cover good practices to ensure workplace safety.</p>
              <ul>
                <li>Health & Safety Training</li>
                <li>Control of Substances</li>
                <li>Hazardous to Health</li>
                <li>Safe use of Display Screen Equipment</li>
                <li>Fire Safety</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/health-and-safety">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg1">
              <div class="topIcon">
                <img src="assets/images/icons/health-safety-icon.svg" alt="Health Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Health Care</h3>
              <p class="course-text">38 Courses</p>
              <p class="details-text">Health Care Compliance is a stringent process, but learning it doesn't have to be.</p>
              <ul>
                <li>Basic Life Support & First Aid</li>
                <li>Communication in Care</li>
                <li>Duty of Care</li>
                <li>Equality and Diversity in Care</li>
                <li>Fluids and Nutrition in Care</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/health-care">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg2">
              <div class="topIcon">
                <img src="assets/images/icons/health-care-icon.svg" alt="Health plus Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Financial Compliance</h3>
              <p class="course-text">38 Courses</p>
              <p class="details-text">We have created an array of modules that supports firms to combat economic crime and irregularities.</p>
              <ul>
                <li>Anti Money Laundering</li>
                <li>Anti Bribery L2</li>
                <li>Anti Bribery L3</li>
                <li>Prevent - Ani Terrorism</li>
                <li>Cyber Security</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/financial-compliance">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg3">
              <div class="topIcon">
                <img src="assets/images/icons/financial-compliance-icon.svg" alt="Finance Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

        

        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Information Security</h3>
              <p class="course-text">18 Courses</p>
              <p class="details-text">Ensure business compliance through our cyber and data security courses. </p>
              <ul>
                <li>Cyber Security</li>
                <li>GDPR Awareness</li>
                <li>UK GDPR</li>
                <li>Business Compliance Essentials</li>
                <li>Conflict Resolution</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/information-security">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg4">
              <div class="topIcon">
                <img src="assets/images/icons/information-security-icon.svg" alt="Information Security Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Human Resources</h3>
              <p class="course-text">20 Courses</p>
              <p class="details-text">Our HR compliance courses ensure that the workforce fully understands existing laws and regulations.</p>
              <ul>
                <li>Equality Diversity & Inclusion</li>
                <li>Stress Awareness</li>
                <li>Adult Safeguarding L1</li>
                <li>Adult Safeguarding L2</li>
                <li>Adult Safeguarding  for Managers L3</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/human-resources">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg5">
              <div class="topIcon">
                <img src="assets/images/icons/human-resources-icon.svg" alt="Human Resources Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

        <div class="flex-items">
          <div class="courseCard">
            <div class="item-course-content">
              <h3>Leadership & Management</h3>
              <p class="course-text">15 Courses</p>
              <p class="details-text">Get yourself equipped with up to date regulations, and good business practices.</p>
              <ul>
                <li>Business Compliance Essentials</li>
                <li>Conflict Resolution</li>
                <li>Customer Service</li>
                <li>Effective Remote Working</li>
                <li>HR Compliance & Wellbeing Essentials</li>
                <li>Adult Safeguarding  for Managers L3</li>
              </ul>
              <div class="btnFixed">
                <button type="button" class="btn btn-blue" aria-label="View Courses" routerLink="/leadership-and-management">View Courses</button>
              </div>
            </div>
            <div class="item-img courseBg6">
              <div class="topIcon">
                <img src="assets/images/icons/leadership-management-icon.svg" alt="Leadership & Management Icon" width="38" height="38">
              </div>
            </div>
          </div>
        </div>

      </div>

  </div>

</section>


<section class="sectionLms">
  <div class="container-main">

      <div class="row-flex">
        <div class="items-col-1">
          <h2 class="large">Point of Convergence</h2>
        </div>
      </div>

      <div class="lms-banner-web-only scroll-elem fade-in-bottom">
        <img src="assets/images/unique-proposition-img.svg" alt="Blended Learning Approach,Differentiation through Storytelling,Practical Skill Development and Accredited Courses" width="940" height="474">
      </div>

      <div class="mobileOnly">
        <div class="lms-content scroll-elem fade-in-bottom">
          <div class="">
            <h3>Blended Learning Approach</h3>
            <p>Combined storytelling and skill-building to create immersive and engaging learning experiences</p>
          </div>
          <div class="">
            <h3>Differentiation through Storytelling</h3>
            <p>Captivate learners on an emotional level, making the learning process more memorable and impactful</p>
          </div>
          <div class="">
            <h3>Practical Skill Development</h3>
            <p>Ensuring learners can immediately apply what they learn in their professional lives</p>
          </div>
          <div class="">
            <h3>Accredited Courses</h3>
            <p class="mb-0">High-quality and industry-recognized learning content that meets rigorous standards</p>
          </div>
        </div>
      </div>

  </div>

</section>


<section class="sectionFeatures">

  <div class="container-main">   

    <div class="features-wrapper">
      <div class="contentItem">
        <h2 class="large mb-10 scroll-elem slide-left">Features</h2>
        <ul class="scroll-elem slide-left">
          <li> Courses cover industry good practices and support employees to get acquainted with regulatory compliance</li>
          <li> Choose from 35+ Healthcare courses.</li>
          <li> Our Learning App tracks progress and offers immense flexibility to professionals with busy work schedules</li>
          <li> CPD accredited courses</li>
          <li> Delivering real-world skills for employees and transform workplaces</li>
        </ul>
      </div>

      <div class="itemImg scroll-elem slide-right">
        <img src="assets/images/features-img.webp" alt="Features" width="368" height="498"
                      onerror="this.src='assets/images/features-img.png'">
      </div>
    </div>

  </div>

</section>


<section class="sectionLearnFrom">
  <div class="container scroll-elem fade-in-bottom">
    <div class="itemTitle">
      <h2><span>Learn From</span> Anywhere</h2>
    </div>
    <div class="itemDetails">
      <p>Take classes on the go from laptop, mobile or PC.
        <span>Download our App from:</span>
      </p>
      <div class="btnWrapper">
        <a href="https://apps.apple.com/gb/app/skillikz-lms/id1618930545" target="_blank">
          <button type="button" aria-label="Download on the App Store">
            <img src="assets/images/icons/btn-app-store.svg" alt="Features" width="165" height="46">
          </button>
        </a>   
        <a href="#">
          <button type="button" aria-label="Get it on Google Play">
            <img src="assets/images/icons/btn-google-play.svg" alt="Features" width="178" height="46">
          </button>
        </a>        
      </div>
    </div>
  </div>
</section>



<section class="sectionForm">
  <div class="container-main">
    <div class="row-flex">
      <div class="custom-col-55">
        <div class="formContent scroll-elem slide-left">
          <h2>Leave your contact information</h2>
          <h3>and we'll help you find a course to meet your goal.</h3>
          <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone, email or text message.</p>
        </div>
      </div>
      <div class="custom-col-45">

        <!-- Contact Us form component -->
        <app-form></app-form>
      </div>
    </div>
  </div>
</section>

