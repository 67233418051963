import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.css']
})
export class HumanResourcesComponent extends BaseClass implements OnInit {

  constructor(public router: ActivatedRoute, private location: Location) { super() }

  ngOnInit(): void {
    init(this);
    this.showLocationTab(null, 'tabOne');
    this.router.queryParams.subscribe(params => {
      if (params && params['tabName']) {
        this.showLocationTab(null, params['tabName']);
        this.location.replaceState('/human-resources');
      }
    });
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:31';
    window.open(url, '_blank');
  }

}

function init(context: HumanResourcesComponent) {
  context.title = 'Human Resources | Skillikz Ltd';
  context.description = 'Gain insights into current employment law, rights, and responsibilities with our CPD accredited courses. Explore Safeguarding, Conflict Resolution, and more. Stay current with regulations and effective business practices through our CPD accredited courses. Designed for managers, the training offers flexibility, an intuitive interface, interactive scenarios, and immediate practical application. ';
  context.keywords = 'Human Resource Management, HR Consulting Services, Employee Recruitment Solutions, Talent Acquisition, HR Outsourcing Services, Workforce Management, Employee Engagement Strategies, HR Software Solutions, Compensation and Benefits Management, HR Policy Development, Performance Management Systems, Learning and Development (L&D), HR Analytics, Employee Retention Strategies, Organizational Development, Succession Planning, HR Compliance and Regulations, Diversity and Inclusion in HR, Employee Onboarding Solutions, HR Transformation Services';
  context.seoTags();
}
