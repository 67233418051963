import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study6',
  templateUrl: './case-study6.component.html',
  styleUrls: ['./case-study6.component.css']
})
export class CaseStudy6Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy6Component) {  
  context.title = 'Cyber Security Services | Skillikz Cybersecurity Solutions';
  context.description = 'Discover how Skillikz Cybersecurity Solutions helped a UK-based lending institution enhance its security posture. Explore our comprehensive cybersecurity services, including DAST, SAST, risk assessment, compliance audits, and continuous monitoring.';
  context.keywords = 'Cybersecurity Solutions, Network Security, Threat Detection and Response, IT Security Services, Cybersecurity Consulting, Data Protection Services, Endpoint Security, Security Information and Event Management (SIEM), Vulnerability Management, Cybersecurity Risk Assessment, Cyber Threat Intelligence, Incident Response, Penetration Testing, Managed Security Services, Cloud Security, Malware Protection, Identity and Access Management (IAM), Security Audits and Compliance, Cybersecurity Awareness Training, Encryption Solutions';
  context.seoTags();
}
