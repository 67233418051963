<form [formGroup]="contactUsFormGroup" *ngIf="contactUsFormGroup">
    <div class="row-flex scroll-elem slide-right">
      <div class="items-col-2">
        <div class="form-group">
          <label for="firstName"><span>*</span> First Name</label>
          <input type="text" class="form-control" id="firstName" name="firstName"
          placeholder="Type first name here" formControlName="firstName">
          <span class="error-msg" *ngIf="contactUsFormGroup.get('firstName').invalid && submitted">
            <span *ngIf="contactUsFormGroup.get('firstName').errors['required']">{{constants.firstNameReq}}</span>
          </span>
  
        </div>
      </div>
      <div class="items-col-2">
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName" name="lastName" placeholder="Type last name here">
        </div>
      </div>
      <div class="items-col-2">
        <div class="form-group">
          <label for="phoneNumber">Phone Number</label>
          <input type="text" class="form-control" id="phoneNumber" name="phoneNumber"
            formControlName="phoneNumber" required placeholder="Type phone number here" maxlength="12">
          <span class="error-msg" *ngIf="contactUsFormGroup.get('phoneNumber').invalid && submitted">
            <span *ngIf="contactUsFormGroup.get('phoneNumber').errors['pattern']">{{constants.phoneNumberPattern}}</span>
          </span>
        </div>
      </div>
      <div class="items-col-2">
        <div class="form-group">
          <label for="companyName">Company Name</label>
          <input type="text" id="companyName" name="companyName" class="form-control"
            placeholder="Type company name here" formControlName="message">
        </div>
      </div>
      <div class="items-col-1 mb-25">
        <div class="form-group">
          <label for="emailId"><span>*</span> E-mail</label>
          <input type="email" class="form-control" id="emailId" name="emailId" formControlName="email"
            placeholder="Type your email address here" email required>
          <span class="error-msg" *ngIf="contactUsFormGroup.get('email').invalid && submitted">
            <span *ngIf="contactUsFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
            <span *ngIf="contactUsFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
          </span>
        </div>
      </div>
      <div class="items-col-1">
        <div class="form-group">
          <button type="submit" class="btn btn-orange" name="submit" aria-label="Submit" (click)="onSubmit()" style="width: 100%;">
            <span>Submit</span>
            <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
          </button>
          <!-- <button type="submit" class="btn btn-blue" name="submit" aria-label="Submit" (click)="onSubmit()">Submit</button> -->
        </div>
        
      </div>
    </div>
  </form>
  