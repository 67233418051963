import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details32',
  templateUrl: './blogs-details32.component.html',
  styleUrls: ['./blogs-details32.component.css']
})
export class BlogsDetails32Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails32Component) {  
  context.title = 'Effective Use of Data Science in Financial Underwriting | Advanced Analytics, Machine Learning, and More';
  context.description = 'Learn how data science is transforming financial underwriting by improving risk assessment, identifying fraudulent behavior, personalizing loan offers, streamlining the underwriting process, and optimizing performance';
  context.keywords = 'Data Science, Financial Underwriting, Advanced Analytics, Predictive Modelling, Machine Learning, Creditworthiness, Risk Assessment, Credit History, Income Analysis, Employment Status, Fraud Detection, Identity Theft, Loan Stacking, Personalizing Loan Offers, Customer Preferences, Spending Habits, Credit History Analysis, Streamlining Underwriting, Automation, Performance Optimization';
  context.seoTags();
}
