<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Financial</span> Compliance</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Enhance business integrity with our CPD accredited modules. Combat economic crime with AML, Anti-Bribery, Prevent Duty, and more. Flexible learning, intuitive interface, interactive scenarios, and immediate practical application.</p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/financial-compliance-img.webp" alt="Financial Compliance image" width="485"
              height="432" onerror="this.src='assets/images/financial-compliance-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Business Compliance Essentials</h3>
            <p>Equip yourself and your team with Cyber Security, Data Protection, Fraud Protection, and more to comply
              with current legislation.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:263,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Conflict Resolution</h3>
            <p>Identify and resolve workplace conflicts effectively. Seek guidance when needed for optimal conflict
              management.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:262,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Customer Service</h3>
            <p>Master the art of exceptional customer experiences, measuring satisfaction, and meeting customer
              expectations.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:264,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Effective Remote Working</h3>
            <p>Thrive in a remote work environment by building online relationships, communicating flexibly, and
              organizing effectively.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:265,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Equality and Diversity</h3>
            <p>Explore concepts of equality, diversity, and inclusion. Learn about the Equality Act 2010, workplace
              discrimination types, unacceptable conduct, and procedures for reporting discrimination and harassment.
            </p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:244,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Cyber Security</h3>
            <p>Understand the significance of cyber and information security. Learn about cyberattacks like phishing,
              malware, ransomware, and identity theft. Improve Cyber Security through password security, e-mail and
              social media practices, firewalls, antivirus software, and reporting security breaches.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:204,group:19" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
      </div>



      <!-- --------Hidden courses wrapper beginning here----------- -->
      <div class="">

        <div class="hidden-wrapper">
          <div class="row-flex">
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>GDPR Awareness Level 2</h3>
                <p>This is a UK & EU Data Protection awareness program sample written and directed internally</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:243,group:19" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Data Protection and GDPR level 3</h3>
                <p>This course provides an understanding of the data protection legislation within the UK and EU and the
                  responsibilities of organizations in protecting personal and sensitive data. It also covers various
                  principles of data protection legislation within a business context.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:205,group:19" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Anti-Bribery Level 2</h3>
                <p>
                  This course tells how to spot potential signs of bribery and what action to take if you suspect that corruption has occurred.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:246,group:32" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Anti Bribery Level 3</h3>
                <p>
                This course tells how to spot potential signs of bribery and what action to take if you suspect that corruption has occurred.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:200,group:32" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Anti Money Laundering</h3>
                <p>Understand money laundering and its process. Learn about the Proceeds of Crime Act, Terrorism Act, Bribery Act, and examples of bribery. Identify suspicious activities and red flags, along with the risks and responsibilities in preventing money laundering.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:236,group:32" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Prevent Duty</h3>
                <p>
                This module covers UK government Guidelines on Counter-Terrorism Act and prevents duty. This is narrated through true stories and dramatized content.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:225,group:32" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="show-all-wp">
          <div class="">
            <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
              Courses</button>
          </div>
        </div>


      </div>
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>

  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Financial Compliance</p>
                <p>The Bribery Act 2010.</p>
                <p>The different types of bribery and what could happen if you fail to prevent bribery.</p>
                <p>The difference between a bribe and a gift and the common indicators of a bribe.</p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=cTeaVahKmvk&list=PLAy395buT7V4LnH_6LDJqdt-1018X78Ie" target="_blank">
              <div class="">
                <img src="assets/images/social-media-youtube-img3.webp" alt="social Media Youtube img" width="412"
                  height="232" onerror="this.src='assets/images/social-media-youtube-img3.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=cTeaVahKmvk&list=PLAy395buT7V4LnH_6LDJqdt-1018X78Ie" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Embrace Digital Transformation: Unlock Your Business's Full Potential</p>
                <p>Are you ready to take your business to the next level in the digital age?</p>
                <p>Discover the power of a well-defined digital transformation framework and gain insights into your organization's digital maturity.</p>
                <p>In our latest article, "Leveraging Digital Transformation Framework for Effective Technology Adoption," we delve into the critical competencies and levels of digital maturity that can drive success in today's competitive landscape.</p>
                <p>From fostering a digital-first culture to selecting and implementing the right technologies, this article provides a roadmap for your digital transformation journey.</p>
                <p>Don't miss out on this opportunity to navigate the complexities of digitalization, unlock new growth opportunities, and empower your business for sustainable success.</p>
                <p>Read our article <a href="https://buff.ly/3PrbJYD" target="_blank">https://buff.ly/3PrbJYD</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7079398685255557120-G4PK/?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img5.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img5.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7079398685255557120-G4PK/?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Digital Maturity in UK Adult Social Care: Bridge the Gap</p>
                <p>Adopting digital technologies in adult social care presents immense opportunities, but a significant digital divide persists. Key challenges include limited access to technologies, inadequate internet connectivity, low adoption of digital records, and a lack of digital maturity.</p>
                <p>To bridge the gap:</p>
                <p>Invest in infrastructure</p>
                <p>Provide comprehensive training</p>
                <p>Promote best practices</p>
                <p>Foster collaboration and support</p>
                <p>Achieving digital maturity improves care quality and operational efficiency.</p>
                <p>Read article at <a href="https://lnkd.in/e8vDZWnt" target="_blank">https://lnkd.in/e8vDZWnt</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_transform-uk-adult-social-care-activity-7076861356439674880-0SLH?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img6.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img6.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_transform-uk-adult-social-care-activity-7076861356439674880-0SLH?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/financial-compliance'"></app-other-course-catalogues>


  </div>
</section>
