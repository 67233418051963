<section class="commonSection topMargin pt-20">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="row-flex">
            <div class="items-lg-col-8">                
                <div class="">
                    <h1><span>.NET</span> Developer</h1>
                </div>
        
                <div class="job-details">
                    <ul>
                        <li>Location: <span>Essex, Basildon</span></li>
                        <li>Reports to: <span>Project Manager</span></li>
                        <li>Salary: <span>Competitive</span></li>
                        <li>Update On: <span>14 May, 2023</span></li>
                    </ul>
                </div>
        
                <div class="work-mode">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
                <app-share-on-details></app-share-on-details>
            </div>

            <div class="items-lg-col-8">
                <hr class="border mb-15">

                <div class="related-service mb-20">
                    <h2 class="text-black mb-0">A technical professional with hands-on experience writing quality code and
                        understanding business in Fintech and retails industry.</h2>
                </div>
                <hr class="border mb-20">
                
                <div class="">
                    <h2 class="text-black">Duties & Responsibilities</h2>
                    <p class="mb-5">Fundamental Responsibilities</p>
                    <ul class="list-black">
                        <li>Producing code using .NET languages (C#, .NET Core, Web API)</li>
                        <li>Upgrading, configuring and debugging existing systems</li>
                        <li>Writing unit test cases</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">Skill Required</h2>
                    <p class="mb-5">Required Skill Set</p>
                    <ul class="list-black">
                        <li>Capable of understanding business requirements and writing a good quality code</li>
                        <li>Hands-on with the Latest Dot Net framework, including .Net Core</li>
                        <li>Intensive hands-on application development experience</li>
                        <li>Understanding of Agile methodologies</li>
                        <li>Good knowledge of OOPS concept experience in writing code using C#</li>
                        <li>Experienced in writing Web API using .Net Core</li>
                        <li>Hands-on experience in writing Unit Test cases</li>
                        <li>Experience in writing queries using SQL Server or any other RDBMS database</li>
                        <li>Experience with any ORM tool</li>
                        <li>CI/CD experience</li>
                        <li>Working knowledge on any code versioning tool using any tool</li>
                        <li>Good Communications skills</li>
                        <li>Should be flexible at work and must be task driven than clock oriented</li>
                        <li>Good interpersonal skills along with self-drive for excellence</li>
                    </ul>
                </div>
    
                <div class="">
                    <p class="mb-5">Desirable Skills:</p>
                    <ul class="list-black">
                        <li>Working knowledge on any cloud plate form (Azure, AWS, GCP)</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">Qualifications</h2>
                    <p class="mb-5">Required Qualification</p>
                    <ul class="list-black">
                        <li>A bachelor/Master's degree (IT, Computer Science preferred) with a minimum of 5+ years of
                            experience</li>
                    </ul>
                </div>

            </div>

        </div>  
    </div>
  </section>


<section class="gradientTopTo">
    <section class="commonSection marginNone border-b bottomBg-">
        <div class="container-main">
            <div class="mb-60">
                <app-apply-form></app-apply-form>
            </div>
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<section class="sectionForm border-b">
    <div class="container-main">
        <div class="row-flex">
            <div class="custom-col-55">
                <div class="formContent scroll-elem slide-left">
                    <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                            redefine your online presence together.</span></h2>
                    <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                        email or text message.</p>
                </div>
            </div>
            <div class="custom-col-45">
                <!-- Contact Us form component -->
                <app-form></app-form>
            </div>
        </div>
    </div>
</section>
