<section class="section-inner-content topMargin">
    <div class="container-main bgWhite">

        <app-breadcrumb></app-breadcrumb>

        <div class="">
            <h1><span>Cookies Policy</span></h1>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Introduction</h2>
            <p>This Cookies Policy (“Policy”) explains how Skillikz Ltd. (“Skillikz”, “we”, “us”, or “our”) uses cookies
                and similar technologies on our website, www.skillikz.com (the “Website”). </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">What are Cookies?</h2>
            <p>Cookies are small text files that are placed on your computer or mobile device when you visit a website.
                They are widely used to make websites work, or work more efficiently, as well as to provide information
                to the owners of the website. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">What Cookies do we use? </h2>
            <p class="mb-5">We use cookies and similar technologies on our Website to collect and store certain
                information about your visit. The cookies we use on our Website fall into the following categories: </p>
            <ul class="list">
                <li><strong>Essential Cookies:</strong> These cookies are necessary for the Website to function and
                    cannot be switched off in our systems. They are usually only set in response to actions made by you
                    which amount to a request for services, such as setting your privacy preferences, logging in or
                    filling in forms. </li>
                <li><strong>Analytics Cookies:</strong> These cookies allow us to count visits and traffic sources so we
                    can measure and improve the performance of our Website. They help us to know which pages are the
                    most and least popular and see how visitors move around the site. </li>
                <li><strong>Advertising Cookies:</strong> These cookies may be set through our site by our advertising
                    partners. They may be used by those companies to build a profile of your interests and show you
                    relevant adverts on other sites. </li>
            </ul>
        </div>

        <div class="mb-20">
            <h2 class="text-black">How to Control Cookies</h2>
            <p>Most web browsers allow you to control cookies through their settings preferences. However, if you limit
                the ability of websites to set cookies, you may worsen your overall user experience, since it will no
                longer be personalized to you. It may also stop you from saving customized settings like login
                information. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Changes to this Policy</h2>
            <p>We reserve the right to update or change this Policy at any time. We will post the most current version
                of the Policy on our Website with the effective date. Your continued use of our Website after we have
                posted changes to the Policy will indicate that you have accepted the updated Policy. </p>
        </div>

        <div class="">
            <h2 class="text-black">Contact Us </h2>
            <p class="mb-0">If you have any questions or concerns about our use of cookies, please contact us at <a class="hyperlink"
                    href="mailto:info@skillikz.co.uk">info@skillikz.co.uk</a>.</p>
        </div>

    </div>
</section>