import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsComponent) {  
  context.title = 'Blogs - SKILLIKZ';
  context.description = 'Latest Trends in Business Digital Transformation, Fintech Industry & Software Development';
  context.keywords = 'Blog Writing Services, Business Blog, Blog Content Creation, Blog Strategy, Content Marketing Blog, Blog Post Ideas, SEO Blog Writing, Blog Articles, Blog SEO Optimization, Personal Blog, Professional Blogging, Blog for Business Growth, Blogging Tips, Guest Blogging, Blog Management Services, Blogging Platforms, Blog Design and Development, Blog Content Strategy, Corporate Blogging, Blog Marketing Techniques';
  context.seoTags();
}
