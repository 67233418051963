import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent extends BaseClass implements OnInit{

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: WhoWeAreComponent) {  
  context.title = 'About Skillikz Ltd - Innovative Solutions for Business Digital Transformation and Customized Technology | Expert IT Product Engineering Services';
  context.description = 'Learn more about Skillikz - a UK-based software development company specializing in custom software development, mobile app development, web development, and digital transformation. Contact us today to discuss your project';
  context.keywords = 'About Us, Our Company, Company Overview, Our Story, Team Introduction, Meet Our Team, Company Values, Our Mission, Our Vision, Leadership Team, Corporate Identity, Company History, Our Philosophy, Company Culture, About Our Services, Our Commitment, Our Expertise, Who We Serve, Why Choose Us, Company Achievements';
  context.seoTags();
}
