import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details30',
  templateUrl: './blogs-details30.component.html',
  styleUrls: ['./blogs-details30.component.css']
})
export class BlogsDetails30Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails30Component) {  
  context.title = 'Best Practices in Business Digital Transformation: Strategies for Success';
  context.description = 'Discover the key best practices for business digital transformation, including starting with a clear strategy, involving stakeholders, focusing on the customer, embracing innovation, ensuring data security and privacy, investing in training and education, and adopting an agile approach';
  context.keywords = 'Digital Transformation, Strategy, Business Goals, Stakeholders, Collaboration, Customer-Centric, Customer Experience, Personalization, Innovation, Emerging Technologies, Artificial Intelligence, Blockchain, Internet of Things (IoT), Data Security, Data Privacy, Training and Education, Employee Skills, Agile Approach, Iteration, Adaptation';
  context.seoTags();
}
