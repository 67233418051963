<section class="commonSection topMargin pt-20">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1><span>Big Data</span> Consultant</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>London Area, United Kingdom</span></li>
                        <li>Reports to: <span>Project Manager</span></li>
                        <li>Salary: <span>Competitive</span></li>
                        <li>Update On: <span>19 September, 2023</span></li>
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>                
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
                <app-share-on-details></app-share-on-details>
            </div>

            <div class="items-lg-col-8">
                <hr class="border mb-15">

                <div class="related-service mb-20">
                    <h2 class="text-black mb-0">Join Skillikz as a Big Data Consultant. Join hands-on projects, mentor a dynamic team, and engage in high-level client discussions. 7+ years of Informatica tools experience required.
                        Elevate your career with us!</h2>
                </div>
                <hr class="border mb-20">
                
                <div class="">
                    <h2 class="text-black">Responsibilities: </h2>
                    <ul class="list-black">
                        <li><strong>Training & Development:</strong> Offer comprehensive data analysis and usage training to staff and
                            management, especially within the financial service providers and utility service sectors.</li>
                          <li><strong>Project Specialisation:</strong> Lead and specialise in Big Data projects under the Think Big
                            initiative.
                          </li>
                          <li><strong>Technical Mastery:</strong> Possess a minimum of 7 years of full-time experience with Informatica
                            tools,
                            ETL/MDM/DQ tools and processes, databases, and data warehousing technologies.</li>
                          <li><strong>Architectural Guidance:</strong> Play a pivotal role in implementing architecture roadmaps for the
                            next
                            generation of Big Data Analytics solutions tailored to our client needs.</li>
                          <li><strong>Development:</strong> Create MR1/YARN applications using Java, Scala, or Python. Additionally, develop
                            Hive and Impala scripts.</li>
                          <li><strong>Project Leadership:</strong> Take charge of the end-to-end successful delivery of Information
                            Management
                            and analytics, specifically focusing on Big Data for our financial and utility clients.</li>
                          <li><strong>Service Development:</strong> Spearheaded the developing of industry-specific Business Analytics/Big
                            Data
                            services. Actively participate in Analytics communities to foster best practices and contribute to knowledge
                            management.</li>
                          <li><strong>Team Leadership:</strong> Lead and mentor a team of Big Data architects and engineers, ensuring the
                            delivery of value-driven solutions.</li>
                          <li><strong>Client Engagement:</strong> Engage with high-level client discussions, offering Point-of-Views,
                            conducting
                            Proof-of-Concepts, and crafting compelling business cases for Big Data and Analytics projects.</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">What do we look for?</h2>
                    <ul class="list-black">
                        <li>At least five years of experience as an SME in Business Intelligence disciplines.</li>
                        <li>At least two years of deep experience with Big Data solutions.</li>
                        <li>Basic expertise in working in a global environment.</li>
                        <li>Proven experience (minimum of 3 years) in managing complex, multi-dimensional demands.</li>
                        <li>Practical experience with customer onboarding, especially within the realm of financial data.</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="list-black mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career. By merging hands-on
                        client projects with vast resources, Skillikz offers a unique platform for growth, learning, and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant experience. We thank all
                        applicants for their interest, but only those selected for an interview will be contacted. Skillikz Ltd is an
                        equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>  
    </div>
  </section>


<section class="gradientTopTo">
    <section class="commonSection marginNone border-b bottomBg-">
        <div class="container-main">
            <div class="mb-60">
                <app-apply-form></app-apply-form>
            </div>
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<section class="sectionForm border-b">
    <div class="container-main">
        <div class="row-flex">
            <div class="custom-col-55">
                <div class="formContent scroll-elem slide-left">
                    <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                            redefine your online presence together.</span></h2>
                    <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                        email or text message.</p>
                </div>
            </div>
            <div class="custom-col-45">
                <!-- Contact Us form component -->
                <app-form></app-form>
            </div>
        </div>
    </div>
</section>