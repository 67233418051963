export class EmailJobApplicationForm {
    id: number;
    applyingForJobType: string;
    referal: string;
    referralCode: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    linkedInIdLink: string;
    currentJobDesignation: string;
    currentEmployer: string;
    totalExperience: string;
    relevantExperience: string;
    attachment: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    isActive: boolean;
    isDeleted: boolean;
}