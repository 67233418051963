import { Component, HostListener, OnInit } from '@angular/core';
import { BaseClass } from '../../modules/core/base/base-class';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseClass implements OnInit {

  
  public anim: any;
  public anim2: any;
  second: boolean = false;
  currentSliderIndex: number = 0;
  loadedSliders: number[] = [];

  videoSrc: string = "";
  
  @HostListener('window:resize', ['$event'])onResize(event) {
    if (window.innerWidth > 768.5)
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    else {
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
    }
  }

  constructor() {
    super()

  }

  ngOnInit(): void {
    init(this);

    if (window.innerWidth > 768.5)
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    else {
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
    }
 
  }

   

  updateDotsCss() {
    let dots = document.getElementsByClassName("dot");
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    dots[this.currentSliderIndex].className += " active";
  }



}



function init(context: HomeComponent) {
  context.title = 'Skillikz Ltd - Digital Transformation Consulting & Custom Software Development Services';
  context.description = 'Skillikz Ltd specializes in digital transformation consulting, custom software development, and technology consulting services. We offer services in business process automation, cloud migration, software development outsourcing, IT modernization, and more. ';
  context.keywords = 'Digital Transformation Services, Business Process Automation, Product Engineering Services, Product Lifecycle Management, Business Consulting Services, IT Consulting, Staff Augmentation Services, IT Staff Augmentation, E-Learning Services, Corporate Training Solutions, Mortgage Automation Solutions, Mortgage Workflow Automation, Financial Digital Transformation, Fintech Solutions, Cloud Transformation Services, Cloud Migration Solutions, Cloud Support Services, Cloud Infrastructure Support, Cybersecurity Solutions, Network Security, Customer Journey Mapping, Customer Experience Automation, Technology Consulting Services, IT Consulting Solutions, Customer Experience Solutions, Customer Experience Strategy';
  context.seoTags();
}







