import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study1',
  templateUrl: './case-study1.component.html',
  styleUrls: ['./case-study1.component.css']
})
export class CaseStudy1Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy1Component) {  
  context.title = 'Business Digital Transformation Case Study | Skillikz';
  context.description = 'Explore our successful business digital transformation case study for a leading mortgage firm in the USA. Discover how Skillikz streamlined loan processing, reduced costs, and improved customer satisfaction through cutting-edge technology.';
  context.keywords = 'Mortgage Automation Solutions, Loan Origination Automation, Mortgage Workflow Automation, Digital Mortgage Processing, Mortgage Document Management, Automated Loan Processing, Mortgage Software Solutions, Mortgage Compliance Automation, Loan Approval Automation, Mortgage Application Automation, Mortgage Underwriting Automation, Robotic Process Automation (RPA) for Mortgages, AI in Mortgage Processing, Mortgage Back-End Automation, Mortgage Loan Automation Systems, E-Signatures for Mortgages, Mortgage Data Automation, End-to-End Mortgage Automation, Mortgage Closing Process Automation, Mortgage Servicing Automation';
  context.seoTags();
}