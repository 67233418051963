<section class="commonSection topMargin pt-20">
  <div class="container-main">
      <app-breadcrumb></app-breadcrumb>

      <div class="row-flex">
          <div class="items-lg-col-8">                
            <div class="">
              <h1><span>Chaos & Resiliency</span> Test Engineer</h1>
            </div>
        
            <div class="job-details">
              <ul>
                <li>Location: <span>India</span></li>
                <li>Reports to: <span>Project Manager</span></li>
                <li>Salary: <span>Competitive</span></li>
                <li>Update On: <span>06 Nov, 2022</span></li>
              </ul>
            </div>
  
          <div class="work-mode">
            <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
          </div>
          </div>

          <!-- share-component-here -->
          <div class="items-lg-col-3 topShareIcon">
              <app-share-on-details></app-share-on-details>
          </div>

          <div class="items-lg-col-8">
              <hr class="border mb-15">

              <div class="related-service mb-20">
                <h2 class="text-black mb-0">We are looking for someone with Strong SRE & DevOps experience to help us test the resiliency of our application by applying the Chaos engineering best practices.</h2>
              </div>
              <hr class="border mb-20">
              
              <div class="">
                <h2 class="text-black">Skill Required</h2>
                <p class="mb-5">Required Skill Set</p>
                <ul class="list-black">
                  <li>Write and Deploy Chaos experimentation using Open Source tools (i.e. Chaos Mesh) and home-grown Chaos toolsets.</li>
                  <li>Must have strong Knowledge of Cloud Infrastructure, Application Development and Networking</li>
                  <li>Familiar with CI/CD Pipelines (Jenkins) and containerization (Docker/Kubernetes)</li>
                  <li>Familiar with observability tooling (Grafana, DataDog, Prometheus)</li>
                  <li>Work with application development teams in implementing chaos experimentation</li>
                  </ul>
              </div>
        
              <div class="">
                <h2 class="text-black">Salary and Benefits</h2>
                <ul class="list-black">
                  <li>Competitive salary depending on skills and experience</li>
                  <li>Flexible working hours</li>
                  <li>Remote working available for this role</li>
                </ul>
              </div>

          </div>

      </div>  
  </div>
</section>


<section class="gradientTopTo">
  <section class="commonSection marginNone border-b bottomBg-">
      <div class="container-main">
          <div class="mb-60">
              <app-apply-form></app-apply-form>
          </div>
          <app-why-work-with-us></app-why-work-with-us>
      </div>
  </section>
</section>

<section class="sectionForm border-b">
  <div class="container-main">
      <div class="row-flex">
          <div class="custom-col-55">
              <div class="formContent scroll-elem slide-left">
                  <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                          redefine your online presence together.</span></h2>
                  <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                      email or text message.</p>
              </div>
          </div>
          <div class="custom-col-45">
              <!-- Contact Us form component -->
              <app-form></app-form>
          </div>
      </div>
  </div>
</section>