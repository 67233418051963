import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-technology-consulting',
  templateUrl: './technology-consulting.component.html',
  styleUrls: ['./technology-consulting.component.css']
})
export class TechnologyConsultingComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
  
 
  showMoreText(obj:any) {
    let paragraphTag = obj.parentNode.previousElementSibling;
    let btnText = obj;
    if (obj.innerHTML === "Read less") {
        btnText.innerHTML = "Read more";
        paragraphTag.classList.remove("moreText");
    } else {
        btnText.innerHTML = "Read less";
        paragraphTag.classList.add("moreText");
    }
}

}

function init(context: TechnologyConsultingComponent) {  
  context.title = 'Consulting Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd provides technology consulting services to help firms achieve their technology goals. Our services include IT strategy development, solution architecture, and project management. Contact us today to learn more.';
  context.keywords = 'Business Consulting Services, IT Consulting, Strategy Consulting, Technology Consulting, Digital Transformation Consulting, Management Consulting, Cloud Consulting Services, Operations Consulting, Product Development Consulting, Process Improvement Consulting, Business Process Consulting, Agile Consulting, Cybersecurity Consulting, Data Analytics Consulting, DevOps Consulting Services, Organizational Change Consulting, Risk Management Consulting, Innovation Consulting, Staff Augmentation Consulting, Enterprise Consulting Solutions';
  context.seoTags();
}
