import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-golang-software-engineer',
  templateUrl: './golang-software-engineer.component.html',
  styleUrls: ['./golang-software-engineer.component.css']
})

export class GolangSoftwareEngineerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: GolangSoftwareEngineerComponent) {  
  context.title = 'Golang Software Engineer Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'Golang Developer, Golang Software Engineer, Go Programming Language, Go Developer Services, Golang Backend Developer, Golang Application Development, Golang Software Solutions, Go Lang Engineering, Go Developer Expertise, Golang Microservices, Golang Cloud Development, Golang Web Development, Golang API Development, Golang Performance Optimization, Go Developer Hiring, Golang Development Services, Go Programming for Scalability, Golang System Design, Golang for Distributed Systems, Golang DevOps Integration';
  context.seoTags();
}
