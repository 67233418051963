import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-big-data-consultant',
  templateUrl: './big-data-consultant.component.html',
  styleUrls: ['./big-data-consultant.component.css']
})
export class BigDataConsultantComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BigDataConsultantComponent) {  
  context.title = 'Big Data Consultant Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `Join Skillikz as a Big Data Consultant. Join hands-on projects, mentor a dynamic team, and engage in high-level client discussions. 7+ years of Informatica tools experience required.
  Elevate your career with us!`;
  context.keywords = 'Big Data Analytics, Data Architecture, Hadoop Ecosystem, Spark Framework, Data Warehousing, Cloud Data Solutions, ETL Processes, Data Lake Management, Machine Learning Integration, Data Governance, Predictive Analytics, NoSQL Databases (Cassandra, MongoDB), Real-Time Data Processing, Data Security and Privacy, Business Intelligence (BI), Data Strategy Development, Data Visualization Tools, Structured and Unstructured Data, Apache Kafka, Data Pipeline Optimization';
  context.seoTags();
}