<section class="section-inner-content topMargin">      
    <div class="container-main">

    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1>.NET Developer</h1>
    </div>

    <div class="job-details">
      <ul>
        <li>Location: <span>Essex, Basildon</span></li>
        <li>Reports to: <span>Project Manager</span></li>
        <li>Salary: <span>Competitive</span></li>
        <li>Update On: <span>Oct 01, 2022</span></li>
      </ul>
    </div>

    <div class="work-mode">
      <p>Work Mode: <span>Full Time</span></p>
    </div>

    <div class="row-flex mb-15">
      <div class="items-col-8">
        <div class="related-service">
          <h2 class="text-black">A technical professional with hands-on experience writing quality code and understanding business in Fintech and retails industry.</h2>     
        </div>
      </div>
      <div class="items-col-3">
        <div class="share-icons mb-15">
          <p>Share On:</p>
          <a href="#"><i class="fa-brands fa-square-facebook"></i> <span>Facebook</span></a>
          <a href="#"><i class="fa-brands fa-twitter"></i> <span>Twitter</span></a>
          <a href="#"><i class="fa-brands fa-linkedin"></i> <span>Linkedin</span></a> 
        </div>
        <button type="button" class="btn btn-blue btn-100" name="submit" aria-label="Go to Apply Form">Go to Apply Form</button>
      </div>            
    </div>


    <div class="content-card">

      <div class="">
        <h2 class="text-black">Duties & Responsibilities</h2>
        <p class="mb-5">Fundamental Responsibilities</p>
        <ul class="list-black">
          <li>Producing code using .NET languages (C#, .NET Core, Web API)</li>
          <li>Upgrading, configuring and debugging existing systems</li>
          <li>Writing unit test cases</li>
        </ul>
      </div>

      <div class="">
        <h2 class="text-black">Skill Required</h2>
        <p class="mb-5">Required Skill Set</p>
        <ul class="list-black">
          <li>Capable of understanding business requirements and writing a good quality code</li>
          <li>Hands-on with the Latest Dot Net framework, including .Net Core</li>
          <li>Intensive hands-on application development experience</li>
          <li>Understanding of Agile methodologies</li>
          <li>Good knowledge of OOPS concept experience in writing code using C#</li>
          <li>Experienced in writing Web API using .Net Core</li>
          <li>Hands-on experience in writing Unit Test cases</li>
          <li>Experience in writing queries using SQL Server or any other RDBMS database</li>
          <li>Experience with any ORM tool</li>
          <li>CI/CD experience</li>
          <li>Working knowledge on any code versioning tool using any tool</li>
          <li>Good Communications skills</li>
          <li>Should be flexible at work and must be task driven than clock oriented</li>
          <li>Good interpersonal skills along with self-drive for excellence</li>
        </ul>
      </div>

      <div class="">
        <p class="mb-5">Desirable Skills:</p>
        <ul class="list-black">
          <li>Working knowledge on any cloud plate form (Azure, AWS, GCP)</li>
        </ul>
      </div>

      <div class="">
        <h2 class="text-black">Qualifications</h2>
        <p class="mb-5">Required Qualification</p>
        <ul class="list-black">
          <li>A bachelor/Master's degree (IT, Computer Science preferred) with a minimum of 5+ years of experience</li>
        </ul>
      </div>

    </div>


    <div class="">
      <h2 class="text-black">About Skillikz</h2>
      <p>We specialise in Organisational development, with a rich pedigree in Information Technology. We combine strategy, design, and software engineering to provide an environment for businesses and technology firms with disruptive ideas worldwide to succeed as cutting-edge digital firms.</p>
    </div>








    <div class="content-card">
      <h2 class="text-black">Benefits</h2>
      <div class="row-flex">
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>People</h3>
            <p>We listen, learn and laugh and create together.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Growth</h3>
            <p>It's fast, exciting, innovative and long-standing.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Culture</h3>
            <p>It's open, collaborative, and builds friendships.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Voice</h3>
            <p>Everyone's voice counts.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Buzz</h3>
            <p>Fast-paced and challenging, but always rewarding.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Trust</h3>
            <p>We all have a job to do, and we're trusted to do it.</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Perks</h3>
            <p>Get an extra day off on your birthday!</p>         
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-benefits">
            <h3>Development</h3>
            <p>We're supported to grow our careers.</p>         
          </div>
        </div>
      </div>

    </div>


    <div class="dashed-wp">
      <h2 class="text-black">Job Application Form</h2>

      <div class="sub-title">
        <h3>Desired Job</h3>
      </div>

      <div class="row-flex mb-15">
        <div class="items-col-3">
          <div class="form-group">
            <label for="applyingFor" class="form-label">Applying For</label>
            <select class="form-control dropIcon" id="applyingFor">
              <option>Select Job Type</option>
            </select>
          </div>
        </div>
        <div class="items-col-3">
          <div class="form-group">
            <label for="referralCode" class="form-label">Referal</label>
            <div class="check-flex">
              <div class="itemCheck">
                <div class="customRadio">
                  <input type="radio" id="yesId" name="yesNo">
                  <label for="yesId">Yes</label>
                </div>
              </div>
              <div class="itemCheck">
                <div class="customRadio">
                  <input type="radio" id="noId" name="yesNo">
                  <label for="noId">No</label>
                </div>
              </div>          
            </div>
          </div>
        </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="referralCode" class="form-label">Referral Code</label>
          <input type="text" class="form-control" id="referralCode" name="" placeholder="Type your code here">
        </div>
      </div>
        </div>


        <div class="sub-title">
          <h3>Personal Information</h3>
        </div>

        <div class="row-flex mb-15">
        <div class="items-col-3">
          <div class="form-group">
            <label for="fullName" class="form-label">Full Name*</label>
            <input type="text" class="form-control" id="fullName" name="" placeholder="Type your full name here">
          </div>
        </div>
        <div class="items-col-3">
          <div class="form-group">
            <label for="e-mail" class="form-label">E-mail*</label>
            <input type="text" class="form-control" id="e-mail" name="" placeholder="Type your email address here">
          </div>
        </div> 
        <div class="items-col-3">
          <div class="form-group">
            <label for="phoneNumber" class="form-label">Phone Number*</label>
            <input type="text" class="form-control" id="phoneNumber" name="" placeholder="Type your contact number...">
          </div>
        </div> 
        <div class="items-col-3">
          <div class="form-group">
            <label for="linkedInIdLink" class="form-label">LinkedIn ID Link</label>
            <input type="text" class="form-control" id="linkedInIdLink" name="" placeholder="Provide your LinkedIn profile link here">
          </div>
        </div>
      </div>


      <div class="sub-title">
        <h3>Professional Information</h3>
      </div>

      <div class="row-flex mb-15">
      <div class="items-col-3">
        <div class="form-group">
          <label for="currentJobDesignation" class="form-label">Current Job Designation</label>
          <input type="text" class="form-control" id="currentJobDesignation" name="" placeholder="Type your current job designation here">
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="currentEmployer" class="form-label">Current Employer</label>
          <input type="text" class="form-control" id="currentEmployer" name="" placeholder="Type your employer name here">
        </div>
      </div> 
      <div class="items-col-3">
        <div class="form-group">
          <label for="totalExperience" class="form-label">Total Experience</label>
          <input type="text" class="form-control" id="totalExperience" name="" placeholder="Your total working experience">
        </div>
      </div> 
      <div class="items-col-3">
        <div class="form-group">
          <label for="relevantExperience" class="form-label">Relevant Experience</label>
          <input type="text" class="form-control" id="relevantExperience" name="" placeholder="Experience relevant to job applying for">
        </div>
      </div>
    </div>


    <div class="sub-title">
      <h3>Upload Documents</h3>
    </div>

    <div class="row-flex mb-15">
    <div class="items-col-3">
      <div class="form-group">
        <label for="uploadYourResume" class="form-label">Upload Your Resume</label>
        <input type="text" class="form-control" id="uploadYourResume" name="" placeholder="">
      </div>
    </div>
    </div>


    <div class="sub-title">
      <h3>Submit Form</h3>
    </div>

    <div class="row-flex mb-15">
    <div class="items-col-3">
      <button type="button" class="btn btn-blue btn-100" aria-label="Submit Application Form">Submit Application Form</button>
    </div>
    </div>

</div>
    
    </div>
  </section>
