<!-- ---Cookies-wrapper beginning here-- -->
<div class="overlay" *ngIf="!isCookie">
    <div class="cookie-wrapper">
        <div class="container-main">
            <h2>This website uses cookies</h2>
            <div class="cookies-flex">
                <div class="itemsContent">
                    <p>Skillikz.com relies on cookies to improve your online experience. Cookies are used to play videos
                        and
                        to analyze our website traffic.</p>

                    <button type="button" class="btn-outline-blue" aria-label="Edit Preference Settings"
                        (click)="moreinfo()">More Info</button>
                </div>
                <div class="itemsBtn">
                    <button type="button" class="btn-outline-blue" aria-label="Edit Preference Settings"
                        (click)="showSettings()">Edit Preference Settings</button>
                    <button type="button" class="btn-blue" aria-label="Accept All Cookies"
                        (click)="onDismissClick()">Accept
                        All Cookies</button>
                </div>
            </div>
        </div>
    </div>

    <!-- ---Cookies modal beginning here-- -->
    <div class="settingsModal">
        <div class="modal-flex">
            <div class="modalTitle">
                <h2>Edit Preference Settings</h2>
                <a href="javascript:void(0)" role="button" (click)="closeSettings()" title="Close"><img
                        src="assets/images/icons/cross-icon.svg" alt="cancel" width="" height=""></a>
            </div>

            <div class="modalBody">

                <div class="mb-20">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="functional" id="functional" checked />
                        <label for="functional">Necessary</label>
                    </div>
                    <p>Necessary cookies help make a website usable by enabling basic functions like page navigation and
                        cookie consent. The website cannot function properly without these cookies.</p>
                </div>

                <div class="mb-20">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="marketing" id="analytics" checked />
                        <label for="analytics">Preference</label>
                    </div>
                    <p>Preference cookies enable a website to remember information that changes the way the website
                        behaves or looks, like your preferred language. </p>
                </div>

                <div class="mb-20">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="advertising" id="advertising" checked />
                        <label for="advertising">Statistics</label>
                    </div>
                    <p>These cookies are used to analyze our web traffic. They allow us to count visits and traffic
                        sources so we can measure and improve the performance of our site. They help us to know which
                        pages are the most and least popular and see how visitors move around the site. All information
                        these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies
                        we will not know when you have visited our site, and will not be able to monitor its
                        performance.</p>
                </div>

                <div class="mb-20">
                    <div class="customcheckeBox">
                        <input type="checkbox" name="essential" id="essential" checked />
                        <label for="essential">Targeting</label>
                    </div>
                    <p>These cookies are set by third parties such as YouTube or Twitter. They track your behavior such
                        as playing videos or which tweets you have already viewed. If you do not allow these cookies you
                        will not be able to watch videos on this website. These cookies may be used by the cookie
                        provider to build a profile of your interests and show you relevant advertisements on other
                        sites. They do not store directly personal information, but are based on uniquely identifying
                        your browser and internet device. </p>
                </div>
            </div>


            <div class="modal-footer">
                <div class="row-flex">
                    <div class="items-col-1">
                        <button type="button" class="btn-blue" aria-label="Save Preference"
                            (click)="onDismissClick()">Save Preference</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- ---Cookies modal modal end here-- -->

    <div class="cookiespolicyModal">
        <div class="modal-flex">

            <div class="modalTitle">
                <h2>Cookies Policy</h2>
                <a href="javascript:void(0)" role="button" (click)="closecookiesSettings()" title="Close"><img
                        src="assets/images/icons/cross-icon.svg" alt="cancel" width="" height=""></a>
            </div>
            <div class="modalBody">
                <div class="mb-20">
                    <h2 class="text-black">Introduction</h2>
                    <p>This Cookies Policy (“Policy”) explains how Skillikz Ltd. (“Skillikz”, “we”, “us”, or “our”) uses
                        cookies
                        and similar technologies on our website, www.skillikz.com (the “Website”). </p>
                </div>

                <div class="mb-20">
                    <h2 class="text-black">What are Cookies?</h2>
                    <p>Cookies are small text files that are placed on your computer or mobile device when you visit a
                        website.
                        They are widely used to make websites work, or work more efficiently, as well as to provide
                        information
                        to the owners of the website. </p>
                </div>

                <div class="mb-20">
                    <h2 class="text-black">What Cookies do we use? </h2>
                    <p class="mb-5">We use cookies and similar technologies on our Website to collect and store certain
                        information about your visit. The cookies we use on our Website fall into the following
                        categories: </p>
                    <ul class="list">
                        <li><strong>Essential Cookies:</strong> These cookies are necessary for the Website to function
                            and
                            cannot be switched off in our systems. They are usually only set in response to actions made
                            by you
                            which amount to a request for services, such as setting your privacy preferences, logging in
                            or
                            filling in forms. </li>
                        <li><strong>Analytics Cookies:</strong> These cookies allow us to count visits and traffic
                            sources so we
                            can measure and improve the performance of our Website. They help us to know which pages are
                            the
                            most and least popular and see how visitors move around the site. </li>
                        <li><strong>Advertising Cookies:</strong> These cookies may be set through our site by our
                            advertising
                            partners. They may be used by those companies to build a profile of your interests and show
                            you
                            relevant adverts on other sites. </li>
                    </ul>
                </div>

                <div class="mb-20">
                    <h2 class="text-black">How to Control Cookies</h2>
                    <p>Most web browsers allow you to control cookies through their settings preferences. However, if
                        you limit
                        the ability of websites to set cookies, you may worsen your overall user experience, since it
                        will no
                        longer be personalized to you. It may also stop you from saving customized settings like login
                        information. </p>
                </div>

                <div class="mb-20">
                    <h2 class="text-black">Changes to this Policy</h2>
                    <p>We reserve the right to update or change this Policy at any time. We will post the most current
                        version
                        of the Policy on our Website with the effective date. Your continued use of our Website after we
                        have
                        posted changes to the Policy will indicate that you have accepted the updated Policy. </p>
                </div>

                <div class="mb-20">
                    <h2 class="text-black">Contact Us </h2>
                    <p>If you have any questions or concerns about our use of cookies, please contact us at <a
                            class="hyperlink" href="mailto:info@skillikz.co.uk">info@skillikz.co.uk</a>.</p>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row-flex">
                    <div class="items-col-1">
                        <button type="button" class="btn-blue" aria-label="Close"
                        (click)="closecookiesSettings()">Close</button>
                    </div>

                </div>
            </div>
        </div>



    </div>


</div>