<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Information</span> Security</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Strengthen business compliance with our CPD accredited cyber and data security courses, covering GDPR, Cyber Security, and more. Flexible learning, intuitive interface, interactive scenarios, and immediate practical application.</p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/information-security-img.webp" alt="Information Security image" width="485"
              height="432" onerror="this.src='assets/images/information-security-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Business Compliance Essentials</h3>
            <p>Equip yourself and your team with Cyber Security, Data Protection, Fraud Protection, and more to comply
              with current legislation.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:263,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Conflict Resolution</h3>
            <p>Identify and resolve workplace conflicts effectively. Seek guidance when needed for optimal conflict
              management.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:262,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Customer Service</h3>
            <p>Master the art of exceptional customer experiences, measuring satisfaction, and meeting customer
              expectations.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:264,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Effective Remote Working</h3>
            <p>Thrive in a remote work environment by building online relationships, communicating flexibly, and
              organizing effectively.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:265,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Cyber Security</h3>
            <p>Understand the significance of cyber and information security. Learn about cyberattacks like phishing,
              malware, ransomware, and identity theft. Improve Cyber Security through password...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:204,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Data Protection and GDPR level 3</h3>
            <p>This course provides an understanding of the data protection legislation within the UK and EU and the
              responsibilities of organizations in protecting personal and sensitive data. It...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:205,group:18" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
      </div>



      <!-- --------Hidden courses wrapper beginning here----------- -->
      <div class="">

        <div class="hidden-wrapper">
          <div class="row-flex">
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>GDPR Awareness Level 2</h3>
                <p>This is a UK & EU Data Protection awareness program sample written and directed internally</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:243,group:18" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="show-all-wp">
          <div class="">
            <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
              Courses</button>
          </div>
        </div>


      </div>
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>

  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>


      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Information Security Courses</p>
                <p>Customer Service</p>
                <p>Cyber Security</p>
                <p> Effective Remote Working</p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=xB4RBP0K-6o&list=PLAy395buT7V6ZK7vYKeOcgLDvwP55rWyI" target="_blank">
              <div class="">
                <img src="assets/images/social-media-youtube-img4.webp" alt="social Media Youtube img" width="412"
                  height="232" onerror="this.src='assets/images/social-media-youtube-img4.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=xB4RBP0K-6o&list=PLAy395buT7V6ZK7vYKeOcgLDvwP55rWyI" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Navigating Regulatory Compliance: Challenges businesses face & the vitality of training employees on compliance.</p>
                <p>Challenges: Evolving environment, Complexity, Resource constraints, Multi-Jurisdictional compliance, Data privacy & security.</p>
                <p>Importance of Training Employees: Help them succeed, Avoid penalties, Create a safe workplace, Build a healthy culture, Stay up to date, Protect the bottom line, and Strengthen your brand.</p>
                <p>Prioritising compliance training brings legal & business benefits. Avoid fines, improve culture, boost the bottom line, and strengthen the brand. Empower employees to navigate regulations for success.</p>
                <p>Read our article <a href="https://buff.ly/3XcSILo" target="_blank">
                  https://buff.ly/3XcSILo</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_compliancetraining-regulatorycompliance-businesssuccess-activity-7084471533334585344-h7pE?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img1.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img1.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_compliancetraining-regulatorycompliance-businesssuccess-activity-7084471533334585344-h7pE?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Ensuring Financial Compliance: Tackling Challenges</p>
                <p>Digital Transformation: Keep up with evolving regulations in the fast-paced digitization era.</p>
                <p>Compliance Cost: Reduce expenses by automating regulatory reporting with AI-powered technology.</p>
                <p>Cyberattacks: Strengthen risk management to protect against security breaches.</p>
                <p>FESG Issues: Enhance transparency and meet regulatory demands for ESG reporting.</p>
                <p>Data Privacy: Safeguard sensitive information with robust policies and technology.</p>
                <p>Read our article <a href="https://buff.ly/3CUGTk0" target="_blank">https://buff.ly/3CUGTk0</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_financialcompliance-ukmarket-compliancechallenges-activity-7081934936366010368-4qKp?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img2.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img2.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_financialcompliance-ukmarket-compliancechallenges-activity-7081934936366010368-4qKp?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/information-security'"></app-other-course-catalogues>

  </div>
</section>