import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-health-and-safety',
  templateUrl: './health-and-safety.component.html',
  styleUrls: ['./health-and-safety.component.css']
})
export class HealthAndSafetyComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:20';
    window.open(url, '_blank');
  }
}


function init(context: HealthAndSafetyComponent) {
  context.title = 'Health & Safety | Skillikz Ltd ';
  context.description = 'Ensure workplace safety with our CPD accredited courses covering COSHH, RIDDOR, Risk Assessment and more. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited. ';
  context.keywords = 'Health and Safety Services, Workplace Safety, Occupational Health and Safety, Safety Training Programs, Risk Assessment Services, Safety Compliance Consulting, Health and Safety Audits, Employee Health Programs, Environmental Health and Safety (EHS), Safety Management Systems, Fire Safety Training, Construction Site Safety, Ergonomics and Workplace Wellness, Hazard Identification, Health and Safety Policies, Industrial Hygiene Services, Safety Standards Compliance, Emergency Preparedness, COVID-19 Safety Measures, Incident Investigation and Reporting';
  context.seoTags();
}
