import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { AppInjector } from "./AppInjector";

@Component({
  template: ""
})

export abstract class BaseClass {

  private _title: string;
  private _description: string;
  private _keywords: string;
  private _tags: string;

  get titleService(): Title {
    return AppInjector.injector?.get(Title);
  }
  get metaTagService(): Meta {
    return AppInjector.injector?.get(Meta);
  }
  set title(title: string) {
    this._title = title;
  }
  set keywords(keywords: string) {
    this._keywords = keywords;
  }
  set description(description: string) {
    this._description = description;
  }
  set tags(tags: string) {
    this._tags = tags;
  }
  get title() {
    return this._title;
  }
  get keywords() {
    return this._keywords;
  }
  get description() {
    return this._description;
  }
  get tags() {
    return this._tags;
  }


  constructor() {
  }

  seoTags() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }

    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: this.keywords },
    );
  }


  //When the user clicks anywhere outside of the modal, close it
  // showProDetails() {
  //     var profileoverlay = document.getElementById("bgOverlay");
  //     if (event.target == profileoverlay) {
  //         profileoverlay.style.display = "none";
  //     }
  // }
  ngAfterViewInit() {
    this.handleClickEvents();

    //function Hidden courses here
    var acc = document.getElementsByClassName("showAllId");
    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function (this: any) {
        var panel = this.parentNode.parentNode.previousElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          this.innerHTML = "Show All Courses";
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
          this.innerHTML = "Hide Courses";
        }
      });
    }

    //function show more text in Social Media----
    var moreText = document.getElementsByClassName("btnShowMore");
    for (let i = 0; i < moreText.length; i++) {
      moreText[i].addEventListener("click", function (this: any) {
        //let panels = this.parentNode.parentNode.previousElementSibling;
        let panels = this.parentNode.previousElementSibling;
        if (panels.style.maxHeight) {
          panels.style.maxHeight = null;
          this.innerHTML = "Show More";
        } else {
          panels.style.maxHeight = panels.scrollHeight + "px";
          this.innerHTML = "Less More";
        }
      });
    }


    const scrollElem = document.querySelectorAll(".scroll-elem");
    setTimeout(() => {
      scrollElem.forEach((el) => {
        el.classList.remove("scroller");
      });
    }, 800);

  }

  protected handleClickEvents() {
    window.onclick = function (event) {
      var profileoverlay = document.getElementById("bgOverlay") as HTMLElement;
      if (event.target == profileoverlay) {
        profileoverlay.style.display = "none";
      }
    };


    const scrollElems = document.querySelectorAll(".scroll-elem");
    const viewElement = (el, dividend = 1) => {
      const topElement = el.getBoundingClientRect().top;

      return (
        topElement <=
        (window.innerHeight || document.documentElement.clientHeight) / dividend
      );
    };

    const outofViewElement = (el) => {
      const topElement = el.getBoundingClientRect().top;
      return (
        topElement > (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    const displayElementScroll = (element) => {
      element.classList.add("scroller");
    };

    const hideElementScroll = (element) => {
      element.classList.remove("scroller");
    };

    const handleAnimationScroll = () => {
      scrollElems.forEach((el) => {
        if (viewElement(el, 1.25)) {
          displayElementScroll(el);
        }
        // else if (outofViewElement(el)) {
        //   hideElementScroll(el);
        // }
      });
    };

    window.addEventListener("scroll", () => {
      handleAnimationScroll();
    });
  }

  scrollInto(el: HTMLElement | string) {
    if (typeof el === 'string') {
      el = document.getElementById(el);
    }
    el.scrollIntoView();
  }


  // Location tab function here
  showLocationTab(evt, tabName) {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");

    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    let tabname = document.getElementById(tabName + 'Tab')
    let tabname2 = document.getElementById(tabName)
    if (tabname2) {
      tabname2.style.display = "block";
    }
    if (tabname)
      tabname.className += " active";
    window.scrollTo(0, 450);
  }
  // Get the element with id="defaultOpen" and click on it
  // document.getElementById("defaultOpen").click();

  // Location tab function end here



  // Technology tab function here
  showTechnologyTab(evt, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }
  // Get the element with id="defaultOpen" and click on it
  // document.getElementById("defaultOpen").click();

  // Technology tab function end here

  //When the user scrolls down 150px from the top of the document, show the button

  showScrolltopbtn() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      document.getElementById("gototop-btn").style.display = "block";
    } else {
      document.getElementById("gototop-btn").style.display = "none";
    }
  }

  removeTransparentHeder() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.querySelector('header').classList.remove("transparentHeader");
    } else {
      document.querySelector('header').classList.add("transparentHeader");
    }
  }



  // When the user clicks on the button, scroll to the top of the document

  gotoTopFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }



  // function show more text in Social Media cards
  showMore(obj: any) {
    let paragraphTag = obj.parentNode.previousElementSibling;
    let btnText = obj;
    if (obj.innerHTML === "Less More") {
      btnText.innerHTML = "Show More";
      paragraphTag.classList.remove("showMore");
    } else {
      btnText.innerHTML = "Less More";
      paragraphTag.classList.add("showMore");
    }
  }





  //show Popup modal menu function here
  showPopupModal() {
    (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "block";
    (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "block";
    document.querySelector('body').classList.add("openModal");
  }

  closePopupModal() {
    (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "none";
    (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "none";
    document.querySelector('body').classList.remove("openModal");
  }



  //close menu onclick on siteBackdrop function here
  closeAll() {
    (document.querySelector('.siteBackdrop') as HTMLElement)!.style.display = "none";
    (document.querySelector('.mobileMenuList') as HTMLElement)!.style.right = "-290px";
    document.querySelector('body').classList.remove("openModal");

    var isSmallModal = document.getElementsByClassName('pupupModal');
    if (isSmallModal.length > 0) {
      (document.querySelector('.pupupModal') as HTMLElement)!.style.display = "none";
      document.querySelector('body').classList.remove("openModal");
    }


  }












}