import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmailContactUs } from '../../modals/email-contact-us.modal';
import { ApiResponseCallback } from '../../modules/core/base/api.service';
import { BaseClassApiService } from '../../modules/core/base/base-class-api-service';
// import * as emailjs from '@emailjs/browser';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService extends BaseClassApiService{

  constructor(private injector: Injector) { super(injector)}

  public _formGroup: FormGroup;

  public getformGroup(): FormGroup {
    return this._formGroup;
  }
  public setformGroup(value: FormGroup) {
    this._formGroup = value;
  }

  submit(apiResponseCallback: ApiResponseCallback) {
    this.apiService.hitPostApi("https://publicemail.azurewebsites.net/api/WebEmailContactUs", createJsonForUContactUs(this, createRequestJson(this)),apiResponseCallback);
  }
}

function createRequestJson(context: ContactUsService) {
  console.log(context);
  let requestJson = {};
  Object.keys(context._formGroup.controls).forEach(key => {
    let value = context._formGroup.get(key).value;
    requestJson[key] = value;
  });
  return requestJson;
}

function createJsonForUContactUs(context: ContactUsService, requestJson: any) {
  let emailContactUs: EmailContactUs = new EmailContactUs();
  console.log(requestJson);
  emailContactUs.firstName = requestJson.firstName;
  emailContactUs.lastName = requestJson.lastName;
  emailContactUs.phoneNumber = requestJson.phoneNumber;
  emailContactUs.toAddress = requestJson.email;
  emailContactUs.message = requestJson.message;
  emailContactUs.createdBy = 0;
  emailContactUs.createdDate = new Date().toISOString();
  emailContactUs.modifiedBy = 0;
  emailContactUs.modifiedDate = new Date().toISOString();
  emailContactUs.isActive = true;
  emailContactUs.isDeleted = false;
  console.log(emailContactUs);

  return emailContactUs;
}