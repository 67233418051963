<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Health</span> & Safety</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Ensure workplace safety with our CPD accredited courses covering COSHH, RIDDOR, Risk Assessment, and more. Flexible learning, intuitive interface, interactive scenarios, and immediate practical application.</p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/health-and-safety-img.webp" alt="Health & Safety image" width="485" height="432"
              onerror="this.src='assets/images/health-and-safety-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>

    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Control of Substances Hazardous to Health (COSHH)</h3>
            <p>The topic comes with the Control of Substances Hazardous to Health, common hazardous substances, how to
              reduce risk when working with dangerous substances, and understanding labels and datasheets.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:203" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Display Screen Equipment (DSE)</h3>
            <p>This course helps you understand what is covered by a display screen equipment (DSE) risk assessment
              and how to reduce risk when working with DSE.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:206" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Driving Safely</h3>
            <p>This topic comes with understanding what road-related activity is covered by health and safety law, the
              road-safety checklist for reducing risks associated with driving at work.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:245" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Electrical Safety</h3>
            <p>This course tells how electricity can cause harm and how to reduce risk when working with electricity.
            </p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:209" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Fire Safety Basics</h3>
            <p>This course consists of the most common causes, locations, times, dangers of fire, and your rights and
              responsibilities.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:212" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Hazards, Risk And Risk Assessment</h3>
            <p>This course provides information on the difference between a hazard and a risk, and the importance of
              risk assessment in a workplace, Understanding the difference between hazards and risks.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:215" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>

      </div>



      <!-- --------Hidden courses wrapper beginning here----------- -->
      <div class="">

        <div class="hidden-wrapper">
          <div class="row-flex">
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Health and Safety - Level 2</h3>
                <p>This course consists of why health and safety are important in a workplace.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:216" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Manual Handling Basics</h3>
                <p>This course tells what operations are covered when we're talking about manual handling, the hazards
                  associated with manual handling operations, and manual handling law.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:219" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Noise At Work</h3>
                <p>This course tells how noise at work can be harmful to one’s health.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:223" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>RIDDOR</h3>
                <p>This course provides information on what RIDDOR is and its importance, and who is responsible for the
                  report.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:227" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Slips Trips & Falls</h3>
                <p>This course contains the most common causes of Slips, Trips and Falls, and what can be done to
                  prevent
                  incidents.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:230" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Working At Heights</h3>
                <p>This course provides information on what is considered to be working at height and what control
                  measures can be taken to reduce the risk of falling.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:233" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Personal Protective Equipment (PPE)</h3>
                <p>This course provides information on the importance of Personal Protective Equipment and the different
                  types of PPE.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:224" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="show-all-wp">
          <div class="">
            <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
              Courses</button>
          </div>
        </div>


      </div>
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>


  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Health & Safety at Work</p>
                <p>Importance of Health and Safety at Work - Employer's Responsibility<br>
                  Control Of Substances Hazardous To Health COSHH <br>
                  Display Screen Equipment <br>                  
                  Diving Safety
                </p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=eANWiNyPz7w&list=PLAy395buT7V7QU2mXc5zN14ohEW3fdlnH" target="_blank">
            <div class="">
                <img src="assets/images/social-media-youtube-img1.webp" alt="social Media Youtube img" width="412"
                height="232" onerror="this.src='assets/images/social-media-youtube-img1.png'">
              </div>
            </a>              
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=eANWiNyPz7w&list=PLAy395buT7V7QU2mXc5zN14ohEW3fdlnH" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Navigating Regulatory Compliance: Challenges businesses face & the vitality of training employees on compliance.</p>
                <p>Challenges: Evolving environment, Complexity, Resource constraints, Multi-Jurisdictional compliance, Data privacy & security.</p>
                <p>Importance of Training Employees: Help them succeed, Avoid penalties, Create a safe workplace, Build a healthy culture, Stay up to date, Protect the bottom line, and Strengthen your brand.</p>
                <p>Prioritising compliance training brings legal & business benefits. Avoid fines, improve culture, boost the bottom line, and strengthen the brand. Empower employees to navigate regulations for success.</p>
                <p>Read our article <a href="https://buff.ly/3XcSILo" target="_blank">https://buff.ly/3XcSILo</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_compliancetraining-regulatorycompliance-businesssuccess-activity-7084471533334585344-h7pE?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img1.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img1.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_compliancetraining-regulatorycompliance-businesssuccess-activity-7084471533334585344-h7pE?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Ensuring Financial Compliance: Tackling Challenges</p>
                <p>Digital Transformation: Keep up with evolving regulations in the fast-paced digitization era.</p>
                <p>Compliance Cost: Reduce expenses by automating regulatory reporting with AI-powered technology.</p>
                <p>Cyberattacks: Strengthen risk management to protect against security breaches.</p>
                <p>FESG Issues: Enhance transparency and meet regulatory demands for ESG reporting.</p>
                <p>Data Privacy: Safeguard sensitive information with robust policies and technology.</p>
                <p>Read our article <a href="https://buff.ly/3CUGTk0" target="_blank">https://buff.ly/3CUGTk0</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_financialcompliance-ukmarket-compliancechallenges-activity-7081934936366010368-4qKp?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img2.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img2.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_financialcompliance-ukmarket-compliancechallenges-activity-7081934936366010368-4qKp?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/health-and-safety'"></app-other-course-catalogues>

  </div>
</section>