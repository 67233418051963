import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-java-developer2',
  templateUrl: './java-developer2.component.html',
  styleUrls: ['./java-developer2.component.css']
})
export class JavaDeveloper2Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: JavaDeveloper2Component) {  
  context.title = 'Java Developer - SKILLIKZ';
  context.description = 'We are looking for a Java Developer with 2-3 years of core development experience.';
  context.keywords = 'Java Programming, Java SE (Standard Edition), Spring Framework, Hibernate ORM, RESTful Web Services, Java Persistence API (JPA), Object-Oriented Programming (OOP), JDBC (Java Database Connectivity), Maven/Gradle Build Tools, Multithreading, Java 8+ Features (Streams, Lambdas), JavaFX (UI Development), JUnit Testing, SQL and NoSQL Databases, Microservices Architecture, Apache Kafka, Agile Development, Version Control (Git), Cloud Integration (AWS, Azure), Continuous Integration/Continuous Deployment (CI/CD)';
  context.seoTags();
}
