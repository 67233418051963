import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-java-developer1',
  templateUrl: './java-developer1.component.html',
  styleUrls: ['./java-developer1.component.css']
})
export class JavaDeveloper1Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: JavaDeveloper1Component) {  
  context.title = 'Java Developer - SKILLIKZ';
  context.description = 'We are looking for a Java Developer with 4-6 years of core development experience.';
  context.keywords = 'Java Development, Spring Framework (Spring Boot, Spring MVC), Hibernate ORM, Microservices Architecture, RESTful APIs, JPA (Java Persistence API), Java 8+ Features (Streams, Lambdas), Multithreading and Concurrency, Apache Kafka, Kubernetes and Docker, Maven/Gradle Build Tools, Unit Testing (JUnit, TestNG), CI/CD Pipelines, SQL and NoSQL Databases, Java EE (Enterprise Edition), Cloud Platforms (AWS, Azure, GCP), Performance Optimization, Design Patterns, Agile/Scrum Methodologies, Application Security Practices';
  context.seoTags();
}