import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study5',
  templateUrl: './case-study5.component.html',
  styleUrls: ['./case-study5.component.css']
})
export class CaseStudy5Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy5Component) {  
  context.title = 'Cloud Support and Migration Services | Skillikz';
  context.description = 'Explore how Skillikz helped a medium-sized financial enterprise modernize its IT infrastructure and transition to the cloud. Discover our comprehensive cloud support, security, and disaster recovery solutions that ensured business continuity and ROI.';
  context.keywords = 'Cloud Support Services, Cloud Infrastructure Support, Cloud Managed Services, Cloud Maintenance Solutions, Cloud Service Management, Cloud Platform Support, Cloud Security Support, Cloud Migration Assistance, Cloud Backup Solutions, Cloud Monitoring Services, Cloud Troubleshooting Services, Cloud Optimization Support, Cloud Performance Management, Cloud Integration Support, Cloud Operations Support, 24/7 Cloud Support, Cloud IT Support, Cloud Disaster Recovery Support, Cloud Cost Management Support, Cloud Technical Support Services';
  context.seoTags();
}
