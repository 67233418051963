import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details38',
  templateUrl: './blogs-details38.component.html',
  styleUrls: ['./blogs-details38.component.css']
})
export class BlogsDetails38Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails38Component) {  
  context.title = 'Latest Trends in Frontend Technologies in Software Development';
  context.description = 'Stay up to date with the latest frontend technologies in software development. Learn about progressive web applications, single page applications, serverless architecture, micro frontends, low-code development, and web components';
  context.keywords = 'Progressive Web Applications (PWAs), Native Mobile Apps, Offline Functionality, Push Notifications, Single Page Applications (SPAs), Dynamic Page Updates, React, Angular, Vue.js, Serverless Architecture, Cloud Computing, Functions-as-a-Service (FaaS), AWS Lambda, Azure Functions, Micro Frontends, Modular Architecture, Low-Code Development, Drag-and-Drop Interfaces, Web Components, Custom Elements';
  context.seoTags();
}
