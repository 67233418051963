import { Component } from '@angular/core';

@Component({
  selector: 'app-articles-shared',
  templateUrl: './articles-shared.component.html',
  styleUrls: ['./articles-shared.component.css']
})
export class ArticlesSharedComponent {

}
