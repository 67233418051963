import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details20',
  templateUrl: './blogs-details20.component.html',
  styleUrls: ['./blogs-details20.component.css']
})
export class BlogsDetails20Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails20Component) {  
  context.title = 'Latest Practices in IT Product Development and Testing | Trends to Watch';
  context.description = 'Stay ahead of the competition with the latest trends in IT product development and testing. From Agile and DevOps to AI, ML, and test automation, learn how to deliver high-quality products quickly and efficiently';
  context.keywords = 'Agile Development, DevOps Methodology, Continuous Testing, Artificial Intelligence (AI), Machine Learning (ML), Cloud-based Testing, Shift-Left Testing, Test Automation, Continuous Integration (CI), Continuous Delivery (CD), Testing as a Service (TaaS), Iterative Development, Product Development Life Cycle, Automated Testing Tools, Testing Efficiency, Distributed Testing, Quality Assurance (QA), Testing in Cloud Environments, Collaboration in Development, Rapid Deployment';
  context.seoTags();
}
