import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.css']
})
export class CaseStudiesComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudiesComponent) {  
  context.title = "Case Studies | Skillikz Ltd ";
  context.description = "Check out Skillikz Ltd's works showcasing our expertise in IT product engineering, digital transformation, and technology consulting services. Contact us to learn how we can help your business succeed.";
  context.keywords = 'Skillikz, Case Studies, portfolio, software development, technology consulting, Salesforce implementation, UK, Technology consulting case studies, IT consulting case studies, Digital transformation case studies, Technology implementation case studies, Cloud consulting case studies, Data analytics consulting case studies, Cybersecurity consulting case studies, Technology strategy case studies, IT project management case studies, Enterprise technology case studies, Digital innovation case studies Technology consulting success stories, Technology consulting client stories, IT consulting project examples, Technology consulting case examples, Technology consulting case summaries.';
  context.seoTags();
}
