import { ApplicationRef, Injector } from '@angular/core';
import { ApiService, DataTransferService } from './api.service';
import { Constants } from './constants';


export abstract class BaseClassApiService {

    public constants: Constants;
    ar: ApplicationRef;
    public apiService: ApiService;
    protected dataService: DataTransferService;

    constructor(injector: Injector) {
        this.constants = injector.get(Constants)
        this.ar = injector.get(ApplicationRef);
        this.dataService = injector.get(DataTransferService);
        this.apiService = injector.get(ApiService);
    }

}
