<section class="section-inner-content topMargin">
    <div class="container-main bgWhite">

    <app-breadcrumb></app-breadcrumb>

      <div class="">
        <h1>Terms of Use - LMS</h1>
      </div>

      <div class="anchor-wp">
        <a (click)="scroll(hyperlinkOne)">Introduction</a><br>
        <a (click)="scroll(hyperlinkTwo)">Skillikz Learning Content</a><br>
        <a (click)="scroll(hyperlinkThree)">Your Responsibilities</a><br>
        <a (click)="scroll(hyperlinkFour)">Fees</a><br>
        <a (click)="scroll(hyperlinkFive)">Term</a><br>
        <a (click)="scroll(hyperlinkSix)">Termination</a><br>
        <a (click)="scroll(hyperlinkSeven)">Our Responsibilities</a><br>
        <a (click)="scroll(hyperlinkEight)">Your Information</a><br>
        <a (click)="scroll(hyperlinkNine)">Additional Terms</a>
      </div>

      <div class="forTarget">

      <div class="mb-20">
        <h2 class="text-black" #hyperlinkOne>Introduction</h2>
        <p>The following Terms of Use and our Privacy Policy and any Agreement you enter into
          setting out the agreement (“<strong>Agreemen</strong>t”) between Skillikz Ltd a company registered in England,
          with company number 13087133 and registered office at 152-160, Kemp House, London EC1V 2NX
          (“<strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>”) and any person
          (“<strong>you</strong>” or “<strong>your</strong>”) accessing and using our online training platform and its
          contents (“<strong>Skillikz LMS</strong>”) and/or our training Learning Content or resources used on your own
          training platform (“<strong>Skillikz SCORM Files</strong>”) as set out in an Agreement.</p>
      </div>


      <div class="mb-20">
        <h2 class="text-black" #hyperlinkTwo>Skillikz Learning Content</h2>
        <p>By contracting to access Skillikz LMS, you may access and use our online training platform, the courses
          available on the online training platform, our blended learning manual and any updates we make to any of them
          from time to time each in accordance with the Agreement.&nbsp; In accessing and using Skillikz LMS you are not
          granted a license to any software under the Agreement.</p>
        <p>By contracting to use Skillikz SCORM Files, your use of any content or software in connection with the
          Skillikz SCORM Files is subject to and governed by the terms of the end-user licence agreement (if any) which
          accompanies or is included with the Skillikz SCORM Files. You may not install or use any content or software
          in the Skillikz SCORM Files that is accompanied by or includes a licence agreement unless you first agree to
          the terms of such licence agreement. For any content or software not accompanied by a licence agreement, we
          hereby grant to you a revocable personal non-transferable licence to use Skillikz SCORM Files for viewing and
          otherwise in accordance with the Agreement.</p>
        <p>Skillikz LMS, Skillikz SCORM Files and Skillikz LTD (as defined below) together constitute the
          “<strong>Skillikz Learning Content</strong>”.&nbsp; During the Term set out in any Agreement, your permitted
          learners may access and/or use the Skillikz Learning Content as described in this Agreement. All such access
          and use by your permitted learners are subject to compliance with the Agreement and you will at all times
          remain liable for your permitted learners’ compliance with the Agreement.</p>
        <p class="mb-5">At any time, we may from time to time:</p>
        <ul class="list-black">
          <li>Modify the Skillikz Learning Content, including by amending content or Learning Content or functions;</li>
          <li>Use third-party service providers to provide some or all elements of the Skillikz Learning Content.</li>
        </ul>
      </div>

      

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkThree>Your Responsibilities</h2>
          <P class="mb-5">You must:</P>
          <ul class="list-black">
            <li>not use the Skillikz Learning Content in any way that or for any purpose or in any manner that is
              unlawful
              or prohibited by this Agreement;</li>
            <li>notify us as soon as possible of any unauthorised use of your Skillikz Learning Content account or of
              the
              Skillikz Learning Content themselves;</li>
            <li>not breach the intellectual property and other rights relating to the resources made available by and
              through the Skillikz Learning Content, in any way, including not copying, renting, leasing, selling,
              distributing or creating derivative works based on the Skillikz Learning Content in the whole or in part,
              by
              any means, except as expressly authorised in writing by us;</li>
            <li>not download, stream, copy, save, duplicate, store, archive, hold and or use any video or other material
              made available on or through the Skillikz Learning Content in any way other than as intended directly for
              and by us. To do so is a breach of copyright, for which action will be taken; and</li>
            <li>notify us of any change to your contact information, billing information and credit card information (as
              applicable) up to date.</li>
          </ul>
          <P>You understand that Learning Content made available in the Skillikz Learning Content is presented in good
            faith by us and is intended to promote the best-known practice but that it is your responsibility to make
            your
            own judgement as to the use of any material presented.</P>
          <P>You acknowledge that we, as providers of the Skillikz Learning Content, provide online and offline learning
            resources only. You are responsible for ensuring learners are suitably able, supported, supervised or
            similar,
            as appropriate, by you to use online and offline learning, including computer literacy issues, access to
            computer equipment and power supplies all of which are your responsibilities, and we are in no way
            responsible
            for any matters arising from this point. We provide no guarantee that the learner understands the subject,
            this is a matter for you to assess and manage appropriately.</P>
          <P>You are also responsible for ensuring learners are generally competent to, and physically capable of,
            undertaking any practical elements of the learning provided in the Skillikz Learning Content and that you
            have
            carried out and logged all necessary practical observations of such learners’ activities in respect of
            actions
            including, but not limited to, provision of CPR, use of fire equipment, moving and handling equipment and
            safe
            administration of medication. We are in no way responsible for your learners’ capabilities to be trained.
          </P>
          <P>Notwithstanding the other terms of the Agreement, you are liable for payment of fees for your access to or
            use of the Skillikz Learning Content as set out in the Agreement and for any violation of our or any third
            party’s intellectual property rights.</P>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkFour>Fees</h2>
          <div class="">
            <P class="mb-5">The fees payable by you to access the Skillikz Learning Content will remain fixed during the
              Initial Term of your right to use, unless:</P>
            <ul class="list-black">
              <li>you exceed your number of permitted learners;</li>
              <li>you request access to or supply of additional content or Learning Content or additional learner
                access;
                or</li>
              <li>otherwise agreed to by you in an agreement.</li>
            </ul>
            <P>Any learners additional to the total permitted learners who access Skillikz LMS (if applicable) in any
              year
              of the Term shall be charged on a pro-rata basis, per learner and per year, for that proportion of the
              relevant year of the Term in which they so access Skillikz LMS.</P>
            <P>The fees for any additional content or Learning Content requested by you shall be our then-current price
              as
              at the date of our agreement to give access to or supply such additional content or Learning Content. The
              fees for Skillikz Learning Content are subject to change at any time at our sole discretion prior to the
              date of purchase.</P>
          </div>

          <div class="">
            <p class="mb-5">In respect of the applicable fees:</p>
            <ul class="list-black">
              <li>if we give you the option you may pay for Skillikz Learning Content by credit card or debit card;</li>
              <li>if we agree to invoice you monthly, all amounts set out in the Agreement and invoiced are due and
                payable monthly within fourteen (14) days from the date of the invoice, unless otherwise specified in
                the
                Agreement; and</li>
              <li>if we agree to you paying annually when we receive an authorised cleared payment for the applicable
                fees
                we will grant you access to the relevant Skillikz Learning Content.</li>
            </ul>
            <p>We may ask you to pay for Skillikz Learning Content by credit card or debit card, by us sending you an
              invoice, by BACS transfer or us arranging for you to set up a direct debit (where applicable).</p>
          </div>

          <div class="">
            <p class="mb-5">For access to Skillikz LMS:</p>
            <ul class="list-black">
              <li>if we agree to accept payment of fees by credit card or debit card, you authorise us to:
                <ul class="regular">
                  <li>charge your credit or debit card or bank account for all fees payable for the Initial Term and
                    each
                    subsequent renewal Term and</li>
                  <li>use a third party to process payments and disclose your payment information to such a third party.
                  </li>
                </ul>
              </li>
              <li>if we agree to accept payment of fees by direct debit, before we make the Skillikz Learning Content
                available to you, you must comply with our prescribed process which we shall make available to you.
                Payments to be made by direct debit shall automatically renew in accordance with these terms of use.
              </li>
            </ul>
            <p>Once your Direct Debit form has been processed and the cleared payment of all fees received by us, we
              shall
              make the Skillikz Learning Content available to you.</p>
            <p>For Pay, As You Go access to Skillikz’s online training platform and our training
              content and Learning Content (“<strong>Skillikz LTD</strong>”) you must purchase licence credits and
              Certificates from us. When we receive an authorised cleared payment for each licence credit, that credit
              and/or Certificate shall entitle one individual learner to use one training course and have access to that
              course for a period of 12 months only. Any unused licence credits expire in a year. You accept that we are
              not liable for unauthorised access to, use of or loss of credits.</p>
          </div>

          <div class="">
            <p class="mb-5">When accessing any Skillikz Learning Content, all fees payment obligations are:</p>
            <ul class="list-black">
              <li>non-cancellable and all amounts paid are non-refundable, except as specifically provided for in the
                Agreement; and</li>
              <li>exclusive of taxes applicable to your use of the Skillikz Learning Content, including VAT, which we
                will
                charge and which you agree to pay.</li>
            </ul>
            <p>Please also see our Refund Policy, which forms part of these Terms of use.</p>
          </div>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkFive>Term</h2>
          <P>The initial period of the Term of your access to the Skillikz Learning Content is set out in your
            Agreement.
            That access will automatically renew at the end of that initial period of the Term (unless otherwise
            specified
            in that Agreement) on the same terms, including payment terms, for an additional period of equal duration to
            the initial period of the Term.</P>
          <P>If you wish to amend the terms of your access to the Skillikz Learning Content in the period of any
            automatic
            renewal after the initial period of the Term, please contact us no later than 5 working days prior to the
            end
            of the initial period of your Term to discuss your amended requirements.</P>
          <P>To prevent automatic renewal of your access to the Skillikz Learning Content you must give us written
            notice
            by email of non-renewal no later than 5 working days prior to the end of the initial period of your Term.
          </P>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkSix>Termination</h2>
          <P>You may cancel your access to the Skillikz Learning Content early in your sole discretion provided that
            should you cancel early we will not provide any refunds of any prepaid fees and you must promptly pay all
            unpaid fees due through the end of the Term of your access as set out in your Agreement.</P>
          <P class="mb-5">We may terminate the Agreement and/ or suspend your or any permitted learner’s access to the
            Skillikz Learning Content, each immediately with written notice if you:</P>
          <ul class="list-black">
            <li>exceed your permitted number of learners;</li>
            <li>are acting, or have acted, in our sole discretion (exercised reasonably) in a way that has or may
              negatively reflect on or affect us, our prospects, or our customers;</li>
            <li>become the subject of a petition in bankruptcy or any other proceeding relating to insolvency, cessation
              of business, liquidation or assignment for the benefit of creditors;</li>
            <li>use the Skillikz Learning Content in breach of any applicable law or the terms of the Agreement;</li>
            <li>fail to pay any unpaid fees within five (5) days of us providing you with written notice of non-payment
              of
              any amount due; or</li>
            <li>use the Skillikz Learning Content in any way which we believe is causing harm to us or others.</li>
          </ul>
          <P>You must stop all use of the Skillikz Learning Content on, suspension, termination or expiry of the
            Agreement
            however that arises.</P>
          <P>If we terminate this Agreement for cause, you will promptly pay all unpaid fees due through the end of the
            Term as set out in the Agreement. Fees are otherwise non-refundable.</P>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkSeven>Our Responsibilities</h2>
          <P>You acknowledge and agree that the Skillikz Learning Content, it’s content and functionality, are provided
            on
            an “as is” and “as available” basis, and, to the fullest extent permitted by law, we make no warranty,
            whether
            express or implied, with respect to the Skillikz Learning Content, its content, functionality or
            availability.
            We do not make any promise that access to the Skillikz Learning Content will be uninterrupted or error-free.
          </P>
          <P>Nothing in these terms of use will operate to exclude or limit either party’s liability: (a) for death or
            personal injury caused by its negligence; (b) for any fraud or fraudulent misrepresentation; or (c) to the
            extent that such liability cannot be excluded or limited under applicable law.</P>
          <P class="mb-5">Subject to the previous sentence, to the maximum extent permitted under applicable law:</P>

          <ul class="list-black">
            <li>we are not liable for the proper functioning of the Skillikz Learning Content and any loss or damage
              that
              may occur to you as a result of your use of, or reliance on, the Skillikz Learning Content.</li>
            <li>we and/or any of our representatives, directors and employees are not liable for any loss or damage that
              you may incur under or in connection with the Agreement or as a result of your use of or reliance on, the
              Skillikz Learning Content, including</li>
            <li>in particular, we will not be liable for:
              <ul class="regular">
                <li>any direct or indirect property damage or monetary loss;</li>
                <li>loss of profit or anticipated savings;</li>
                <li>loss of business, contracts, contacts, goodwill, reputation and any loss that may arise from
                  interruption of the business;</li>
                <li>loss or inaccuracy of data; and</li>
                <li>any indirect or consequential loss or damage.</li>
              </ul>
            </li>
          </ul>

          <P>Notwithstanding the other terms of the Agreement, if we are determined to have any liability to you or any
            third party in respect of the making available of the Skillikz Learning Content or otherwise, we and you
            agree
            that our aggregate liability will be limited to a sum equal to the total amounts of fees paid or payable by
            you for the Skillikz Learning Content in the twelve (12) month period preceding the event giving rise to a
            claim.</P>
        </div>

        <div class="mb-20">
          <h2 class="text-black" #hyperlinkEight>Your Information</h2>
          <p>You agree that information provided to us by you or collected by us about you (“<strong>your
              Information</strong>”) is governed by our Privacy Policy, and we agree to process, use and store your
            Information in accordance with applicable data protection laws.&nbsp; You acknowledge and agree that we may
            use your information to provide you with information about the Skillikz Learning Content or services similar
            to the Skillikz Learning Content. If you contact us via telephone, you acknowledge that we may record
            telephone calls for training and/or monitoring purposes.</p>
          <P>You must provide us with an email and postal address and phone number and let us know if any of these
            change during the term of the Agreement. You must send all written notices to the address mentioned on our
            website contact page or email us at info@skillikz.com.</P>
        </div>


        <div class="">
          <h2 class="text-black" #hyperlinkNine>Additional Terms</h2>
          <P>We may transfer our rights or obligations under the Agreement or arrange for any other person to carry out
            our rights or obligations under the Agreement and will provide you with notice in writing (which includes
            email) if this happens. You may not transfer any of your rights or obligations under the Agreement.</P>
          <P>In accepting the Agreement, you agree to be bound by it. No alterations to the Agreement operate without
            the written authority of a Skillikz LTD director. We reserve the right to change these terms of use from
            time to time. You are responsible for regularly reviewing any amended terms of use posted notified to you.
            Following any such notification, your continued use of the Skillikz Learning Content will constitute your
            agreement to these terms of use as amended.</P>
          <P>Only you and we may enforce the terms of the Agreement, and the Agreement does not give rise to any rights
            under the Contracts (Rights of Third Parties) Act 1999 to enforce the terms of the Agreement.</P>
          <P>If you or we fail to enforce any of our rights under the Agreement, it will not mean that you or we (as
            applicable) have waived our ability to enforce such rights.</P>
          <P>If there is any conflict between these terms of use and the Privacy Policy, these terms of use will take
            precedence. If any court considers any clause of the Agreement is illegal, invalid, or unenforceable, such
            clause will not apply to you or us (as applicable) and will not affect the validity or enforceability of the
            remainder of the terms of the Agreement.</P>
          <P class="mb-0">The Agreement shall be governed by and construed in accordance with the laws of England and subject to the
            exclusive jurisdiction of the English Courts. The language of the Agreement is English, and all notices and
            information given under the Agreement will be in English. If you have a query about anything in connection
            with the Agreement, you may contact us at <a href="mailto:info@skillikz.com">info@skillikz.com</a>.</P>
        </div>

      </div>

    </div>
  </section>
