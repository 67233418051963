import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.css']
})

export class CookiesPolicyComponent extends BaseClass implements OnInit{
    constructor() {
      super()
     }
  
    ngOnInit(): void {
      init(this);
    }
    scroll(el: HTMLElement) {
      el.scrollIntoView();
    }
  
  }
  
  function init(context: CookiesPolicyComponent) {  
    context.title = 'Cookies Policy - SKILLIKZ';
    context.description = 'Contact Skillikz for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
    context.keywords = 'contact us, Skillikz, technology solutions, consulting services, IT product development, modernization, digital transformation';
    context.seoTags();
  }