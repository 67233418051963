import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-e-learning-developer',
  templateUrl: './e-learning-developer.component.html',
  styleUrls: ['./e-learning-developer.component.css']
})
export class ELearningDeveloperComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: ELearningDeveloperComponent) {  
  context.title = 'E-Learning Developer - SKILLIKZ';
  context.description = 'This is a new role for the organisation, Skillikz are now searching for a E-learning developer who is motivated to join their well-capitalised and ambitious company for the next phase of growth.';
  context.keywords = 'E-Learning Development, Instructional Design, Learning Management System (LMS), SCORM (Sharable Content Object Reference Model), Online Course Creation, eLearning Authoring Tools, Moodle, Articulate Storyline, Adobe Captivate, Interactive Learning Modules, Gamification in Learning, Virtual Classrooms, Mobile Learning (mLearning), Multimedia Integration, Assessments and Quizzes, Content Personalization, Learning Analytics, Educational Technology, Courseware Development, User Experience (UX) Design';
  context.seoTags();
}
