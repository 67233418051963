import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b19623',
  templateUrl: './blogs-details-b19623.component.html',
  styleUrls: ['./blogs-details-b19623.component.css']
})
export class BlogsDetailsB19623Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetailsB19623Component) {  
  context.title = 'Leveraging Digital Transformation Framework for Effective Technology Adoption | Skillikz';
  context.description = "Explore how businesses can effectively utilise a digital transformation framework to drive successful technology adoption. Learn about the Digital Maturity Framework, key competencies, and strategic planning for digital transformation. Empower your business with Skillikz's expertise in business digital transformation services and IT product engineering.";
  context.keywords = 'Digital Transformation, Digital Maturity Framework, Technology Adoption, Innovation Culture, Agile Project Management, Digital Literacy, Leadership Alignment, Resource Allocation, Continuous Learning, Data Management, KPIs (Key Performance Indicators), Service Delivery Optimization, Customer Experience, Internal System Digitization, Technology Integration, Pilot Projects, Business Case Development, Scalability and Interoperability, Strategic Roadmap, Competitive Advantage';
  context.seoTags();
}
