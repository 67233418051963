<section class="section-inner-content topMargin">
    <div class="container-main">

        <app-breadcrumb></app-breadcrumb>

        <div class="page-title">
            <h1><span>Case</span> Studies</h1>
        </div>

        <div class="targetCard">
            <div class="row-flex">
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Mortgage Process Automation </h2>
                  <div class="img100">
                    <img src="assets/images/bdt-small-img.webp" alt="Business Digital Transformation" width="512"
                      height="175" onerror="this.src='assets/images/bdt-small-img.png'">
                  </div>
                  <p>For a leading mortgage firm based in the USA, their vision to improve overall customer experience and engagement, is realised by delivering a fully automated multi-tenant platform for mortgage business process. 
    
                  </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-1" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Smart Travel Recommendations</h2>
                  <div class="img100">
                    <img src="assets/images/amex-small-img.webp" alt="AMEX (BDT)" width="512" height="175"
                      onerror="this.src='assets/images/amex-small-img.png'">
                  </div>
                  <p>For one the largest Banking and Financial Services client, we delivered a recommendation engine using Artificial Intelligence (AI) and Machine Learning (ML) techniques, to enable data-driven tailored travel recommendations to its customers with personalized travel options. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-2" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
    
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Cloud Transformation</h2>
                  <div class="img100">
                    <img src="assets/images/cloud-transformation-small-img.webp" alt="Cloud Transformation" width="512"
                      height="175" onerror="this.src='assets/images/cloud-transformation-small-img.png'">
                  </div>
                  <p>With the mission of improved performance, scalability, security, resiliency, with agility for a multi-tenant platform, we help one of our clients in moving their entire technology landscape and infrastructure to cloud - AWS. The key to success was “minimal business disruption”. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-3" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
              <div class="items-col-3">
                <div class="card-white">
                    <h2>Application Management Service</h2>
                    <div class="img100">
                        <img src="assets/images/ams-small-img.webp" alt="AMS" width="512" height="175"
                            onerror="this.src='assets/images/ams-small-img.png'">
                    </div>
                    <p>During the Business Digital Transformation (BDT) for one of the FinTech clients, which included
                        modernization of 20+ legacy applications, Skillikz proposed to set up a 24*7 application and infrastructure
                        support model to support business continuity and growth.</p>
                    <div class="btn-fixed">
                        <button type="button" routerLink="/case-study-4" class="btn-blue"
                            aria-label="Read Case Study">Read Case Study</button>
                    </div>
                </div>
            </div>

              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Cloud Support</h2>
                  <div class="img100">
                    <img src="assets/images/cloud-support-img.webp" alt="Cloud Support" width="512" height="175"
                      onerror="this.src='assets/images/cloud-support-img.png'">
                  </div>
                  <p>As part of their digital transformation journey, one of our clients aimed to transition their operations to the cloud to achieve greater agility, cost savings, and improved disaster recovery capabilities. We collaborated with client’s IT team to define a process-driven Managed Cloud Support services. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-5" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Cyber Security</h2>
                  <div class="img100">
                    <img src="assets/images/cyber-security-img.webp" alt="Cyber Security" width="512" height="175"
                      onerror="this.src='assets/images/cyber-security-img.png'">
                  </div>
                  <p>Client, a UK-based lending institution, recognized the increasing importance of robust
                    cybersecurity
                    measures to protect their sensitive data and maintain regulatory compliance. Facing evolving
                    cyber
                    threats, they sought the expertise of Skillikz Cybersecurity Solutions to fortify their
                    security
                    posture.</p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-6" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
    
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Customer Journey Automation</h2>
                  <div class="img100">
                    <img src="assets/images/bdt-customer-journey-img.webp" alt="BDT Customer Journey" width="512"
                      height="175" onerror="this.src='assets/images/bdt-customer-journey-img.png'">
                  </div>
                  <p>Process Opacity, full of friction Customer Interaction with business users, and Process delays were the key challenges which were hindering the growth. We designed an API-led integrated and easy to use solution to connect disparate internal and external applications and provide a seamless customer experience. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-7" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Staff Augmentation</h2>
                  <div class="img100">
                    <img src="assets/images/staff-augmentation-img.webp" alt="Staff Augmentation" width="512" height="175"
                      onerror="this.src='assets/images/staff-augmentation-img.png'">
                  </div>
                  <p>A prominent UK-based lending customer encountered challenges in maintaining its legacy and standalone applications. The Client sought the expertise of Skillikz to leverage staff augmentation for the deployment of Azure Cloud infrastructure and DevOps practices. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-8" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Technology Consulting</h2>
                  <div class="img100">
                    <img src="assets/images/tech-consulting-underwriting-img.webp" alt="Tech Consulting Underwriting"
                      width="512" height="175" onerror="this.src='assets/images/tech-consulting-underwriting-img.png'">
                  </div>
                  <p>Our client is a lending finance organization with an objective to transition from labor-intensive
                    underwriting
                    methods to a streamlined automated process leveraging cutting-edge technologies. The integration of
                    predictive
                    analysis, open banking data, credit agency insights, and cognitive services has significantly
                    transformed the
                    underwriting landscape.</p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-9" class="btn-blue" aria-label="Read Case Study">Read Case
                      Study</button>
                  </div>
                </div>
              </div>
    
              <div class="items-col-3">
                <div class="card-white ">
                  <h2>Customer Experience</h2>
                  <div class="img100">
                    <img src="assets/images/customer-experience-img.webp" alt="Customer Experience" width="512" height="175"
                      onerror="this.src='assets/images/customer-experience-img.png'">
                  </div>
                  <p>With our deep expertise and experience in the field of customer experience (CX), we helped a mid-sized e-commerce company that specializes in selling fashion apparel and accessories to improve their online presence and capture a larger customer base. We employed a holistic approach encompassing research, measurement, ideation, storyboarding, mental model analysis, and prototyping. </p>
                  <div class="btn-fixed">
                    <button type="button" routerLink="/case-study-10" class="btn-blue" aria-label="Read Case Study">Read
                      Case Study</button>
                  </div>
                </div>
              </div>
    
    
            </div>
          </div>

    </div>
</section>  