<div class="mb-20">
    <h2 class="large">Other Course Catalogues</h2>

    <div class="other-course-catalogue scroll-elem fade-in-bottom">

      <div class="itemsFlex" *ngIf="url!=='/health-and-safety'">
        <a routerLink="/health-and-safety">
          <div class="itemsCard">
            <img src="assets/images/icons/health-safety-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Health & Safety</h3>
            <p>35 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course" routerLink="/health-and-safety">View Course</button>
            </div>
          </div>
        </a>        
      </div>

      <div class="itemsFlex" *ngIf="url!=='/health-care'">
        <a routerLink="/health-care">
          <div class="itemsCard">
            <img src="assets/images/icons/health-care-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Health Care</h3>
            <p>38 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course" routerLink="/health-care">View Course</button>
            </div>
          </div>
        </a>        
      </div>

      <div class="itemsFlex" *ngIf="url!=='/information-security'">
        <a routerLink="/information-security">
          <div class="itemsCard">
            <img src="assets/images/icons/information-security-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Information Security</h3>
            <p>18 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course">View Course</button>
            </div>
          </div>
        </a>        
      </div>
      <div class="itemsFlex" *ngIf="url!=='/financial-compliance'">
        <a routerLink="/financial-compliance">
          <div class="itemsCard">
            <img src="assets/images/icons/financial-compliance-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Financial Compliance</h3>
            <p>30 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course" routerLink="/financial-compliance">View Course</button>
            </div>
          </div>
        </a>        
      </div>

      <div class="itemsFlex" *ngIf="url!=='/human-resources'">
        <a routerLink="/human-resources">
          <div class="itemsCard">
            <img src="assets/images/icons/human-resources-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Human Resources</h3>
            <p>20 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course" routerLink="/human-resources">View Course</button>
            </div>
          </div>
        </a>        
      </div>

      <div class="itemsFlex" *ngIf="url!=='/leadership-and-management'">
        <a routerLink="/leadership-and-management">
          <div class="itemsCard">
            <img src="assets/images/icons/leadership-management-icon.svg" alt="Unique Proposition" width="64" height="64">
            <h3>Leadership & Management</h3>
            <p>15 Courses</p>
            <div class="btnFixed">
              <button type="button" aria-label="View Course" routerLink="/leadership-and-management">View Course</button>
            </div>
          </div>
        </a>        
      </div>
    </div>

  </div>