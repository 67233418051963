<section class="sectionInnerBanner topMargin">
    <div class="container-main">

        <app-breadcrumb></app-breadcrumb>

      <div class="topInnerBanner">
        <div class="itemTopContent">
          <div class="">
            <h1><span>BDT</span> Customer Journey</h1>
            <div class="onlyForMbl">
              <img src="assets/images/bdt-customer-journey.webp" alt="BDT Customer Journey" width="336" height="336"
                onerror="this.src='assets/images/bdt-customer-journey.png'">
            </div>
            <p class="md bold">In the rapidly evolving financial services sector, customer experience has become a key
              differentiator for success. To meet the demands of tech-savvy customers and streamline lending processes, financial
              institutions are leveraging customer portals to provide a comprehensive 360-degree view of lending, services, and
              automated underwriting decisions. </p>
            <p class="mb-0">Skillikz implementation of a customer portal in a lending finance institution has revolutionized the
              customer journey, enabling instant decisions, informed product choices, and seamless contract completion. The Client
              aimed to provide customers with a user-friendly portal that would offer real-time insights into their lending
              options, facilitate instant underwriting decisions, and allow customers to track their application progress until
              contract completion.</p>
          </div>
        </div>
        <div class="itemsImage">
          <div class="">
            <img src="assets/images/bdt-customer-journey.webp" alt="BDT Customer Journey" 
            width="336" height="336" onerror="this.src='assets/images/bdt-customer-journey.png'">
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="section-client-details">
    <div class="container-main">
      <ul>
        <li><span>Client: </span>Car Dealership & Lending Firm</li>
        <li><span>Country: </span>UK</li>
        <li><span>Industry: </span>Fin Tech</li>
        <li><span>Engagement Type: </span>BDT</li>
        <li class="lineBrak"><span>Technologies: </span>.Net Core, Angular, Entity Framework, Mongo ,SQL Server, WebAPI, Docker, Kubernetes, Redis, GraphQL</li>
      </ul>
    </div>
  </section>




  <section class="sectionCommonLightBlue border-b">
    <div class="container-main">
      <div class="mb-40">
        <h2 class="large">The Challenge</h2>
        <div class="row-flex">
          <div class="items-col-8 mb-15 itemCenter">
            <h3 class="regular-text noMargin">Manual underwriting in high-risk lending scenarios has historically been a time-consuming process fraught with challenges. Some of them are listed below:</h3>
          </div>
          <div class="items-col-1">
            <hr class="border">
            <br>
            <ul class="regular bullet listCol-2">
              <li><strong>Process Opacity:</strong> Customers lacked visibility into their application status and had limited access
                to relevant information about available lending products.</li>              
              <li><strong>Suppressed business growth:</strong> Low performing applications, high investment in on-premises
                infrastructure not yielding expected result.</li>
                <li><strong>Customer Interaction with business:</strong> Manual communication methods made it cumbersome for customers
                  to track the progress of their application and contracts, often leading to confusion.</li>
              <li><strong>Process delays:</strong> Reviewing and verifying extensive documentation, including customer financial
                records, bank statements, and credit history, consumed hours or even days.</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="">
        <h2 class="large">Drive to accomplishment</h2>
        <div class="row-flex">
          <div class="items-col-8 mb-15">
            <h3 class="regular-text mb-15">To address the challenges, the Client engaged Skillikz and embarked on developing an integrated customer portal that would provide customers with a seamless lending experience.</h3>
            <p class="mb-0">Leveraging new world technologies, the following solutions were implemented:</p>             

          </div>
          <div class="items-col-1 mb-15">
            <hr class="border mb-15">
            <ul class="regular bullet listCol-2">
              <li><strong>Instant Underwriting Decisions:</strong> Customers could input their financial information and lending
                requirements into the portal. The portal utilized automated underwriting algorithms to provide instant lending
                decisions, eliminating delays.</li>
              <li><strong>Product Comparison:</strong> The portal displayed a range of lending products with detailed information
                about interest rates, repayment terms, and eligibility criteria. This empowered customers to make informed decisions
                aligned with their financial goals.</li>
              <li><strong>Application Tracking:</strong> Customers could track their application's progress in real-time, from
                submission to underwriting to final approval. This transparency reduced customer anxiety and improved engagement.
              </li>
              <li><strong>Document Management:</strong> The portal allowed customers to securely upload necessary documents, manage
                their documents, streamlining the application process and reducing the need for physical paperwork.</li>
              <li><strong>E-Signature and Contract Completion:</strong> Once the lending application was approved, customers could
                review and sign contracts electronically through the portal, expediting the process and eliminating the need for
                in-person visits.</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="commonSection bgWhite marginNone border-b">
    <div class="container-main">
      <h2 class="large">The Outcome</h2>
      <div class="row-flex">
        <div class="items-col-8 mb-15 itemCenter">
          <h3 class="regular-text mb-0 scroll-elem slide-left">The implementation of customer portal yielded remarkable results:</h3>          
        </div>
        <div class="items-col-1">
          <hr class="border mb-15">
          <ul class="regular bullet listCol-2">            
            <li class="scroll-elem slide-left"><strong>Faster Decisions:</strong> Automated underwriting decisions reduced
              decision times from days to minutes, enhancing customer satisfaction and improving conversion rates.</li>
            <li class="scroll-elem slide-right"><strong>Improved Transparency:</strong> Customers had complete visibility into the
              application and contract process, leading to reduced anxiety and improved trust in the lending institution.</li>
              <li class="scroll-elem slide-left"><strong>Improved Customer Experience:</strong> The customer portal provided a
                user-friendly and efficient platform, allowing customers to access lending options, receive instant decisions, and
                track their application progress seamlessly.</li>
            <li class="scroll-elem slide-right"><strong>Efficiency Gains:</strong> The portal streamlined document submission,
              contract signing, and communication, resulting in increased operational efficiency and reduced administrative
              overhead.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>



  <section class="sectionForm">
    <div class="container-main">
      <div class="row-flex">
        <div class="custom-col-55">
          <div class="formContent scroll-elem slide-left">
            <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's redefine
                your online presence together.</span></h2>
            <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone, email or
              text message.</p>
          </div>
        </div>
        <div class="custom-col-45">
          <!-- Contact Us form component -->
          <app-form></app-form>
        </div>
      </div>
    </div>
  </section>



<!-- ---Read More component here--- -->
<app-read-more></app-read-more>


