<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Human</span> Resources</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Gain insights into current employment law, rights, and responsibilities with our CPD accredited courses. Explore Safeguarding, Conflict Resolution, and more through flexible learning, intuitive interface, interactive scenarios, and immediate practical application.</p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/.webp" alt="Human Resources image" width="485" height="432"
              onerror="this.src='assets/images/human-resources-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>


    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Conflict Resolution</h3>
            <p>Identify and resolve workplace conflicts effectively. Seek guidance when needed for optimal conflict
              management.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:262,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Effective Remote Working</h3>
            <p>Thrive in a remote work environment by building online relationships, communicating flexibly, and
              organizing effectively.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:265,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Adult at Risk - Level 3</h3>
            <p>This course is intended for team leaders and managers and those with designated safeguarding
              responsibilities. It will help promote awareness of safeguarding duties within an organisation and...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:235,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Adults at Risk - Level 1</h3>
            <p>This course aims to promote safeguarding awareness to frontline staff through interactive activities and
              bite-sized content. You will get an opportunity to grasp information through short cases...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:241,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Adults at Risk Level 2</h3>
            <p>This course promotes awareness of different types of abuse and offers a comprehensive view of
              safeguarding.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:242,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Equality and Diversity</h3>
            <p>Explore concepts of equality, diversity, and inclusion. Learn about the Equality Act 2010, workplace
              discrimination types, unacceptable conduct, and procedures for reporting...</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:244,group:31" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- --------Hidden courses wrapper beginning here----------- -->
      <div class="">

        <div class="hidden-wrapper">
          <div class="row-flex">
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Stress Awareness</h3>
                <p>This topic aims to make employees more aware of the causes of stress and the positive actions that
                  you can take to manage stress.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:231,group:31" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>HR Compliance & Wellbeing Essentials</h3>
                <p>Covering equality, diversity, conflict resolution, mental health, modern slavery, sexual harassment,
                  and whistleblower protection. Elevate workplace standards and customer benefit.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:266,group:31" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="show-all-wp">
          <div class="">
            <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
              Courses</button>
          </div>
        </div>


      </div>
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>

  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p>HR Compliance Courses</p>
                <ol>
                  <li>Understand what abuse is, the groups of adults that might be at risk and who can be an abuser</li>
                  <li>Understand the different types of abuse that adults at risk might experience</li>
                  <li>Understand what is safeguarding adults and how to deal with disclosures</li>
                </ol>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=B_mELtF4oqo&list=PLAy395buT7V4qjM32v0Npi3yMfcK9YS1D" target="_blank">
              <div class="">
                <img src="assets/images/social-media-youtube-img5.webp"  alt="social Media Youtube img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-youtube-img5.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=B_mELtF4oqo&list=PLAy395buT7V4qjM32v0Npi3yMfcK9YS1D" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Is Compliance a Culture Issue? Building a Culture of Compliance in the Tech Industry</p>
                <p>Compliance goes beyond legalities in the tech industry - it's about fostering a culture of differentiation, talent attraction, and success.</p>
                <p>Steps to build a compliance culture: define expected behaviours, measure compliance, assess outcomes, and prevent escalation.</p>
                <p>Managers play a pivotal role in shaping and upholding the culture of compliance. </p>
                <p>Ethical standards should be embedded in purpose, brand, and culture to navigate new business realities.</p>
                <p>Empower employees to raise ethical concerns and create an environment that encourages open dialogue.</p>
                <p>Prioritize compliance culture for long-term success in the ever-evolving tech industry.</p>
                <p>Read our article <a href="https://buff.ly/3Nexuse" target="_blank">https://buff.ly/3Nexuse</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_complianceculture-techindustry-ethics-activity-7087008518704095234-VKsK?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img3.webp" alt="social Media img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-img3.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_complianceculture-techindustry-ethics-activity-7087008518704095234-VKsK?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Unlocking the Power of Digital Transformation in SMEs</p>
                <p>In today's tech-driven landscape, SMEs have a unique opportunity to embrace digital transformation and revolutionise their businesses.</p>
                <p>Key enablers drive this journey:</p>
                
                <p>Internal Enablers:</p>
                <ol>
                  <li>Organisational Strategy: Clear goals and effective communication are crucial</li>
                  <li>Sustainable Tech Capabilities: Leveraging IT resources empowers digital initiatives</li>
                  <li>Skilled People: Expertise and leadership catalyse success</li>
                  <li>Agile Processes: Flexibility paves the way for innovation</li>
                </ol>
                
                <p>External Enablers:</p>
                <ol>
                  <li>Accessibility to Partnerships: Collaborating for shared resources and growth</li>
                  <li>Digital Technology: Embracing cutting-edge tech drives transformation</li>
                  <li>Digital Competition: Leveraging customer feedback and agility</li>
                </ol>
                <p>Join the digital revolution and thrive in the dynamic market!</p>
                <p>Read article <a href="https://buff.ly/3K8Aplv" target="_blank">https://buff.ly/3K8Aplv</a>
                </p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7089492019617619968-oIHG?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img4.webp" alt="social Media img" width="412" height="232"
                      onerror="this.src='assets/images/social-media-img4.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7089492019617619968-oIHG?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/human-resources'"></app-other-course-catalogues>

  </div>
</section>