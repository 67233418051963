import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-dot-net-developer',
  templateUrl: './dot-net-developer.component.html',
  styleUrls: ['./dot-net-developer.component.css']
})
export class DotNetDeveloperComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetDeveloperComponent) {  
  context.title = '.NET Developer - SKILLIKZ';
  context.description = 'A technical professional with hands-on experience writing quality code and understanding business in Fintech and retails industry.';
  context.keywords = '.NET Framework, ASP.NET Core, C# Programming, Entity Framework, MVC Architecture, Web API Development, LINQ (Language Integrated Query), SQL Server, Azure Cloud Services, Dependency Injection, Razor Pages, Windows Presentation Foundation (WPF), Blazor Development, Object-Oriented Programming (OOP), Microservices Architecture, RESTful Services, Visual Studio, Agile Development Practices, Unit Testing (xUnit, NUnit), Integration with Third-Party APIs';
  context.seoTags();
}