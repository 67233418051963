import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.css']
})
export class OurValuesComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: OurValuesComponent) {  
  context.title = "Our Values - Discover Skillikz Ltd's Core Values and Commitment to Innovative Solutions for Business Digital Transformation | Expert IT Product Engineering Services";
  context.description = "Learn about Skillikz’ s core values: client satisfaction, innovation, quality, integrity, and teamwork. We believe in delivering technology solutions that make a positive impact on our clients' businesses";
  context.keywords = 'Company Values, Core Values, Organizational Values, Business Ethics, Commitment to Excellence, Integrity in Business, Customer-Centric Values, Innovation and Creativity, Respect and Responsibility, Collaboration and Teamwork, Accountability in Business, Sustainability and Responsibility, Diversity and Inclusion, Transparency in Business, Quality and Reliability, Ethical Business Practices, Social Responsibility, Empowering Employees, Trust and Integrity, Leadership and Vision';
  context.seoTags();
}
