<section class="sectionInnerBanner topMargin">
  <div class="container-main">
    
    <app-breadcrumb></app-breadcrumb>

    <div class="topInnerBanner">
      <div class="itemTopContent">
        <div class="">
          <h1><span>ESG</span></h1>
          <div class="onlyForMbl">
            <img src="assets/images/esg-img.webp" alt="Esg img" width="336" height="336"
              onerror="this.src='assets/images/esg-img.png'">
          </div>
          <p class="xl">“Embracing ESG Excellence”</p>
          <p class="mb-0">At Skillikz, we believe that sustainable success is built on Environmental stewardship, Social
            responsibility, and sound Governance. Our commitment to ESG principles is woven into the fabric of our company,
            driving us to reduce our environmental impact, foster social equity, and uphold the highest standards of corporate
            governance. We are dedicated to creating value for all stakeholders while making a positive impact on the world.</p>
        </div>
      </div>
      <div class="itemsImage">
        <div class="">
          <img src="assets/images/esg-img.webp" alt="Esg img" width="336" height="336"
              onerror="this.src='assets/images/esg-img.png'">
        </div>
      </div>
    </div>

  </div>
</section>




<section class="sectionCommonLightBlue marginNone border-b">
  <div class="container-main">

    <h2 class="large">Environmental Policy</h2>
    <div class="row-flex">
      <div class="items-col-2">
        <p>We are committed to reducing our environmental impact and promoting sustainable practices throughout our
          operations. To achieve this, we will:</p>
      </div>
    </div> 

    <div class="row-flex">
      <div class="items-col-2">
        <div class="esg-inner-flex">
          <div class="itemLeft">
            <div class="mb-15">
              <h3 class="large">Minimize Energy Consumption and Reduce Carbon Footprint</h3>
              <p>We use energy-efficient technologies and renewable energy sources whenever possible to minimize our energy consumption and reduce our carbon footprint.</p>
            </div>
            <div class="">
              <h3 class="large">Sustainable Transportation</h3>
              <p>We encourage sustainable transportation among our employees, customers, and suppliers by promoting public transportation, carpooling, and cycling.</p>
            </div>
          </div>
          <div class="itemImg esgBg1"></div>
        </div>        
      </div>
      <div class="items-col-2">
        <div class="esg-inner-flex">
          <div class="itemImg esgBg2"></div>
          <div class="itemRight">
            <div class="mb-15">
              <h3 class="large">Responsible Sourcing</h3>
              <p>We source our materials and supplies from responsible and sustainable sources and collaborate with our suppliers to reduce their environmental impact.</p>
            </div>
            <div class="">
              <h3 class="large">Waste Reduction</h3>
              <p>We implement a comprehensive waste management plan that includes recycling and responsible disposal of hazardous waste to minimize waste generation.</p>
            </div>
          </div>          
        </div>
      </div>
    </div>

  </div>
</section>




<section class="gradientBg border-b">

  <section class="commonSection marginNone bottomBg">
    <div class="container-main">

      <div class="mb-40">
        <h2 class="large">Social Policy</h2>
        <div class="row-flex">
          <div class="items-col-2 scroll-elem slide-left">
            <p>We believe in treating our employees, customers, and stakeholders with respect and fairness. To achieve this,
              we will:</p>
          </div>
        </div>
      
        <div class="row-flex">
          <div class="items-col-2">
            <div class="esg-inner-flex scroll-elem slide-left">
              <div class="itemLeft">
                <div class="mb-15">
                  <h3 class="large">Safe and Healthy Work Environment</h3>
                  <p>We provide a safe and healthy work environment for our employees and promote work-life balance,
                    diversity, and inclusion within our workforce.</p>
                </div>
                <div class="">
                  <h3 class="large">Transparent and Ethical Engagement</h3>
                  <p>We engage with our customers and stakeholders transparently and ethically, considering their feedback and
                    concerns.</p>
                </div>
              </div>
              <div class="itemImg esgBg3"></div>
            </div>
          </div>
          <div class="items-col-2">
            <div class="esg-inner-flex scroll-elem slide-right">
              <div class="itemImg esgBg4"></div>
              <div class="itemRight">
                <div class="mb-15">
                  <h3 class="large">Respect for Human Rights</h3>
                  <p>We uphold human rights, avoid discrimination and harassment, and ensure that our suppliers do the same.
                  </p>
                </div>
                <div class="">
                  <h3 class="large">Community Support</h3>
                  <p>We give back to our communities by supporting local initiatives and charitable causes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-60">
        <h2 class="large">Governance Policy</h2>
        <div class="row-flex">
          <div class="items-col-2 scroll-elem slide-left">
            <p>We are committed to maintaining high standards of corporate governance and ethical behavior. To achieve this,
              we will:</p>
          </div>
        </div>
      
        <div class="row-flex">
          <div class="items-col-2">
            <div class="esg-inner-flex scroll-elem slide-left">
              <div class="itemLeft">
                <div class="mb-15">
                  <h3 class="large">Diverse and Skilled Leadership</h3>
                  <p>We provide a safe and healthy work environment for our employees and promote work-life balance,
                    diversity, and inclusion within our workforce.</p>
                </div>
                <div class="">
                  <h3 class="large">Compliance and Ethics</h3>
                  <p>We comply with all applicable laws, regulations, and ethical standards, avoiding conflicts of interest
                    and corruption.</p>
                </div>
              </div>
              <div class="itemImg esgBg5"></div>
            </div>
          </div>
          <div class="items-col-2">
            <div class="esg-inner-flex scroll-elem slide-right">
              <div class="itemImg esgBg6"></div>
              <div class="itemRight">
                <div class="mb-15">
                  <h3 class="large">Strong Internal Controls</h3>
                  <p>We maintain a robust system of internal controls and risk management, ensuring accurate and transparent
                    financial reporting.</p>
                </div>
                <div class="">
                  <h3 class="large">ESG Transparency</h3>
                  <p>We communicate our ESG policies and performance to our stakeholders and strive for continuous improvement
                    in our ESG practices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="mb-20">
        <h2 class="large">Read More About Us</h2>
        <div class="moreAboutLinks scroll-elem slide-left">
          <ul>
            <li><a routerLink="/who-we-are">Who We Are</a></li>
            <li><a routerLink="/our-values">Our Values</a></li>
          </ul>
        </div>
      </div>

    </div>
  </section>


</section>
