<section class="sectionCommonWhite border-b bottomBg">
    <div class="container-main">
      <h2 class="large">Explore Technology</h2>
      <div class="row-flex customRow">
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-left">
            <h3>Backend</h3>
            <p>In the world of software development, a strong backend is the backbone of every successful application. At Skillikz, we specialize in creating powerful, efficient, and scalable backend solutions that drive your digital...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/backend" class="btn btn-white"
                aria-label="Explore Backend Services">Explore Backend Services</button>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem fade-in-bottom">
            <h3>Web Development</h3>
            <p>In today's fast-paced digital landscape, your online presence plays a pivotal role in shaping your success. At Skillikz, we're not just developers; we're your partners in crafting extraordinary web solutions that...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/web-development" class="btn btn-white"
                aria-label="Explore Web Development Services">Explore Web Development Services</button>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-right">
            <h3>Mobility</h3>
            <p>In a world that's constantly on the move, your business needs to be right where your customers are – in the palms of their hands. At Skillikz, we don't just develop mobile apps; we create solutions that reshape...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/mobility" class="btn btn-white"
                aria-label="Explore Mobility Services">Explore Mobility Services</button>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-left">
            <h3>Cloud</h3>
            <p>In today's rapidly evolving digital landscape, harnessing the potential of cloud computing is essential for staying competitive. At Skillikz, we offer a comprehensive suite of cloud services that empower businesses to scale...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/cloud" class="btn btn-white"
                aria-label="Explore Cloud">Explore Cloud</button>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem fade-in-bottom">
            <h3>DevOps</h3>
            <p>DevOps is more than just a buzzword, it's a transformative approach that accelerates time-to-market, fosters collaboration, and enhances the overall efficiency of software development and IT operations. At Skillikz...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/devOps" class="btn btn-white"
                aria-label="Explore DevOps">Explore DevOps</button>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="articles-card explore-card scroll-elem slide-right">
            <h3>SecOps</h3>
            <p>In today's digital landscape, the integration of security and operations is not just a necessity but a strategic advantage. SecOps, the synergy between security and operations, enhances threat detection, ensures...</p>
            <div class="btn-fixed">
              <button type="button" routerLink="/secOps" class="btn btn-white"
                aria-label="Explore SecOps">Explore SecOps</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>