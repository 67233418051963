import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-dev-ops',
  templateUrl: './dev-ops.component.html',
  styleUrls: ['./dev-ops.component.css']
})
export class DevOpsComponent extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: DevOpsComponent) {  
  context.title = 'DevOps Services and Consultancy | Transforming Operations | Skillikz';
  context.description = 'Embrace DevOps with Skillikz for accelerated software development, enhanced collaboration, and operational efficiency. Explore our DevOps services, strategy, and solutions.';
  context.keywords = 'DevOps Services, DevOps Automation, Continuous Integration (CI), Continuous Deployment (CD), DevOps Consulting, DevOps Solutions, Infrastructure as Code (IaC), DevOps Pipeline, DevOps Toolchain, Cloud DevOps, DevOps for Enterprises, Agile DevOps, DevOps Best Practices, DevOps Monitoring, DevOps Security (DevSecOps), Containerization with DevOps, DevOps Implementation, Kubernetes and DevOps, DevOps Culture, Automated Testing in DevOps';
  context.seoTags();
}