import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-adobe-experience-manager',
  templateUrl: './adobe-experience-manager.component.html',
  styleUrls: ['./adobe-experience-manager.component.css']
})
export class AdobeExperienceManagerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: AdobeExperienceManagerComponent) {  
  context.title = "Adobe Experience Manager Development and Consulting Services | Skillikz Ltd ";
  context.description = 'Skillikz Ltd provides expert Adobe Experience Manager development and consulting services to help businesses build and optimize their digital experiences. Contact us today to learn more.';
  context.keywords = 'ServiceNow case study, IT operations case study, ServiceNow implementation case study, ServiceNow customization case study, Skillikz Technologies case study, case study examples, IT success stories';
  context.seoTags();
}