import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study8',
  templateUrl: './case-study8.component.html',
  styleUrls: ['./case-study8.component.css']
})
export class CaseStudy8Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy8Component) {  
  context.title = 'Azure Cloud Migration | Staff Augmentation Services | Skillikz';
  context.description = "Discover how Skillikz's staff augmentation services helped a UK-based lending institution migrate to Azure Cloud, ensuring remote work capabilities and rapid software development. Explore our Azure Cloud migration strategy, DevOps practices, and the remarkable results achieved during the pandemic.";
  context.keywords = 'IT Staff Augmentation, Staff Augmentation Services, On-Demand Staffing Solutions, Remote Staff Augmentation, Temporary Staffing Solutions, Staff Augmentation Consulting, Technical Staffing Services, Dedicated Development Teams, Contract-to-Hire Staffing, IT Workforce Solutions, Global Staff Augmentation, Custom Staff Augmentation, Talent Augmentation Services, IT Resource Augmentation, Flexible Staffing Solutions, Offshore Staff Augmentation, Project-Based Staffing, Agile Staff Augmentation, Enterprise Staff Augmentation, Digital Transformation Staffing';
  context.seoTags();
}