import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details4',
  templateUrl: './blogs-details4.component.html',
  styleUrls: ['./blogs-details4.component.css']
})
export class BlogsDetails4Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails4Component) {  
  context.title = 'New Trends in Cloud Computing: Hybrid Cloud, Edge Computing, Serverless Computing, Multi-Cloud, and AI/ML';
  context.description = 'Stay ahead of the curve with the latest trends in cloud computing, including hybrid cloud, edge computing, serverless computing, multi-cloud, and AI/ML. Learn how businesses can improve performance, reduce costs, and remain competitive';
  context.keywords = 'Hybrid Cloud, Public Cloud, Private Cloud, Cloud Flexibility, Data Security, Compliance, Edge Computing, Real-Time Data Processing, Latency Reduction, Cost Efficiency, Serverless Computing, Cloud Infrastructure, Scalability, Multi-Cloud, Vendor Lock-In, Redundancy, Artificial Intelligence (AI), Machine Learning (ML), Intelligent Applications, Cloud Performance';
  context.seoTags();
}
