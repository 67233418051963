import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study2',
  templateUrl: './case-study2.component.html',
  styleUrls: ['./case-study2.component.css']
})
export class CaseStudy2Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CaseStudy2Component) {  
  context.title = 'Finance BDT (Business Digital Transformation) Case Study | Skillikz';
  context.description = 'Explore our successful finance industry case study, where Skillikz developed a custom recommendation engine using AI and ML techniques. Discover how we enhanced customer experiences, increased conversion rates, and optimized costs in the banking and financial services sector.';
  context.keywords = 'Financial Digital Transformation, Fintech Solutions, Digital Banking Transformation, Cloud-Based Financial Services, AI in Finance, Robotic Process Automation (RPA) in Finance, Blockchain in Financial Services, Financial Data Analytics, Digital Payments Solutions, Automated Financial Processes, Digital Risk Management, Financial Technology (Fintech), Digital Accounting Solutions, Finance Transformation Consulting, Regulatory Technology (RegTech), AI-Powered Financial Services, Cloud Financial Management, Digital Asset Management, Digital Finance Strategy, Next-Generation Financial Systems';
  context.seoTags();
}