import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details33',
  templateUrl: './blogs-details33.component.html',
  styleUrls: ['./blogs-details33.component.css']
})
export class BlogsDetails33Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails33Component) {  
  context.title = 'Latest Trends in Financial Software Development: What You Need to Know';
  context.description = 'Stay up to date with the latest trends in financial software development. Learn how cloud computing, mobile applications, AI and ML, blockchain, open banking, RPA, and digital wallets are changing the financial industry';
  context.keywords = 'Cloud Computing, Financial Software, Mobile Applications, AI (Artificial Intelligence), Machine Learning (ML), Blockchain, Open Banking, Digital Wallets, Robotic Process Automation (RPA), Data Security, Scalable Solutions, Financial Transactions, Transaction Processing, Fraud Detection, Cost-Effective Solutions, Mobile Finance Management, Secure Digital Ledger, Automation in Finance, Consumer Financial Data, Financial APIs';
  context.seoTags();
}
