import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-mobility',
  templateUrl: './mobility.component.html',
  styleUrls: ['./mobility.component.css']
})
export class MobilityComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: MobilityComponent) {  
  context.title = 'Mobility Solutions | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers expert mobility solutions to help businesses create powerful and innovative mobile applications. Contact us today to learn more.';
  context.keywords = 'Mobile Application Development, Enterprise Mobility Solutions, Mobility Solutions for Businesses, Mobile Device Management (MDM), Mobility as a Service (MaaS), Mobile App Consulting, Cross-Platform Mobile Development, Mobile Solutions for Digital Transformation, Cloud Mobility Services, Mobile Enterprise Applications, Mobility Strategy Consulting, Internet of Things (IoT) and Mobility, Mobile Security Solutions, Mobile Workforce Management, Mobile App Integration, BYOD (Bring Your Own Device) Solutions, Enterprise Mobility Management (EMM), Location-Based Mobile Services, Mobile User Experience (UX) Design, Mobility Infrastructure Solutions';
  context.seoTags();
}
