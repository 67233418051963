import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-all-job-openings',
  templateUrl: './all-job-openings.component.html',
  styleUrls: ['./all-job-openings.component.css']
})
export class AllJobOpeningsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: AllJobOpeningsComponent) {  
  context.title = "Join Skillikz Ltd's Team | Explore All Career Opportunities Here";
  context.description = "Browse Skillikz's current job openings and apply for exciting opportunities to join our team. We are looking for talented professionals with a passion for innovation and technology.";
  context.keywords = "Latest job openings at Skillikz, Join the Skillikz team, Exciting career opportunities at Skillikz, Apply for open positions at Skillikz, Work with Skillikz, Find your next job at Skillikz, Skillikz job vacancies, Join our dynamic team at Skillikz, We're hiring at Skillikz, Grow your career with Skillikz, Open positions at Skillikz - apply now, Careers at Skillikz - join us today";
  context.seoTags();
}

