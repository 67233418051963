import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-business-analyst',
  templateUrl: './business-analyst.component.html',
  styleUrls: ['./business-analyst.component.css']
})
export class BusinessAnalystComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BusinessAnalystComponent) {  
  context.title = 'Business Analyst - SKILLIKZ';
  context.description = 'Looking for a professional who can understand the business very well in FinTech, Retail industry. Someone who can identify and analyze areas for improvement and define the requirements of the business.';
  context.keywords = 'Business Requirements Gathering, Process Mapping, Stakeholder Management, Gap Analysis, Requirements Documentation, SWOT Analysis, Business Process Improvement, Use Case Development, Agile Methodology, Functional Specifications, Data Analysis, User Stories, Change Management, Business Intelligence, Problem Solving, JAD (Joint Application Design) Sessions, Business Case Development, System Integration, Risk Analysis, Performance Metrics';
  context.seoTags();
}
