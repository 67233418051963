import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Constants {

    //error codes
    public noInternetConnectionError = 101;
    public errorUnauthorized = 401;
    public errorNotFound = 404;
    public errorDuplicate = 302;
    public error409 = 409;

    public errorNoInternetConnectionAvailable = "Kindly check your internet connection";

    // validation error messages
    fullNameReq = "Full name is required."
    firstNameReq = "First name is required."
    emailReq = "Email is required."
    emailInvalid = "Email is invalid."
    phoneNumberReq = "Phone number is required."
    messageReq = "Enter a message.";
    phoneNumberPattern = "Must contain only numbers.";
    firstNamePattern = "Must only contain characters."
}