import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-business-digital-transformation',
  templateUrl: './business-digital-transformation.component.html',
  styleUrls: ['./business-digital-transformation.component.css']
})
export class BusinessDigitalTransformationComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BusinessDigitalTransformationComponent) {  
  context.title = 'Business Digital Transformation Services | Skillikz Ltd';
  context.description = 'Skillikz Ltd offers business digital transformation services to help firms adapt to the digital age. Our services include strategy development, process optimization, and technology implementation. Contact us today to learn more.';
  context.keywords = 'Digital Transformation Services, Business Process Automation, Digital Innovation, Cloud Migration Strategy, Digital Transformation Consulting, Agile Transformation, IT Modernization, Data-Driven Transformation, Enterprise Digital Solutions, Technology Transformation, Digital Strategy Development, Customer Experience Transformation, Intelligent Automation, AI-Powered Transformation, Legacy System Modernization, Digital Workforce Enablement, Hybrid Cloud Transformation, Digital Transformation Roadmap, Cybersecurity Transformation, Digital Operations Optimization';
  context.seoTags();
}
