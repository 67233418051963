import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-health-care',
  templateUrl: './health-care.component.html',
  styleUrls: ['./health-care.component.css']
})
export class HealthCareComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:14';
    window.open(url, '_blank');
  }
}


function init(context: HealthCareComponent) {
  context.title = 'Health Care | Skillikz Ltd ';
  context.description = 'Access more than 35 engaging courses, including Care Certificate standards. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited. ';
  context.keywords = 'Healthcare Services, Medical Solutions, Telemedicine Services, Health IT Solutions, Patient Care Management, Healthcare Technology, Healthcare Consulting, Healthcare Digital Transformation, EHR (Electronic Health Records) Solutions, Healthcare Workforce Solutions, Healthcare Analytics, Medical Software Development, Healthcare Management Systems, Patient Engagement Solutions, Healthcare Data Security, Health Insurance Solutions, Remote Patient Monitoring, Healthcare Compliance, Health Tech Solutions, Population Health Management';
  context.seoTags();
}
