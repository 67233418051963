import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as data from '../../../../assets/json/crumbs.json';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  urlArr: Array<string>;
  urlList = [];
  breadCrumbs: any = (data as any).default;
  constructor(public router: Router) {
  }

  ngOnInit(): void {
    this.getUrlList();
  }

  getUrlList() {
    this.urlArr = this.breadCrumbs.find(e => e.page === this.router.url.split('?')[0].split('/')[1])?.url.split('/');
    if (this.urlArr) {
      this.urlArr.forEach(element => {
        if (element) {
          let a = this.breadCrumbs.find(e => e.page === element)
          this.urlList.push(a)
        }
      });
    }
  }
}
