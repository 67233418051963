import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b12723',
  templateUrl: './blogs-details-b12723.component.html',
  styleUrls: ['./blogs-details-b12723.component.css']
})
export class BlogsDetailsB12723Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetailsB12723Component) {  
  context.title = 'Empowering Business Digital Transformation: Key Enablers for SMEs | Skillikz Blogs';
  context.description = 'Discover the key enablers driving successful digital transformation in small and medium enterprises (SMEs). Learn how internal strategies, technology capabilities, skilled personnel, and agile processes, along with external partnerships, digital technologies, and competition, empower SMEs to thrive in the digital era.';
  context.keywords = 'Digital Transformation, SMEs (Small and Medium Enterprises), Organisational Strategy, Sustainable Technology, IT Expertise, Cloud Computing, Internet of Things (IoT), Digital Technologies, Skilled Workforce, Agile Business Processes, External Partnerships, Social Media Innovation, Mobile Technologies, Analytics Solutions, Leadership Support, Digital Competition, Resource Management, Customer Agility, Blockchain Implementation, Innovation Cognition';
  context.seoTags();
}