import { Component, OnInit } from '@angular/core';

import { BaseClass } from 'src/app/modules/core/base/base-class';


@Component({
  selector: 'app-esg',
  templateUrl: './esg.component.html',
  styleUrls: ['./esg.component.css']
})
export class EsgComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  
}
function init(context: EsgComponent) {
  context.title = 'Get in Touch with Skillikz Ltd for Expert IT Solutions | Contact Us';
  context.description = 'Contact Skillikz for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'ESG Strategy, Environmental Sustainability, Social Responsibility, Governance Practices, ESG Reporting, ESG Compliance, Corporate Social Responsibility (CSR), Sustainable Business Practices, ESG Investment, ESG Risk Management, Green Business Initiatives, ESG Performance Metrics, ESG Framework, Climate Change and ESG, Ethical Business Governance, ESG Integration, ESG Standards, Corporate Governance, Social Impact and ESG, ESG Transparency';
  context.seoTags();
}
