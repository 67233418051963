import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-leadership-and-management',
  templateUrl: './leadership-and-management.component.html',
  styleUrls: ['./leadership-and-management.component.css']
})
export class LeadershipAndManagementComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:32';
    window.open(url, '_blank');
  }
}


function init(context: LeadershipAndManagementComponent) {
  context.title = 'Leadership & Management | Skillikz Ltd ';
  context.description = 'Stay current with regulations and effective business practices for leadership & management through our CPD accredited courses designed for managers. Stay current with regulations and effective business practices through our CPD accredited courses. Designed for managers, the training offers flexibility, an intuitive interface, interactive scenarios, and immediate practical application. ';
  context.keywords = 'Leadership Development, Management Consulting Services, Executive Coaching, Leadership Training Programs, Strategic Leadership, Organizational Leadership, Change Management, Leadership Skills Development, High-Performance Leadership, Leadership Excellence, Team Leadership, Leadership Communication, Conflict Resolution for Leaders, Leadership Strategy, Managerial Skills Development, Employee Leadership Programs, Leadership Effectiveness, Executive Leadership Training, Leadership & Management Coaching, Transformational Leadership';
  context.seoTags();
}
