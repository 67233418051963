<div class="options-wrapper">
    <div class="mb-20">
      <p class="mb-10">Share On:</p>
      <div class="share-icons">
        <a role="button" (click)="showFacebookLink()"><img src="assets/images/icons/facebook-blue-icon.svg" alt="Facebook icon" width="24" height="24"> <span>Facebook</span></a>
        <a role="button" (click)="showTwitterLink()"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter icon" width="24" height="24"> <span>Twitter</span></a>
        <a role="button" (click)="showLinkedinLink()"><img src="assets/images/icons/linkedin-blue-icon.svg" alt="Linkedin icon" width="24" height="24"> <span>Linkedin</span></a>
      </div>
    </div>

    <div class="">
      <p class="heading">More Blogs</p>
      <ul class="list-red">
        <li><a routerLink="/blogs-details-1">New Trends in Business Digital Transformation</a></li>
        <li><a routerLink="/blogs-details-2">Stages in Business Digital Transformation</a></li>
        <li><a routerLink="/blogs-details-3">Digitisation Vs Digitalisation Vs Digital Transformation</a></li>
        <!-- <li><a href="#">[BlogHeaderName]</a></li> -->
      </ul>
    </div>

  </div>
