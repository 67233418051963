import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-sec-ops',
  templateUrl: './sec-ops.component.html',
  styleUrls: ['./sec-ops.component.css']
})
export class SecOpsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: SecOpsComponent) {  
  context.title = 'SecOps Services and Consultancy | Strengthening Your Security | Skillikz';
  context.description = 'Discover the power of SecOps with Skillikz to enhance threat detection, achieve compliance, and fortify your security posture. Explore our SecOps services, strategy, and solutions.';
  context.keywords = 'SecOps Solutions, Security Operations Center (SOC), DevSecOps Integration, SecOps Consulting, Cybersecurity Operations, Security Incident Response, Threat Detection and Response, SecOps Automation, Security Monitoring Services, SecOps Platform, Security Risk Management, Continuous Security Monitoring, Incident Management in SecOps, SecOps as a Service, Vulnerability Management in SecOps, Compliance and SecOps, Cloud Security Operations, SecOps Framework, Threat Intelligence and SecOps, Endpoint Security in SecOps';
  context.seoTags();
}
