import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details2',
  templateUrl: './blogs-details2.component.html',
  styleUrls: ['./blogs-details2.component.css']
})
export class BlogsDetails2Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails2Component) {  
  context.title = 'Stages in Business Digital Transformation: A Roadmap for SMEs | Skillikz';
  context.description = 'Learn about the stages of digital transformation for SMEs, including digitization, digital optimization, digital transformation, and continuous improvement. Discover how embracing digital technologies can increase efficiency, productivity, and competitiveness.';
  context.keywords = 'Digital Transformation, Digitization, Digital Optimization, Continuous Improvement, SMEs (Small and Medium-Sized Enterprises), Automation, Workflow Streamlining, Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Data Analytics, Business Models, Revenue Streams, E-commerce, Digital Marketing, Artificial Intelligence (AI), Machine Learning (ML), Blockchain, Innovation Culture, Digital Roadmap, Productivity';
  context.seoTags();
}
