<section class="commonSection topMargin pt-20">
  <div class="container-main">
      <app-breadcrumb></app-breadcrumb>

      <div class="row-flex">
          <div class="items-lg-col-8">                
            <div class="">
              <h1><span>Business</span> Analyst</h1>
            </div>
      
            <div class="job-details">
              <ul>
                <li>Location: <span>London</span></li>
                <li>Reports to: <span>Product Owner</span></li>
                <li>Salary: <span>Competitive</span></li>
                <li>Update On: <span>18 Oct, 2022</span></li>
              </ul>
            </div>
  
          <div class="work-mode">
            <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
          </div>
          </div>

          <!-- share-component-here -->
          <div class="items-lg-col-3 topShareIcon">
              <app-share-on-details></app-share-on-details>
          </div>

          <div class="items-lg-col-8">
              <hr class="border mb-15">

              <div class="related-service mb-20">
                <h2 class="text-black mb-0">Looking for a professional who can understand the business very well in FinTech,
                  Retail industry. Someone who can identify and analyze areas for improvement and define the requirements of
                  the business.</h2>
              </div>
              <hr class="border mb-20">
              
              <div class="">
                <h2 class="text-black">Duties & Responsibilities</h2>
                <p class="mb-5">Fundamental Responsibilities</p>
                <ul class="list-black">
                  <li>Business Analyst with a real passion for learning, a keen interest in the process, a proactive approach, and a desire to create a superb customer experience for our users</li>
                  <li>Analyzing the structure of a business to determine how it operates and determine its objectives</li>
                  <li>Identifying any problems with the current business model</li>
                  <li>Designing potential solutions to any problems identified during the research phase</li>
                  <li>Preparing and delivering a report of their findings to leadership with comprehensive risk and impact assessment</li>
                  <li>Developing comprehensive end-to-end (E2E) test cases at the application and multi-application levels</li>
                  <li>Overseeing the implementation of approved process improvements</li>
                  <li>Hiring, monitoring and overseeing the IT team members</li>
                  <li>Carrying out training sessions and workshops to guide relevant teams</li>
                  <li>Carrying out periodic quality checks</li>
                  </ul>
              </div>
        
                <div class="">
                  <h2 class="text-black">Skill Required</h2>
                  <p class="mb-5">Required Skill Set</p>
                  <ul class="list-black">
                    <li>Hands-on experience with tools like <strong>Figma and Adobe XD</strong> to create functional workflows diagram</li>
                    <li>Demonstrable experience with process flow and business processes.</li>
                    <li>Experience in engaging with both technical and non-technical stakeholders.</li>
                    <li>Consulting experience and background, including engaging directly with clients.</li>
                    <li>Ability to balance and prioritise activities, with the flexibility to quickly adapt to changing business demands</li>
                    <li>Advanced level of English (written and oral skills)</li>
                    <li>Strong problem-solving skills.<strong>&nbsp;</strong></li>
                    </ul>
                </div>
        
                <div class="">
                  <h2 class="text-black">Qualifications</h2>
                  <p class="mb-5">Required Qualification</p>
                  <ul class="list-black">
                    <li>A bachelor/Master’s degree (IT, Computer science preferred ) with 3-4 yrs. Experience in BA role</li>
                  </ul>
                </div>
        
                <div class="">
                  <h2 class="text-black">Salary and Benefits</h2>
                  <ul class="list-black">
                    <li>Flexible working hours</li>
                    <li>Competitive salary depending on skills and experience</li>
                  </ul>
                </div>

          </div>

      </div>  
  </div>
</section>


<section class="gradientTopTo">
  <section class="commonSection marginNone border-b bottomBg-">
      <div class="container-main">
          <div class="mb-60">
              <app-apply-form></app-apply-form>
          </div>
          <app-why-work-with-us></app-why-work-with-us>
      </div>
  </section>
</section>

<section class="sectionForm border-b">
  <div class="container-main">
      <div class="row-flex">
          <div class="custom-col-55">
              <div class="formContent scroll-elem slide-left">
                  <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                          redefine your online presence together.</span></h2>
                  <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                      email or text message.</p>
              </div>
          </div>
          <div class="custom-col-45">
              <!-- Contact Us form component -->
              <app-form></app-form>
          </div>
      </div>
  </div>
</section>

