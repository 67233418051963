import { Injector } from '@angular/core';


export class AppInjector {
  private static appInjector: Injector;
  constructor() { }

  static set injector(injector: Injector) {
    this.appInjector = injector;
  }

  static get injector() {
    return this.appInjector;
  }
}
