import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-case-study7',
  templateUrl: './case-study7.component.html',
  styleUrls: ['./case-study7.component.css']
})
export class CaseStudy7Component extends BaseClass implements OnInit {
  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
}

function init(context: CaseStudy7Component) {  
  context.title = 'Transforming Customer Journey in Lending Finance | Skillikz Customer Portal';
  context.description = 'Discover how Skillikz transformed the customer journey in lending finance with a user-friendly customer portal. Explore our solutions, including instant underwriting decisions, product comparison, application tracking, document management, and e-signature for faster lending processes.';
  context.keywords = 'Customer Journey Mapping, Customer Experience Automation, Automated Customer Engagement, Marketing Automation, Customer Journey Analytics, Personalization Automation, Omnichannel Customer Journey, AI-Powered Customer Journey, Lead Nurturing Automation, Automated Sales Funnel, CRM Automation, Behavioral Targeting Automation, Customer Retention Automation, Customer Lifecycle Automation, Marketing Campaign Automation, Dynamic Content Personalization, Automated Customer Support, Cross-Channel Automation, Predictive Customer Insights, Customer Touchpoint Automation';
  context.seoTags();
}
