import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-trainee-data-analyst',
  templateUrl: './trainee-data-analyst.component.html',
  styleUrls: ['./trainee-data-analyst.component.css']
})
export class TraineeDataAnalystComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TraineeDataAnalystComponent) {  
  context.title = 'Trainee Data Analyst (Consulting Projects Division) - SKILLIKZ';
  context.description = 'Skillikz is a business consulting firm based in London offering research services to a diverse range of clients around the world.';
  context.keywords = 'Data Collection, Data Cleaning, Data Visualization, SQL Queries, Excel for Data Analysis, Statistical Analysis, Data Interpretation, Reporting Dashboards, Data Mining, Data Modeling, Trend Analysis, Python for Data Analysis, R Programming, Machine Learning Basics, Data Warehousing, Big Data, Data Integration, Business Intelligence, KPIs (Key Performance Indicators), Data Quality Assurance';
  context.seoTags();
}
