import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details35',
  templateUrl: './blogs-details35.component.html',
  styleUrls: ['./blogs-details35.component.css']
})
export class BlogsDetails35Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails35Component) {  
  context.title = 'Hyperautomation in Business: Benefits, Challenges, and Examples';
  context.description = 'Learn how Hyperautomation can benefit your organization by automating complex business processes and achieving digital transformation. Discover the challenges of implementing Hyperautomation and the examples of successful applications';
  context.keywords = 'Hyperautomation, Artificial Intelligence (AI), Machine Learning (ML), Robotic Process Automation (RPA), Intelligent Automation, Business Process Automation (BPA), Digital Transformation, Software Development Life Cycle (SDLC), Decision-Making Automation, Problem-Solving Automation, Efficiency Improvement, Scalability, AI and ML Algorithms, Data Insights, Collaboration Between Humans and Machines, Job Satisfaction, Productivity Improvement, Workforce Training, Job Displacement, Automation Challenges';
  context.seoTags();
}
