<div class="dashed-wp mt-20 scroll-elem fade-in-bottom">
  <h2 class="text-black goToApplyForm mb-20" id="goToApplyForm" #goToApplyForm>Job Application Form</h2>

  <div class="sub-title">
    <h3>Desired Job</h3>
  </div>
  <form [formGroup]="applyNowFormGroup" *ngIf="applyNowFormGroup">
    <div class="row-flex mb-15">
      <div class="items-col-3">
        <div class="form-group">
          <label for="applyingFor" class="form-label">Applying For</label>
          <select class="form-control dropIcon" id="applyingFor" name="applyingFor"
            formControlName="applyingForJobType">
            <option value="">Select Job Type</option>
            <option *ngFor="let job of jobTypesList" [value]="job.code">{{job.name}}</option>
          </select>
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="referralCode" class="form-label">Referral</label>
          <div class="check-flex">
            <div class="itemCheck">
              <div class="customRadio">
                <input type="radio" id="yesId" formControlName="referal" [value]="true"> 
                <label for="yesId">Yes</label>
              </div>
            </div>
            <div class="itemCheck">
              <div class="customRadio">
                <input type="radio" id="noId" formControlName="referal" [value]="false">
                <label for="noId">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="referralCode" class="form-label">Referral Code</label>
          <input type="text" class="form-control" id="referralCode" name="referralCode" formControlName="referralCode"
            placeholder="Type your code here">
        </div>
      </div>
    </div>

    <div class="sub-title">
      <h3>Personal Information</h3>
    </div>

    <div class="row-flex mb-15">
      <div class="items-col-3">
        <div class="form-group">
          <label for="fullName" class="form-label">Full Name *</label>
          <input type="text" class="form-control" id="fullName" name="fullName" formControlName="fullName"
            placeholder="Type your full name here">
          <span class="error-msg" *ngIf="applyNowFormGroup.get('fullName').invalid && submitted">
            <span *ngIf="applyNowFormGroup.get('fullName').errors['required']">{{constants.fullNameReq}}</span>
          </span>
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="email" class="form-label">E-mail *</label>
          <input type="text" class="form-control" id="email" name="email" formControlName="email"
            placeholder="Type your email address here">
          <span class="error-msg" *ngIf="applyNowFormGroup.get('email').invalid && submitted">
            <span *ngIf="applyNowFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
            <span *ngIf="applyNowFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
          </span>
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="phoneNumber" class="form-label">Phone Number *</label>
          <input type="text" class="form-control" id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
            placeholder="Type your contact number">
          <span class="error-msg" *ngIf="applyNowFormGroup.get('phoneNumber').invalid && submitted">
            <span *ngIf="applyNowFormGroup.get('phoneNumber').errors['required']">{{constants.phoneNumberReq}}</span>
          </span>
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="linkedInIdLink" class="form-label">LinkedIn ID Link</label>
          <input type="text" class="form-control" id="linkedInIdLink" name="linkedInIdLink"
            formControlName="linkedInIdLink" placeholder="Provide your LinkedIn profile link here">
        </div>
      </div>
    </div>

    <div class="sub-title">
      <h3>Professional Information</h3>
    </div>

    <div class="row-flex mb-15">
      <div class="items-col-3">
        <div class="form-group">
          <label for="currentJobDesignation" class="form-label">Current Job Designation</label>
          <input type="text" class="form-control" id="currentJobDesignation" name="currentJobDesignation"
            formControlName="currentJobDesignation" placeholder="Type your current job designation here">
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="currentEmployer" class="form-label">Current Employer</label>
          <input type="text" class="form-control" id="currentEmployer" name="currentEmployer"
            formControlName="currentEmployer" placeholder="Type your employer name here">
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="totalExperience" class="form-label">Total Experience</label>
          <input type="text" class="form-control" id="totalExperience" name="totalExperience"
            formControlName="totalExperience" placeholder="Your total working experience">
        </div>
      </div>
      <div class="items-col-3">
        <div class="form-group">
          <label for="relevantExperience" class="form-label">Relevant Experience</label>
          <input type="text" class="form-control" id="relevantExperience" name="relevantExperience"
            formControlName="relevantExperience" placeholder="Experience relevant to job applying for">
        </div>
      </div>
    </div>

    <!-- <div class="sub-title">
      <h3>Upload Documents</h3>
    </div>

    <div class="row-flex mb-15">
      <div class="items-col-3">
        <div class="form-group">
          <label for="uploadYourResume" class="form-label">Upload Your Resume</label>
          <input type="file" class="form-control" id="uploadYourResume" name="uploadYourResume"
            (change)="upload($event.target)" multiple formControlName="attachment" placeholder="">
        </div>
      </div>
    </div> -->


    <div class="sub-title">
      <h3>Submit Form</h3>
    </div>


    <div class="row-flex mb-15">
      <div class="items-col-3">
        <button type="submit" class="btn btn-blue btn-100" aria-label="Submit Application Form"
          (click)="onSubmit()">Submit
          Application
          Form</button>
      </div>
    </div>
  </form>
</div>
<app-popup-modal></app-popup-modal>
