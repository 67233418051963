import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-dot-net-technical-architect',
  templateUrl: './dot-net-technical-architect.component.html',
  styleUrls: ['./dot-net-technical-architect.component.css']
})
export class DotNetTechnicalArchitectComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetTechnicalArchitectComponent) {  
  context.title = '.NET Technical Architect - SKILLIKZ';
  context.description = 'We are looking for a dot Net Architect who can understand the customer business in Fintech and Retail industry and provide a scalable business solution that can add value to the business.';
  context.keywords = '.NET Architecture, Solution Design, Microservices Architecture, Cloud Solutions (Azure, AWS), ASP.NET Core, Entity Framework, C# Programming, Distributed Systems, Design Patterns, Domain-Driven Design (DDD), RESTful APIs, Web Services (SOAP, REST), Performance Optimization, Database Design (SQL Server, NoSQL), Continuous Integration/Continuous Deployment (CI/CD), DevOps Practices, High Availability and Scalability, Security Architecture, Integration Architecture, Cross-Platform Development (Xamarin, .NET MAUI)';
  context.seoTags();
}
