import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-share-on-details',
  templateUrl: './share-on-details.component.html',
  styleUrls: ['./share-on-details.component.css']
})
export class ShareOnDetailsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
  }

}
