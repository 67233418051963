<div class="mb-30">
    <h2 class="large">Whitepaper/Articles</h2>

    <div class="row-flex articles-wp">

      <div class="items-col-3">
        <div class="articles-card scroll-elem slide-left">
          <h3>Navigating Regulatory Compliance: The Challenges Businesses Face and the Vitality of Training Employees on Compliance</h3>
          <p>In today's fast-paced business environment, regulatory compliance has become a critical aspect of operations for organisations across various industries. However, many businesses need help with the ever-increase...</p>
          <div class="btn-fixed">
            <a routerLink="/blogs-details-19423">
              <button type="button" class="btn btn-white" aria-label="Read Article">Read Article</button>
            </a>
          </div>
        </div>
      </div>
      <div class="items-col-3">
        <div class="articles-card scroll-elem fade-in-bottom">
          <h3>Prevailing Challenges in Ensuring Financial Compliance: A Market Review</h3>
          <p>Financial compliance is a critical aspect of the financial services industry beyond mere adherence to rules and regulations. It encompasses building trust and confidence among stakeholders, including employees...</p>
          <div class="btn-fixed">
            <a routerLink="/blogs-details-5523">
              <button type="button" class="btn btn-white" aria-label="Read Article">Read Article</button>
            </a>
          </div>
        </div>
      </div>
      <div class="items-col-3">
        <div class="articles-card scroll-elem slide-right">
          <h3>Is Compliance a Culture Issue? Building a Culture of Compliance in the Tech Industry</h3>
          <p>The tech industry has faced numerous challenges recently, including privacy violations, security hacks, and harassment charges. As government agencies launch investigations into tech companies' business practices...</p>
          <div class="btn-fixed">
            <a routerLink="/blogs-details-17523">
              <button type="button" class="btn btn-white" aria-label="Read Article">Read Article</button>
            </a>
          </div>
        </div>
      </div>

    </div>

  </div>