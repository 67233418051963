import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailContactUs } from '../../../modals/email-contact-us.modal';
import { ContactUsService } from '../../contact-us/contact-us.service';
import { Constants } from '../../../modules/core/base/constants';
import { DataTransferService } from '../../../modules/core/base/api.service';
import { BaseClass } from 'src/app/modules/core/base/base-class';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent extends BaseClass implements OnInit {

  contactUsFormGroup: FormGroup;
  submitted: boolean = false;
  emailContactUs: EmailContactUs = new EmailContactUs();
  constructor(public contactUsService: ContactUsService, public constants: Constants,
    public dataTransfer: DataTransferService) { super() }

  ngOnInit(): void {
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  onSuccess(response: any) {
    this.dataTransfer.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }

  onError(errorCode: number, errorMsg: string) {
    // error page
  }
}

function init(context: FormComponent) {
  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);
}

function addFormValidation(context: FormComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, Validators.required),
    lastName: new FormControl(context.emailContactUs.lastName),
    phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.pattern(/^\d+$/), Validators.maxLength(12)]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message),
  });
}
