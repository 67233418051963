import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.css']
})
export class FrontendComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: FrontendComponent) {  
  context.title = 'Frontend Development Services | Skillikz Ltd';
  context.description = 'Skillikz Ltd offers professional frontend development services to help businesses create beautiful and user-friendly web applications. Contact us today to learn more.';
  context.keywords = 'Web Development Services, Custom Web Development, Full-Stack Web Development, Front-End Web Development, Back-End Web Development, Web Application Development, E-commerce Web Development, Responsive Web Design, Mobile-Friendly Web Development, WordPress Web Development, Website Design and Development, Web Development Consulting, Web Development Solutions, CMS Web Development, Web Development Company, Web Development for Startups, SEO Web Development, Web Development Technologies, Cloud-Based Web Development, Web Development for Businesses';
  context.seoTags();
}
