import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.css']
})
export class BackendComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: BackendComponent) {  
  context.title = 'Backend Development Services | Skillikz Ltd ';
  context.description = 'Skillikz Ltd offers professional backend development services to help businesses build robust and scalable applications. Contact us today to learn more.';
  context.keywords = 'Backend Development Services, Backend Software Development, Backend Application Development, Backend Systems Integration, Backend API Development, Backend Architecture Design, Cloud Backend Solutions, Backend Database Management, Backend Server Development, Backend Frameworks, Scalable Backend Solutions, RESTful API Development, Backend Development Consulting, Backend Performance Optimization, Microservices Architecture, Backend for Mobile Applications, Backend Security Solutions, Data-Driven Backend Development, Backend for Web Applications, Full-Stack Development (Backend Focus)';
  context.seoTags();
}

