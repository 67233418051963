<section class="commonSection topMargin pt-20">
  <div class="container-main">
      <app-breadcrumb></app-breadcrumb>

      <div class="row-flex">
          <div class="items-lg-col-8">                
            <div class="">
              <h1><span>.NET</span> Technical Architect</h1>
            </div>
        
            <div class="job-details">
              <ul>
                <li>Location: <span> 	Essex, Basildon</span></li>
                <li>Reports to: <span>Head of Projects</span></li>
                <li>Salary: <span>Competitive</span></li>
                <li>Update On: <span>09 Oct, 2022</span></li>
              </ul>
            </div>
  
          <div class="work-mode">
              <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
          </div>
          </div>

          <!-- share-component-here -->
          <div class="items-lg-col-3 topShareIcon">
              <app-share-on-details></app-share-on-details>
          </div>

          <div class="items-lg-col-8">
              <hr class="border mb-15">

              <div class="related-service mb-20">
                <h2 class="text-black mb-0">We are looking for a dot Net Architect who can understand the customer business in Fintech and Retail industry and provide a scalable business solution that can add value to the business.</h2> 
              </div>
              <hr class="border mb-20">
              
              <div class="">
                <h2 class="text-black">Duties & Responsibilities</h2>
                <p class="mb-5">Fundamental Responsibilities</p>
                <ul class="list-black">
                  <li>To manage end to end technical landscape of the enterprise project</li>
                  <li>Lead the technical team of 10-20 people</li>
                  <li>Responsible for the successful delivery of the project in all technical aspects</li>
                  </ul>
              </div>
        
              <div class="">
                <h2 class="text-black">Skill Required</h2>
                <p class="mb-5">Required Skill Set</p>
                <ul class="list-black">
                  <li>Capable of understanding business requirements and crafting&nbsp;technical&nbsp;solutions using appropriate tools and technologies</li>
                  <li>Should have the capability to handle enterprise-level projects independently</li>
                  <li>Hands-on latest Dot Net framework, including .Net Core</li>
                  <li>Intensive hands-on application development and&nbsp;architecture experience</li>
                  <li>Design Pattern and Architecture design</li>
                  <li>Experience in handling large databases/transactions on SQL Server</li>
                  <li>Strong object-oriented design skills with excellent knowledge/usage of Design patterns</li>
                  <li>Knowledge of UML and design artefacts (HLD, LLD etc.)</li>
                  <li>Hands-On experience in any of the cloud (Azure, AWS, GCP)</li>
                  <li>Experience with persistence and data access layer technology such as NHibernate, LINQ, or Entity Framework</li>
                  <li>Expert Knowledge of WCF/ Restful framework/Web API</li>
                  <li>Agile Development Methodology</li>
                  <li>WPF, C#, Dot Net, ASP.Net, MVC, HTML5, ORM tools</li>
                  <li>Sound knowledge and hands-on in SQL Server and performance tuning</li>
                  <li>Sound knowledge of Java Script &amp; JQuery</li>
                  <li>Automated Deployment experience using CI/CD</li>
                  <li>Hands-on experience in Micro Services based design and containerized deployment with Docker and Kubernetes experience</li>
                  <li>Strong Communications skills along with leadership capability</li>
                  <li>Should be flexible at work and must be task driven than clock oriented</li>
                  <li>Good interpersonal skills along with self-drive for excellence</li>
                  </ul>
              </div>
              <div class="">
                <h2 class="text-black">Preferred:</h2>
                <ul class="list-black">
                  <li>SSIS/ADF</li>
                  <li>BizTalk/LogicApps</li>
                  <li>SharePoint/Office365 (Power Platform)</li>
                  <li>Working knowledge of any ERP system</li>
                  </ul>
              </div>
        
        
        
        
              <div class="">
                <p class="mb-5">Qualifications:</p>
                <ul class="list-black">
                  <li>A bachelor/Master's degree (IT, Computer Science preferred) with a minimum of 10+ years of experience </li>
                  </ul>
              </div>
        
              <div class="">
                <h2 class="text-black">Salary and Benefits</h2>
                <ul class="list-black">
                  <li>Competitive salary depending on skills and experience</li>
                  <li>Flexible working hours</li>
                  <li>Remote working available for this role</li>
                  </ul>
              </div>

          </div>

      </div>  
  </div>
</section>


<section class="gradientTopTo">
  <section class="commonSection marginNone border-b bottomBg-">
      <div class="container-main">
          <div class="mb-60">
              <app-apply-form></app-apply-form>
          </div>
          <app-why-work-with-us></app-why-work-with-us>
      </div>
  </section>
</section>

<section class="sectionForm border-b">
  <div class="container-main">
      <div class="row-flex">
          <div class="custom-col-55">
              <div class="formContent scroll-elem slide-left">
                  <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                          redefine your online presence together.</span></h2>
                  <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                      email or text message.</p>
              </div>
          </div>
          <div class="custom-col-45">
              <!-- Contact Us form component -->
              <app-form></app-form>
          </div>
      </div>
  </div>
</section>
