import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-trainee-project-manager',
  templateUrl: './trainee-project-manager.component.html',
  styleUrls: ['./trainee-project-manager.component.css']
})
export class TraineeProjectManagerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TraineeProjectManagerComponent) {  
  context.title = 'Trainee Project Manager (Intern) - SKILLIKZ';
  context.description = 'Skillikz is a business consulting firm based in London offering research services to a diverse range of clients around the world.';
  context.keywords = 'Project Planning, Task Scheduling, Resource Allocation, Stakeholder Communication, Project Scope, Risk Management, Gantt Charts, Agile Methodology, Scrum Framework, Project Budgeting, Project Milestones, Team Collaboration, Time Management, Quality Control, Project Documentation, Progress Tracking, Change Management, Problem Solving, Project Reporting, Project Life Cycle';
  context.seoTags();
}
