<section class="commonSection topMargin pt-20">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="row-flex">
            <div class="items-lg-col-8">
                <h1><span>Sr. Engineer –</span> DevOps</h1>
                <div class="job-details">
                    <ul>
                        <li>Location: <span>UK</span></li>
                        <li>Experience: <span>3-9 years</span></li>
                        <li>Skills: <span>NoSQL, Data Analytics, Git, Jenkins, CI/CD, Agile</span></li>
                        <!-- <li>Update On: <span>19 September, 2023</span></li> -->
                    </ul>
                </div>
                <div class="work-mode mb-0">
                    <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
                </div>                
            </div>

            <!-- share-component-here -->
            <div class="items-lg-col-3 topShareIcon">
                <app-share-on-details></app-share-on-details>
            </div>

            <div class="items-lg-col-8">
                <hr class="border mb-15">

                <div class="related-service mb-20">
                    <h2 class="text-black mb-0">We are looking for an innovative, experienced, and talented senior software engineer who will work closely with business unit and development team to develop new system capabilities as defined within the technical/ functional specifications.</h2>
                </div>
                <hr class="border mb-20">
                
                <div class="">
                    <h2 class="text-black">Key Responsibilities: </h2>
                    <ul class="list-black">
                        <li>Responsible for leading projects, designing system solutions, developing custom applications, and modifying existing applications to meet distinct and changing business requirements. Handle coding, debugging, and documentation, as well working closely with SRE team. Provide post implementation and ongoing production support.</li>
                        <li>Develop and design software applications, translating user needs into system architecture. Assess and validate application performance and integration of component systems and provide process flow diagrams. Test the engineering resilience of software and automation tools.
                        </li>
                        <li>You will be challenged with identifying innovative ideas and proof of concept to deliver against the existing and future needs of our customers. Software Engineers who join our Loyalty Technology team will be assigned to one of several exciting teams that are developing a modern loyalty platform which supports the key element of connecting with our customers where they are and how they choose to interact with the company.</li>
                        <li>Be part of an enthusiastic, high performing technology team developing solutions to drive engagement and loyalty within our existing cardmember base and attract new customers to the brand.</li>
                        <li>The position will also play a critical role partnering with other development teams, testing and quality, and production support, to meet implementation dates and allow smooth transition throughout the development lifecycle.</li>
                        <li>The successful candidate will be focused on building and executing against a strategy and roadmap focused on moving from monolithic, tightly coupled, batch-based legacy platforms to a loosely coupled, event-driven, microservices-based architecture to meet our long-term business goals.</li>
                         
                    </ul>
    
                    <h2 class="text-black">Minimum Qualifications: </h2>
                    <ul class="list-black">
                        <li>Position requires a bachelor’s degree in computer science, Engineering, or a related field followed by 5+ years of experience in a modern development stack (Golang preferred).</li>
                        <li>Experience in application design, software development, and testing, preferably in an Agile environment.
                        </li>
                        <li>Experience working with Git on small to medium sized teams.</li>
                        <li>Experience with open-source frameworks is a plus, especially maintaining or contributing to open-source projects.</li>
                        <li>Experience with relational and NoSQL databases, including PostgreSQL, Couchbase, or Cassandra is a plus.</li>
                        <li>Experience designing and developing REST APIs for high volume clients.</li>
                        <li>Experience with continuous integration tools (GitHub Actions, Jenkins, Gitlab CI) a plus.</li>
                        <li>Experience with automated build and test frameworks a plus.</li>
                        <li>A proven hunger to learn new technologies and translate them into working software.</li>
                        <li>Experience with container and container orchestration technologies, such as Docker and Kubernetes, a plus.</li>
                        <li>Experience with Atlassian software development and collaboration tools (JIRA, Confluence, etc.), a plus.</li>
                        <li>Strong ability to develop unique, outside the box ideas.</li>
                        <li>Strong analytical, problem-solving/quantitative skills</li>
                        <li>Willing to take risks, experiment, and share fresh perspectives.</li>
                        <li>Aptitude for learning and applying programming concepts.</li>
                        <li>Ability to effectively communicate with internal and external business partners.</li>
                         
                    </ul>

                    <h2 class="text-black">Preferred Additional: </h2>
                    <ul class="list-black">
                        <li>Knowledge of payments industry</li>
                        <li>Experience with coding skills across a variety of distributed technologies</li>
                        <li>Experience with a broad range of software languages and payments technologies</li>
                         
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">What do we look for?</h2>
                    <ul class="list-black">
                        <li>At least five years of experience as an SME in Business Intelligence disciplines.</li>
                        <li>At least two years of deep experience with Big Data solutions.</li>
                        <li>Basic expertise in working in a global environment.</li>
                        <li>Proven experience (minimum of 3 years) in managing complex, multi-dimensional demands.</li>
                        <li>Practical experience with customer onboarding, especially within the realm of financial data.</li>
                    </ul>
                </div>
    
                <div class="">
                    <h2 class="text-black">Why Join Us?</h2>
                    <ul class="list-black mb-0">
                        <li>This role provides an exciting opportunity to kickstart or elevate your consulting career. By merging hands-on
                        client projects with vast resources, Skillikz offers a unique platform for growth, learning, and impactful work.</li>
                        <li>Interested candidates can apply with their CV and cover letter detailing relevant experience. We thank all
                        applicants for their interest, but only those selected for an interview will be contacted. Skillikz Ltd is an
                        equal opportunity employer.</li>
                    </ul>
                </div>
            </div>

        </div>  
    </div>
  </section>


<section class="gradientTopTo">
    <section class="commonSection marginNone border-b bottomBg-">
        <div class="container-main">
            <div class="mb-60">
                <app-apply-form></app-apply-form>
            </div>
            <app-why-work-with-us></app-why-work-with-us>
        </div>
    </section>
</section>

<section class="sectionForm border-b">
    <div class="container-main">
        <div class="row-flex">
            <div class="custom-col-55">
                <div class="formContent scroll-elem slide-left">
                    <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                            redefine your online presence together.</span></h2>
                    <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                        email or text message.</p>
                </div>
            </div>
            <div class="custom-col-45">
                <!-- Contact Us form component -->
                <app-form></app-form>
            </div>
        </div>
    </div>
</section>