<div class="share-icons customShareIcon">
  <p>Share On:</p>
  <a role="button" (click)="showFacebookLink()"><img src="assets/images/icons/facebook-blue-icon.svg" alt="Facebook icon" width="24" height="24"> <span>Facebook</span></a>
  <a role="button" (click)="showTwitterLink()"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter icon" width="24" height="24"> <span>Twitter</span></a>
  <a role="button" (click)="showLinkedinLink()"><img src="assets/images/icons/linkedin-blue-icon.svg" alt="Linkedin icon" width="24" height="24"> <span>Linkedin</span></a>
</div>





