import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CloudComponent) {  
  context.title = 'Embrace the Power of Cloud Computing for Your Business | Skillikz';
  context.description = "Unlock the Future with Cloud Solutions. Explore Skillikz's comprehensive cloud services to scale, innovate, and succeed in today's digital era.";
  context.keywords = 'Cloud Computing Services, Cloud Solutions, Cloud Infrastructure, Cloud Migration Services, Cloud-Based Applications, Cloud Security Solutions, Cloud Storage Services, Cloud Hosting, Cloud Technology Consulting, Hybrid Cloud Solutions, Multi-Cloud Strategy, Cloud Management Services, Cloud Service Providers, Cloud Backup Solutions, Cloud Integration Services, Cloud Platform Development, Cloud Data Analytics, Cloud Disaster Recovery, SaaS (Software as a Service) Solutions, PaaS (Platform as a Service) Solutions';
  context.seoTags();
}


