import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-service-now',
  templateUrl: './service-now.component.html',
  styleUrls: ['./service-now.component.css']
})
export class ServiceNowComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
function init(context: ServiceNowComponent) {  
  context.title = "ServiceNow Consulting Services | Skillikz Ltd ";
  context.description = 'Skillikz Ltd offers expert ServiceNow consulting services to help businesses streamline their IT service management processes and improve operational efficiency. Contact us today to learn more.';
  context.keywords = 'ServiceNow case study, IT operations case study, ServiceNow implementation case study, ServiceNow customization case study, Skillikz Technologies case study, case study examples, IT success stories';
  context.seoTags();
}