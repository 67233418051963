<section class="section-inner-content topMargin">
    <div class="container-main bgWhite">

        <app-breadcrumb></app-breadcrumb>

        <div class="">
            <h1 class="large"><span>Terms of Use</span></h1>
            <p>Welcome to Skillikz.co.uk (the “Website”). The Website is owned and operated by Skillikz Ltd.
                (“Skillikz”, “we”, “us” or “our”). These Terms of Use (“Terms”) govern your use of the Website, and by
                using the Website, you agree to be bound by these Terms. Please read them carefully. </p>
        </div>


        <div class="mb-20">
            <h2 class="text-black">Acceptance of Terms </h2>
            <p>By using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you may
                not use the Website. We reserve the right, in our sole discretion, to modify or update these Terms at
                any time without prior notice to you. Your continued use of the Website following the posting of changes
                to these Terms will mean that you accept those changes. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Use of the Website </h2>
            <p>The Website is intended for personal and non-commercial use only. You may not use the Website for any
                illegal or unauthorized purpose. You agree to comply with all applicable laws and regulations in
                connection with your use of the Website. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">User Content </h2>
            <p>The Website may allow you to post or submit content, including without limitation, comments, photos,
                videos, and other materials (collectively, “User Content”). By posting or submitting User Content, you
                represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to
                use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights
                in and to any and all User Content to enable inclusion and use of the User Content in the manner
                contemplated by the Website and these Terms. </p>
            <p>You acknowledge and agree that we have the right (but not the obligation) to monitor, edit, remove or
                delete any User Content in our sole discretion, including without limitation User Content that violates
                these Terms or is otherwise objectionable. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Intellectual Property </h2>
            <p>The Website and its contents, including without limitation, text, graphics, images, logos, icons,
                software, and the compilation of all content on the Website, are the property of Skillikz or its content
                suppliers and are protected by international copyright laws. All trademarks, service marks, trade names,
                and trade dress are proprietary to Skillikz or its content suppliers. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Links to Third-Party Websites </h2>
            <p>The Website may contain links to third-party websites or resources. We are not responsible for the
                content, products, or services on or available from those websites or resources, and you access them at
                your own risk. We do not endorse any third-party websites or resources or the content, products, or
                services available from them. </p>
        </div>

        <div class="mb-20">
            <h2 class="text-black">Disclaimer of Warranties </h2>
            <p>The Website and its contents are provided on an “as is” and “as available” basis. We make no
                representations or warranties of any kind, express or implied, as to the operation of the Website or the
                information, content, materials, or products included on the Website. To the full extent permissible by
                applicable law, we disclaim all warranties, express or implied, including without limitation, implied
                warranties of merchantability and fitness for a particular purpose. </p>
        </div>

        <div class="">
            <h2 class="text-black">Limitation of Liability </h2>
            <p class="mb-0">In no event shall Skillikz, its affiliates, or any of their respective officers, directors, employees,
                agents, or licensors be liable to you or any third party for any damages of any kind arising out of or
                in connection with your use of the Website, including, without limitation, any direct, indirect,
                incidental, special, punitive, or consequential damages, or damages for loss of profits, revenue, data,
                or use, arising out of or in any way connected with the use or inability to use the Website or any of
                its contents, whether based on contract, tort, strict liability, or any other legal theory, even if
                Skillikz has been. </p>
        </div>

    </div>
</section>