import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogsDetails1Component } from './views/blogs-pages/blogs-details1/blogs-details1.component';
import { BlogsDetails10Component } from './views/blogs-pages/blogs-details10/blogs-details10.component';
import { BlogsDetails11Component } from './views/blogs-pages/blogs-details11/blogs-details11.component';
import { BlogsDetails12Component } from './views/blogs-pages/blogs-details12/blogs-details12.component';
import { BlogsDetails13Component } from './views/blogs-pages/blogs-details13/blogs-details13.component';
import { BlogsDetails14Component } from './views/blogs-pages/blogs-details14/blogs-details14.component';
import { BlogsDetails15Component } from './views/blogs-pages/blogs-details15/blogs-details15.component';
import { BlogsDetails16Component } from './views/blogs-pages/blogs-details16/blogs-details16.component';
import { BlogsDetails17Component } from './views/blogs-pages/blogs-details17/blogs-details17.component';
import { BlogsDetails18Component } from './views/blogs-pages/blogs-details18/blogs-details18.component';
import { BlogsDetails19Component } from './views/blogs-pages/blogs-details19/blogs-details19.component';
import { BlogsDetails2Component } from './views/blogs-pages/blogs-details2/blogs-details2.component';
import { BlogsDetails20Component } from './views/blogs-pages/blogs-details20/blogs-details20.component';
import { BlogsDetails21Component } from './views/blogs-pages/blogs-details21/blogs-details21.component';
import { BlogsDetails22Component } from './views/blogs-pages/blogs-details22/blogs-details22.component';
import { BlogsDetails23Component } from './views/blogs-pages/blogs-details23/blogs-details23.component';
import { BlogsDetails24Component } from './views/blogs-pages/blogs-details24/blogs-details24.component';
import { BlogsDetails25Component } from './views/blogs-pages/blogs-details25/blogs-details25.component';
import { BlogsDetails26Component } from './views/blogs-pages/blogs-details26/blogs-details26.component';
import { BlogsDetails27Component } from './views/blogs-pages/blogs-details27/blogs-details27.component';
import { BlogsDetails28Component } from './views/blogs-pages/blogs-details28/blogs-details28.component';
import { BlogsDetails29Component } from './views/blogs-pages/blogs-details29/blogs-details29.component';
import { BlogsDetails3Component } from './views/blogs-pages/blogs-details3/blogs-details3.component';
import { BlogsDetails30Component } from './views/blogs-pages/blogs-details30/blogs-details30.component';
import { BlogsDetails31Component } from './views/blogs-pages/blogs-details31/blogs-details31.component';
import { BlogsDetails32Component } from './views/blogs-pages/blogs-details32/blogs-details32.component';
import { BlogsDetails33Component } from './views/blogs-pages/blogs-details33/blogs-details33.component';
import { BlogsDetails34Component } from './views/blogs-pages/blogs-details34/blogs-details34.component';
import { BlogsDetails35Component } from './views/blogs-pages/blogs-details35/blogs-details35.component';
import { BlogsDetails36Component } from './views/blogs-pages/blogs-details36/blogs-details36.component';
import { BlogsDetails37Component } from './views/blogs-pages/blogs-details37/blogs-details37.component';
import { BlogsDetails38Component } from './views/blogs-pages/blogs-details38/blogs-details38.component';
import { BlogsDetails39Component } from './views/blogs-pages/blogs-details39/blogs-details39.component';
import { BlogsDetails4Component } from './views/blogs-pages/blogs-details4/blogs-details4.component';
import { BlogsDetails40Component } from './views/blogs-pages/blogs-details40/blogs-details40.component';
import { BlogsDetails5Component } from './views/blogs-pages/blogs-details5/blogs-details5.component';
import { BlogsDetails6Component } from './views/blogs-pages/blogs-details6/blogs-details6.component';
import { BlogsDetails7Component } from './views/blogs-pages/blogs-details7/blogs-details7.component';
import { BlogsDetails8Component } from './views/blogs-pages/blogs-details8/blogs-details8.component';
import { BlogsDetails9Component } from './views/blogs-pages/blogs-details9/blogs-details9.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { BusinessAnalystComponent } from './views/business-analyst/business-analyst.component';
import { AllJobOpeningsComponent } from './views/career-module/all-job-openings/all-job-openings.component';
import { ChaosResiliencyTestEngineerComponent } from './views/chaos-resiliency-test-engineer/chaos-resiliency-test-engineer.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { CustomerServiceAssociateComponent } from './views/customer-service-associate/customer-service-associate.component';
import { DotNetDeveloperComponent } from './views/dot-net-developer/dot-net-developer.component';
import { DotNetTechnicalArchitectComponent } from './views/dot-net-technical-architect/dot-net-technical-architect.component';
import { ELearningDeveloperComponent } from './views/e-learning-developer/e-learning-developer.component';
import { EsgComponent } from './views/esg/esg.component';
import { FinancialComplianceComponent } from './views/financial-compliance/financial-compliance.component';
import { HomeComponent } from './views/home/home.component';
import { HumanResourcesComponent } from './views/human-resources/human-resources.component';
import { JavaDeveloper1Component } from './views/java-developer1/java-developer1.component';
import { JavaDeveloper2Component } from './views/java-developer2/java-developer2.component';
import { JobDetailsComponent } from './views/job-details/job-details.component';
import { OurValuesComponent } from './views/our-values/our-values.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { BusinessDigitalTransformationComponent } from './views/services-module/business-digital-transformation/business-digital-transformation.component';
import { LearningServicesComponent } from './views/services-module/learning-services/learning-services.component';
import { ProductEngineeringComponent } from './views/services-module/product-engineering/product-engineering.component';
import { SalesforcePracticeComponent } from './views/services-module/salesforce-practice/salesforce-practice.component';
import { TechnologyConsultingComponent } from './views/services-module/technology-consulting/technology-consulting.component';
import { BackendComponent } from './views/technology-module/backend/backend.component';
import { FrontendComponent } from './views/technology-module/frontend/frontend.component';
import { MobilityComponent } from './views/technology-module/mobility/mobility.component';
import { TermsConditionsComponent } from './views/terms-conditions/terms-conditions.component';
import { TraineeDataAnalystComponent } from './views/trainee-data-analyst/trainee-data-analyst.component';
import { TraineeProjectManagerComponent } from './views/trainee-project-manager/trainee-project-manager.component';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { CookiesPolicyComponent } from './views/cookies-policy/cookies-policy.component';
import { AdobeExperienceManagerComponent } from './views/adobe-experience-manager/adobe-experience-manager.component';
import { ServiceNowComponent } from './views/technology-module/service-now/service-now.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { BlogsDetailsB8623Component } from './views/blogs-pages/blogs-details-b8623/blogs-details-b8623.component';
import { BlogsDetailsB17523Component } from './views/blogs-pages/blogs-details-b17523/blogs-details-b17523.component';
import { BlogsDetailsB19423Component } from './views/blogs-pages/blogs-details-b19423/blogs-details-b19423.component';
import { BlogsDetailsB19623Component } from './views/blogs-pages/blogs-details-b19623/blogs-details-b19623.component';
import { BlogsDetailsB5523Component } from './views/blogs-pages/blogs-details-b5523/blogs-details-b5523.component';
import { BlogsDetailsB12723Component } from './views/blogs-pages/blogs-details-b12723/blogs-details-b12723.component';
import { HealthAndSafetyComponent } from './views/lms-pages/health-and-safety/health-and-safety.component';
import { HealthCareComponent } from './views/lms-pages/health-care/health-care.component';
import { InformationSecurityComponent } from './views/lms-pages/information-security/information-security.component';
import { LeadershipAndManagementComponent } from './views/lms-pages/leadership-and-management/leadership-and-management.component';
import { BlogsDetailsB4823Component } from './views/blogs-pages/blogs-details-b4823/blogs-details-b4823.component';
import { BlogsDetailsB14823Component } from './views/blogs-pages/blogs-details-b14823/blogs-details-b14823.component';
import { BlogsDetailsB30823Component } from './views/blogs-pages/blogs-details-b30823/blogs-details-b30823.component';
import { CloudComponent } from './views/technology-module/cloud/cloud.component';
import { CaseStudiesComponent } from './views/services-module/case-studies/case-studies.component';
import { CaseStudy1Component } from './views/services-module/case-study1/case-study1.component';
import { CaseStudy2Component } from './views/services-module/case-study2/case-study2.component';
import { CaseStudy3Component } from './views/services-module/case-study3/case-study3.component';
import { CaseStudy4Component } from './views/services-module/case-study4/case-study4.component';
import { CaseStudy5Component } from './views/services-module/case-study5/case-study5.component';
import { CaseStudy6Component } from './views/services-module/case-study6/case-study6.component';
import { CaseStudy7Component } from './views/services-module/case-study7/case-study7.component';
import { CaseStudy8Component } from './views/services-module/case-study8/case-study8.component';
import { CaseStudy9Component } from './views/services-module/case-study9/case-study9.component';
import { StaffAugmentationComponent } from './views/services-module/staff-augmentation/staff-augmentation.component';
import { CaseStudy10Component } from './views/services-module/case-study10/case-study10.component';
import { SecOpsComponent } from './views/technology-module/sec-ops/sec-ops.component';
import { BigDataConsultantComponent } from './views/big-data-consultant/big-data-consultant.component';
import { DevOpsComponent } from './views/technology-module/dev-ops/dev-ops.component';
import { CustomDevelopmentComponent } from './views/technology-module/custom-development/custom-development.component';
import { CareerComponent } from './views/career/career.component';
import { WhoWeAreComponent } from './views/who-we-are/who-we-are.component';
import { GolangSoftwareEngineerComponent } from './views/golang-software-engineer/golang-software-engineer.component';
import { UiEngineersReactjsNodejsComponent } from './views/ui-engineers-reactjs-nodejs/ui-engineers-reactjs-nodejs.component';
import { SrEngineerDevopsComponent } from './views/sr-engineer-devops/sr-engineer-devops.component';
import { ProductOwnerUkComponent } from './views/product-owner-uk/product-owner-uk.component';
import { QuallityEngineerItComponent } from './views/quallity-engineer-it/quallity-engineer-it.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "who-we-are", component: WhoWeAreComponent },
  { path: "our-values", component: OurValuesComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "backend", component: BackendComponent },
  { path: "web-development", component: FrontendComponent },
  { path: "mobility", component: MobilityComponent },
  { path: "devOps", component: DevOpsComponent },
  { path: "secOps", component: SecOpsComponent },
  { path: "product-engineering", component: ProductEngineeringComponent },
  { path: "business-digital-transformation", component: BusinessDigitalTransformationComponent },
  { path: "technology-consulting", component: TechnologyConsultingComponent },
  { path: "salesforce-practice", component: SalesforcePracticeComponent },
  { path: "learning-services", component: LearningServicesComponent },
  { path: "human-resources", component: HumanResourcesComponent },
  { path: "financial-compliance", component: FinancialComplianceComponent },
  { path: "all-job-openings", component: AllJobOpeningsComponent },
  { path: "job-details", component: JobDetailsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-of-use-lms", component: TermsConditionsComponent },
  { path: "esg", component: EsgComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "cookies-policy", component: CookiesPolicyComponent },
  { path: "service-now", component: ServiceNowComponent },
  { path: "custom-development", component: CustomDevelopmentComponent },
  { path: "career", component: CareerComponent },

  { path: "dot-net-developer", component: DotNetDeveloperComponent },
  { path: "java-developer1", component: JavaDeveloper1Component },
  { path: "java-developer2", component: JavaDeveloper2Component },
  { path: "chaos-resiliency-test-engineer", component: ChaosResiliencyTestEngineerComponent },
  { path: "business-analyst", component: BusinessAnalystComponent },
  { path: "dot-net-technical-architect", component: DotNetTechnicalArchitectComponent },
  { path: "trainee-project-manager", component: TraineeProjectManagerComponent },
  { path: "trainee-data-analyst", component: TraineeDataAnalystComponent },
  { path: "customer-service-associate", component: CustomerServiceAssociateComponent },
  { path: "e-learning-developer", component: ELearningDeveloperComponent },
  { path: "adobe-experience-manager", component: AdobeExperienceManagerComponent },
  { path: "golang-software-engineer", component: GolangSoftwareEngineerComponent },
  { path: "ui-engineers-reactjs-nodejs", component: UiEngineersReactjsNodejsComponent },
  { path: "sr-engineer-devops", component: SrEngineerDevopsComponent },
  { path: "product-owner-uk", component: ProductOwnerUkComponent },
  { path: "big-data-consultant", component: BigDataConsultantComponent },
  { path: "blogs", component: BlogsComponent },
  { path: "blogs-details-1", component: BlogsDetails1Component },
  { path: "blogs-details-2", component: BlogsDetails2Component },
  { path: "blogs-details-3", component: BlogsDetails3Component },
  { path: "blogs-details-4", component: BlogsDetails4Component },
  { path: "blogs-details-5", component: BlogsDetails5Component },
  { path: "blogs-details-6", component: BlogsDetails6Component },
  { path: "blogs-details-7", component: BlogsDetails7Component },
  { path: "blogs-details-8", component: BlogsDetails8Component },
  { path: "blogs-details-9", component: BlogsDetails9Component },
  { path: "blogs-details-10", component: BlogsDetails10Component },
  { path: "blogs-details-11", component: BlogsDetails11Component },
  { path: "blogs-details-12", component: BlogsDetails12Component },
  { path: "blogs-details-13", component: BlogsDetails13Component },
  { path: "blogs-details-14", component: BlogsDetails14Component },
  { path: "blogs-details-15", component: BlogsDetails15Component },
  { path: "blogs-details-16", component: BlogsDetails16Component },
  { path: "blogs-details-17", component: BlogsDetails17Component },
  { path: "blogs-details-18", component: BlogsDetails18Component },
  { path: "blogs-details-19", component: BlogsDetails19Component },
  { path: "blogs-details-20", component: BlogsDetails20Component },
  { path: "blogs-details-21", component: BlogsDetails21Component },
  { path: "blogs-details-22", component: BlogsDetails22Component },
  { path: "blogs-details-23", component: BlogsDetails23Component },
  { path: "blogs-details-24", component: BlogsDetails24Component },
  { path: "blogs-details-25", component: BlogsDetails25Component },
  { path: "blogs-details-26", component: BlogsDetails26Component },
  { path: "blogs-details-27", component: BlogsDetails27Component },
  { path: "blogs-details-28", component: BlogsDetails28Component },
  { path: "blogs-details-29", component: BlogsDetails29Component },
  { path: "blogs-details-30", component: BlogsDetails30Component },
  { path: "blogs-details-31", component: BlogsDetails31Component },
  { path: "blogs-details-32", component: BlogsDetails32Component },
  { path: "blogs-details-33", component: BlogsDetails33Component },
  { path: "blogs-details-34", component: BlogsDetails34Component },
  { path: "blogs-details-35", component: BlogsDetails35Component },
  { path: "blogs-details-36", component: BlogsDetails36Component },
  { path: "blogs-details-37", component: BlogsDetails37Component },
  { path: "blogs-details-38", component: BlogsDetails38Component },
  { path: "blogs-details-39", component: BlogsDetails39Component },
  { path: "blogs-details-40", component: BlogsDetails40Component },
  { path: "blogs-details-8623", component: BlogsDetailsB8623Component },
  { path: "blogs-details-17523", component: BlogsDetailsB17523Component },
  { path: "blogs-details-19423", component: BlogsDetailsB19423Component },
  { path: "blogs-details-19623", component: BlogsDetailsB19623Component },
  { path: "blogs-details-5523", component: BlogsDetailsB5523Component },
  { path:"blogs-details-12723", component: BlogsDetailsB12723Component },
  { path: "blogs-details-4823", component: BlogsDetailsB4823Component },
  { path: "blogs-details-14823", component: BlogsDetailsB14823Component }, 
  { path: "blogs-details-30823", component: BlogsDetailsB30823Component }, 

  { path:"health-and-safety", component: HealthAndSafetyComponent },
  { path:"health-care", component: HealthCareComponent },
  { path:"information-security", component: InformationSecurityComponent },
  { path:"leadership-and-management", component: LeadershipAndManagementComponent },
  { path:"cloud", component: CloudComponent },
  { path:"staff-augmentation", component: StaffAugmentationComponent },
  { path:"quality-engineer", component: QuallityEngineerItComponent },

  { path:"case-studies", component: CaseStudiesComponent },
  { path:"case-study-1", component: CaseStudy1Component },
  { path:"case-study-2", component: CaseStudy2Component },
  { path:"case-study-3", component: CaseStudy3Component },
  { path:"case-study-4", component: CaseStudy4Component },
  { path:"case-study-5", component: CaseStudy5Component },
  { path:"case-study-6", component: CaseStudy6Component },
  { path:"case-study-7", component: CaseStudy7Component },
  { path:"case-study-8", component: CaseStudy8Component },
  { path:"case-study-9", component: CaseStudy9Component },
  { path:"case-study-10", component: CaseStudy10Component },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
