import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details-b17523',
  templateUrl: './blogs-details-b17523.component.html',
  styleUrls: ['./blogs-details-b17523.component.css']
})
export class BlogsDetailsB17523Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetailsB17523Component) {  
  context.title = 'Is Compliance a Culture Issue? Building a Culture of Compliance in the Tech Industry | Skillikz Blog';
  context.description = 'Explore the role of compliance in the tech industry and the importance of building a culture of compliance. Discover valuable insights and strategies on creating a compliant culture in the Skillikz blog.';
  context.keywords = 'Compliance Culture, Organisational Ethics, Regulatory Compliance, Cultural Alignment, Employee Engagement, Ethical Behaviour, Risk Mitigation, Talent Attraction, Role of Managers, Compliance Measurement, Compliance Incentives, Employee Retention, Ethical Leadership, Business Differentiation, Cultural Expectations, Organisational Values, Trust Building, Consumer Expectations, Ethical Challenges, Legal Risks';
  context.seoTags();
}
