import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-customer-service-associate',
  templateUrl: './customer-service-associate.component.html',
  styleUrls: ['./customer-service-associate.component.css']
})
export class CustomerServiceAssociateComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: CustomerServiceAssociateComponent) {  
  context.title = 'Customer Service Associate (Legal) - SKILLIKZ';
  context.description = 'To provide administration and assistance to a Conveyancing Property Lawyer and Team in relation to the progression of active conveyancing files. To carry out further ad hoc duties as required by the management.';
  context.keywords = 'Customer Support, Issue Resolution, Customer Inquiries, Communication Skills, Call Center Operations, Complaint Handling, Product Knowledge, Customer Satisfaction, Multitasking, CRM Software (Customer Relationship Management), Empathy and Patience, Problem-Solving, Order Processing, Technical Support, Customer Feedback, Live Chat Support, Email Support, Troubleshooting, Service Recovery, Customer Retention';
  context.seoTags();
}
