import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.css']
})
export class CookiesConsentComponent {
  isCookie: boolean = false;
  expireDays: number = 7;

  constructor(private _cookieService: CookieService) { }

  ngOnInit() {
    this.isCookie = this._cookieService.get('CookiesAccepted') === "Yes" ? true : false;
   
    
  }

  onDismissClick() {
    this._cookieService.set('CookiesAccepted', 'Yes', { expires: this.expireDays, path: '/' });
    this.isCookie = true;
    document.querySelector('body').classList.remove("openModal");
  }

  closeCookies() {
    (document.querySelector('.overlay') as HTMLElement)!.style.display = "none";
  }
  moreinfo() {

    this.isCookie = false;
    // (document.querySelector('.cookie-wrapper') as HTMLElement)!.style.display = "none";
    (document.querySelector('.cookiespolicyModal') as HTMLElement)!.style.display = "block";
    document.querySelector('body').classList.add("openModal");


  }
  showSettings() {
    this.isCookie = false;
    // (document.querySelector('.cookie-wrapper') as HTMLElement)!.style.display = "none";
    (document.querySelector('.settingsModal') as HTMLElement)!.style.display = "block";
    document.querySelector('body').classList.add("openModal");
  }

  closeSettings() {
    // (document.querySelector('.overlay') as HTMLElement)!.style.display = "none";
    (document.querySelector('.settingsModal') as HTMLElement)!.style.display = "none";
    document.querySelector('body').classList.remove("openModal");
  }
  closecookiesSettings()
  {

  // (document.querySelector('.overlay') as HTMLElement)!.style.display = "none";
  (document.querySelector('.cookiespolicyModal') as HTMLElement)!.style.display = "none";
  document.querySelector('body').classList.remove("openModal");
  }

}
