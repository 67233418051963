import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from './constants';

export interface ApiResponseCallback {
  onSuccess(response);
  onError(errorCode: number, errorMsg: string);
}

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  private hideShowLoaderSubject = new Subject<boolean>();
  hideShowLoaderObservable = this.hideShowLoaderSubject.asObservable();

  private shareDataSubject = new Subject<any>();
  shareDataObservable = this.shareDataSubject.asObservable();

  constructor() { }
  onHideShowLoader(showLoader: boolean) {
    setTimeout(() => {
      this.hideShowLoaderSubject.next(showLoader);
    }, 100);
  }

  shareData(data: any) {
    this.shareDataSubject.next(data);
  }
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient, private constants: Constants, private dataService: DataTransferService) { }

  hitGetApi(url, apiResponseCallback: ApiResponseCallback, showLoader?: boolean) {
    if (navigator.onLine) {
      if (showLoader == undefined)
        showLoader = true;
      this.dataService.onHideShowLoader(showLoader ? true : false);
      this.httpClient.get(url)
        .subscribe((res: any) => {
          // console.log(res);
          if (showLoader)
            this.dataService.onHideShowLoader(false);
          if (res && res.hasOwnProperty('Error_code'))
            apiResponseCallback.onError(res.Error_code, res.Message)
          else
            apiResponseCallback.onSuccess(res)
        },
          (err: HttpErrorResponse) => {
            apiResponseCallback.onError(err.status, err.message)
          }
        )
    }
    else {
      apiResponseCallback.onError(this.constants.noInternetConnectionError, this.constants.errorNoInternetConnectionAvailable);
    }
  }

  hitPostApi(url, formData, apiResponseCallback: ApiResponseCallback, header?: any, showLoader?: boolean) {
    if (navigator.onLine) {
      if (showLoader == undefined)
        showLoader = true;
      this.dataService.onHideShowLoader(showLoader ? true : false);
      let request: Observable<any>;
      if (header)
        request = this.httpClient.post(url, formData, header);
      else {
        request = this.httpClient.post(url, formData);
      }
      request.subscribe((res: any) => {
        this.dataService.onHideShowLoader(false);
        if (res && res.hasOwnProperty('Error_code'))
          apiResponseCallback.onError(res.Error_code, res.Message)
        else
          apiResponseCallback.onSuccess(res)
      },
        (err: HttpErrorResponse) => {
          apiResponseCallback.onError(err.status, err.message)
        }
      )
    } else {
      apiResponseCallback.onError(this.constants.noInternetConnectionError, this.constants.errorNoInternetConnectionAvailable);
    }
  }

  hitPutApi(url, formData, apiResponseCallback: ApiResponseCallback, header?: any) {

    if (navigator.onLine) {
      this.dataService.onHideShowLoader(true);
      let request: Observable<any>;
      if (header)
        request = this.httpClient.put(url, formData, header);
      else {
        request = this.httpClient.put(url, formData);
      }
      request.subscribe((res: any) => {

        this.dataService.onHideShowLoader(false);
        if (res && res.hasOwnProperty('Error_code'))
          apiResponseCallback.onError(res.Error_code, res.Message)
        else
          apiResponseCallback.onSuccess(res)
      },
        (err: HttpErrorResponse) => {
          apiResponseCallback.onError(err.status, err.message)

        }
      )
    } else {
      apiResponseCallback.onError(this.constants.noInternetConnectionError, this.constants.errorNoInternetConnectionAvailable);
    }
  }

  hitDeleteApi(url, apiResponseCallback: ApiResponseCallback, showLoader?: boolean) {
    if (navigator.onLine) {
      if (showLoader == undefined)
        showLoader = true;
      this.dataService.onHideShowLoader(showLoader ? true : false);
      this.httpClient.delete(url)
        .subscribe((res: any) => {
          if (showLoader)
            this.dataService.onHideShowLoader(false);
          if (res && res.hasOwnProperty('Error_code'))
            apiResponseCallback.onError(res.Error_code, res.Message)
          else
            apiResponseCallback.onSuccess(res)
        },
          (err: HttpErrorResponse) => {
            apiResponseCallback.onError(err.status, err.message)
          }
        )
    }
    else {
      apiResponseCallback.onError(this.constants.noInternetConnectionError, this.constants.errorNoInternetConnectionAvailable);
    }
  }



  hitMultipleRequest(observables: Array<Observable<any>>, apiResponseCallback: ApiResponseCallback) {
    this.dataService.onHideShowLoader(true);
    forkJoin(observables).subscribe(responses => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onSuccess(responses);
    }, err => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onError(err.status, err.statusText);
    });
  }

  hitMultipleURLRequest(urls: string[], apiResponseCallback: ApiResponseCallback) {
    this.dataService.onHideShowLoader(true);
    let observables: Array<Observable<Object>> = [];
    urls.forEach(element => {
      // console.log(element)
      observables.push(this.httpClient.get(element).pipe(catchError(error => of(error))));
    });
    forkJoin(observables).subscribe(responses => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onSuccess(responses);
    }, err => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onError(err.status, err.statusText);
    });
  }

  hitApiUsingObservable(observable: Observable<any>, apiResponseCallback: ApiResponseCallback) {
    this.dataService.onHideShowLoader(true);
    observable.subscribe(responses => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onSuccess(responses);
    }, err => {
      this.dataService.onHideShowLoader(false);
      apiResponseCallback.onError(err.status, err.statusText);
    });
  }

  getHttpGetRequestObservable(url): Observable<any> {

    return this.httpClient.get(url);
  }

  getHttpPostObservable(url, data, header?) {
    return this.httpClient.post(url, data, header).pipe(catchError(error => of(error)));
  }

  getHttpPutObservable(url, data, header?) {
    return this.httpClient.put(url, data, header).pipe(catchError(error => of(error)));
  }
}

