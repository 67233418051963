export class EmailContactUs {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    toAddress: string;
    message: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    isActive: boolean;
    isDeleted: boolean;
}