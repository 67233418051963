<div class="">
    <h2 class="large">Our Approach</h2>
    <div class="approach-flow">
      <div class="items scroll-elem slide-left">
        <div class="">
          <div class="item-icon">
            <img src="assets/images/icons/actionable-training-icon.svg" alt="Actionable Training" width="110" height="76">
          </div>
          <div class="item-content">
            <h3>Actionable Training</h3>
          </div>
        </div>
        <p>Acquire knowledge and skills through our short courses and apply them at work.</p>
      </div>
      <div class="items scroll-elem fade-in-bottom">
        <div class="">
          <div class="item-icon">
            <img src="assets/images/icons/meaningful-interaction-icon.svg" alt="Meaningful Interaction" width="110"
              height="76">
          </div>
          <div class="item-content">
            <h3>Meaningful Interaction</h3>
          </div>
        </div>
        <p>Carefully crafted interactions will help you connect theory and vocational practices</p>
      </div>
      <div class="items scroll-elem slide-right">
        <div class="">
          <div class="item-icon">
            <img src="assets/images/icons/experimental-learning-icon.svg" alt="Experimental Learning" width="110"
              height="76">
          </div>
          <div class="item-content">
            <h3>Experimental Learning</h3>
          </div>
        </div>
        <p>Learn through experience that will allow you to practice new skills with confidence</p>
      </div>
    </div>
  </div>