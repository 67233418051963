import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-sr-engineer-devops',
  templateUrl: './sr-engineer-devops.component.html',
  styleUrls: ['./sr-engineer-devops.component.css']
})

export class SrEngineerDevopsComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: SrEngineerDevopsComponent) {  
  context.title = 'Sr. Engineer DevOps Vacancy at Skillikz - Redefining Tech & Business Services';
  context.description = `We are looking for an innovative, experienced, and talented senior software engineer who will work closely with business unit and development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'DevOps Engineering, Continuous Integration (CI), Continuous Deployment (CD), Infrastructure as Code (IaC), Cloud Computing (AWS, Azure, GCP), Kubernetes (K8s), Docker Containers, Automation Scripting, Jenkins Pipelines, Ansible Configuration Management, Terraform Infrastructure Automation, Monitoring and Logging (Prometheus, Grafana), CI/CD Pipeline Optimization, Version Control (Git), Agile Methodologies, Cloud-Native Applications, Security Automation, DevSecOps Practices, Scalability and Reliability, Incident Management';
  context.seoTags();
}

