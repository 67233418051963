<section class="commonSection topMargin pt-20">
  <div class="container-main">
      <app-breadcrumb></app-breadcrumb>

      <div class="row-flex">
          <div class="items-lg-col-8">                
            <div class="">
              <h1><span>Customer</span> Service Associate (Legal)</h1>
            </div>
      
            <div class="job-details">
              <ul>
                <li>Location: <span>Essex ,Basildon</span></li>
                <li>Reports to: <span>Project Operations Manager</span></li>
                <li>Salary: <span>Competitive</span></li>
                <li>Work Mode: <span>35 hrs per week</span></li>
                <li>Salary: <span>Up to £10.00 per hour</span></li>
                <li>Update On: <span>16 Jul, 2021</span></li>
              </ul>
            </div>
  
          <div class="work-mode">
            <p>Work Mode: <span>Full Time</span> Job Status: <span class="closed">Closed</span></p>
          </div>
          </div>

          <!-- share-component-here -->
          <div class="items-lg-col-3 topShareIcon">
              <app-share-on-details></app-share-on-details>
          </div>

          <div class="items-lg-col-8">
              <hr class="border mb-15">

              <div class="related-service mb-20">
                <h2 class="text-black mb-0">To provide administration and assistance to a Conveyancing Property Lawyer and Team
                  in relation to the progression of active conveyancing files. To carry out further ad hoc duties as
                  required by the management.</h2>
              </div>
              <hr class="border mb-20">

              <div class="">
                <h2 class="text-black mb-5">Scope of the Role</h2>
                <p>This role will be based in Basildon reporting to the Project Operations Manager. The first line of
                  management will be your Property Lawyer.</p>
              </div>
      
              <div class="">
                <h2 class="text-black mb-5">Duties & Responsibilities</h2>
                <ul class="list-black">
                  <li>Contacting Clients, Agents, Referrers, Search providers and other parties Solicitors to progress active
                    conveyancing transactions.</li>
                  <li>Requesting documentation fundamental to the transaction.</li>
                  <li>Ensuring documents are returned by Clients and work providers, prompting them if required and
                    progressing transactions.</li>
                  <li>Dealing with sale, purchase and re-mortgage matters to the extent delegated by the Fee Earner, to
                    include:
                    <ul class="list-roman">
                      <li>Any preliminary matters on both sale and purchase files.</li>
                      <li>Obtaining all required documentation from the client, other-side, third parties (such as HMLR, HMRC
                        and Lenders).</li>
                      <li>Drafting sale contracts and re-mortgage documentation as required by the Property Lawyer.</li>
                      <li>Dealing with Lenders on both sale and purchase matters, checking redemption statements, mortgage
                        offers and dealing with the financial calculations relating to the same as required by the Property
                        Lawyer.</li>
                    </ul>
                  </li>
                  <li>Assisting with purchase files, including:
                    <ul class="list-roman">
                      <li>Obtaining initial documentation.</li>
                      <li>Collating contract packs.</li>
                      <li>Administrative duties relating to the title checks/title reports.</li>
                      <li>Assisting the Property Lawyer in raising enquiries.</li>
                      <li>Reviewing and reporting on mortgages.</li>
                      <li>Ordering (all appropriate) searches, review and reporting on the same as directed by the Property
                        Lawyer.</li>
                    </ul>
                  </li>
                  <li>Preparation of files for completion to include, final searches, requests for mortgage advances and
                    liaison with Clients as to receipt of balance funds.</li>
                  <li>Dealing with all appropriate Notices/Deeds – Post Completion.</li>
                  <li>Ensuring the timely transfer of files following completion to the Post Completion Team.</li>
                  <li>Updating the Referrer, Agent, other websites and portals.</li>
                  <li>Checking the Conveyancing Inbox daily as appropriate and dealing with any emails relating to current
                    files.</li>
                  <li>Clearing daily task list in Case Management Systems (e.g., Perfect portal, LEAP, OSPREY etc.).</li>
                  <li>Telephone enquiries as received into the office.</li>
                  <li>Making telephone calls as required in relation to redemptions, completion dates and search results.</li>
                  <li>This list is not exhaustive and other tasks may be requested by the Property Lawyer/Management.</li>
                </ul>
              </div>

          </div>

      </div>  
  </div>
</section>


<section class="gradientTopTo">
  <section class="commonSection marginNone border-b bottomBg-">
      <div class="container-main">
          <div class="mb-60">
              <app-apply-form></app-apply-form>
          </div>
          <app-why-work-with-us></app-why-work-with-us>
      </div>
  </section>
</section>

<section class="sectionForm border-b">
  <div class="container-main">
      <div class="row-flex">
          <div class="custom-col-55">
              <div class="formContent scroll-elem slide-left">
                  <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                          redefine your online presence together.</span></h2>
                  <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                      email or text message.</p>
              </div>
          </div>
          <div class="custom-col-45">
              <!-- Contact Us form component -->
              <app-form></app-form>
          </div>
      </div>
  </div>
</section>

