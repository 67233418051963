import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-details14',
  templateUrl: './blogs-details14.component.html',
  styleUrls: ['./blogs-details14.component.css']
})
export class BlogsDetails14Component extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails14Component) {  
  context.title = 'Emerging Trends in ERP Systems: What Businesses Should Know';
  context.description = 'Discover the latest trends in ERP systems and learn how they can help your business streamline processes, integrate data, and improve overall operations. From cloud-based systems to AI and machine learning, stay ahead of the curve with these emerging trends';
  context.keywords = 'Cloud-Based ERP, Mobile ERP, Artificial Intelligence (AI), Machine Learning, Internet of Things (IoT) Integration, ERP System Automation, Real-time Data Insights, Predictive Analytics, User Experience (UX), Intuitive Interfaces, Simplified Workflows, ERP Cloud Hosting, Data Integration, Supply Chain Management, ERP Mobile Accessibility, ERP Adoption, AI-Powered Decision-Making, Digital Transformation, ERP Customization, Employee Productivity';
  context.seoTags();
}
