import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-learning-services',
  templateUrl: './learning-services.component.html',
  styleUrls: ['./learning-services.component.css']
})
export class LearningServicesComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  sendToCal(){
    var d = new Date().getFullYear().toString() + '-' + '0' + (new Date().getMonth() + 1).toString().slice(-2);
    const url = 'https://calendly.com/skillikz/30min?back=1&month='+d;
    window.open(url, '_blank');
  }

}
function init(context: LearningServicesComponent) {  
  context.title = 'Learning Services | Skillikz Ltd ';
  context.description = 'Stay ahead with 300+ online courses covering industry best practices, regulatory compliance, and 50+ CPD accredited options, including healthcare. Enjoy 24x7 access for flexible learning. ';
  context.keywords = 'E-Learning Services, Corporate Training Solutions, Learning and Development (L&D), Online Learning Platforms, Customized Training Programs, Digital Learning Solutions, Virtual Learning Environment, Employee Training Services, Skill Development Courses, Blended Learning Solutions, Microlearning Modules, Learning Management System (LMS), Upskilling and Reskilling, Remote Learning Solutions, Instructional Design Services, Onboarding Training, Leadership Development Programs, Mobile Learning Solutions, Interactive Learning Content, Learning Analytics and Assessment';
  context.seoTags();
}
