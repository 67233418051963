import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-more-blogs',
  templateUrl: './more-blogs.component.html',
  styleUrls: ['./more-blogs.component.css']
})
export class MoreBlogsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  showFacebookLink() {
    let FacebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(FacebookLink,"_new")
  }
  showTwitterLink() {
    let twitterLink = "https://twitter.com/intent/tweet?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(twitterLink, "_new")
  }
  showLinkedinLink() {
    let linkedinLink = "https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(linkedinLink, "_new")
  }

}
