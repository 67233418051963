<section class="section-inner-content-lms topMargin">

  <div class="container-main">
    <app-breadcrumb></app-breadcrumb>

    <div class="">
      <h1><span>Health</span> Care</h1>

      <div class="lms-top-content">
        <div class="itemsContent">
          <p>Access 35+ engaging courses, including Care Certificate standards. Learn with flexibility, an intuitive interface, and interactive scenarios that allow immediate application of knowledge to your job.</p>
        </div>
        <div class="itemImg">
          <div class="img-wp">
            <img src="assets/images/health-care-img.webp" alt="Health Care image" width="485" height="432"
              onerror="this.src='assets/images/health-care-img.png'">
            <button type="button" class="btn btn-blue" aria-label="Buy Bundle" (click)="buybundels()">Buy Bundle</button>
          </div>
          <app-share-on></app-share-on>
        </div>
      </div>
    </div>


    <div class="">
      <h2 class="large">Courses</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Understanding your role in Care</h3>
            <p>This course has been produced for anyone who is responsible for the duty of caring for adults or
              supporting them, be it in a care home, a hospital, an adult’s own home, or anywhere in-between.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:199" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Your personal development in Care</h3>
            <p>This course has been produced for anyone responsible with the duty of caring for adults or supporting
              them, be it in a care home, a hospital, an adult’s own home, or anywhere in-between.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:234" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Duty of Care</h3>
            <p>This course has been produced for anyone who is responsible with the duty of caring for adults or
              supporting them, be it in a care home, a hospital, an adult’s own home, or anywhere in-between.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:208" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Equality and Diversity in Care</h3>
            <p>Embrace equality and inclusion in the care setting. Access information and support on diversity and
              inclusion, promote respect for diversity through effective interventions, challenge discrimination, and
              apply principles of equality and diversity in health & social care.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:211" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Work in a person centred way</h3>
            <p>Embrace person-centred values. Support individuals with empathy, minimizing pain and discomfort. Use
              person-centred approaches to preserve identity and self-esteem, ensuring holistic and compassionate care.
            </p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:232" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="course-card-inner">
            <h3>Communication in Care</h3>
            <p>
              Master effective communication principles. Meet individual needs and preferences, promote effective
              communication, uphold confidentiality, utilize verbal/non-verbal cues, support communication
              aids/technologies, and recognize the vital role of communication in providing quality care.</p>
            <div class="btn-fixed">
              <a href="https://lms.skillikz.com/catalog/info/id:202" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
              </a>
            </div>
          </div>
        </div>

      </div>



      <!-- --------Hidden courses wrapper beginning here----------- -->
      <div class="">

        <div class="hidden-wrapper">
          <div class="row-flex">
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Privacy and Dignity in Care</h3>
                <p>
                  Embrace principles of privacy and dignity. Maintain individual privacy and dignity, support their
                  right to make choices, and actively involve them in their care decisions for a person-centered
                  approach.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:226" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Fluids and Nutrition in Care</h3>
                <p>Learn principles of hydration, nutrition, and food safety. Support individuals to access fluids and
                  nutrition according to their care plan, ensuring their well-being and health.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:213" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Mental health, dementia and learning disabilities</h3>
                <p>Promote well-being for individuals with these needs. Emphasize early detection, grasp legal
                  frameworks, policies, and guidelines. Comprehend mental capacity for effective support.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:221" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Safeguarding adults in Care</h3>
                <p>Recognize the impact of carer's health on well-being, including domestic violence effects. Prioritize
                  adult rights in safeguarding. Learn proper action to take if concerned, reporting issues to
                  appropriate authorities and seeking advice.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:228" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Basic Life Support & First Aid</h3>
                <p>
                  Learn workplace health and safety responsibilities. Respond to accidents, sudden illness, medication,
                  and healthcare tasks. Promote fire safety, secure work practices, and manage stress effectively.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:201" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Handling Information in Care</h3>
                <p>Learn proper handling of personal/sensitive information within a care setting. Recognize the
                  importance of secure information handling, access support when needed, and ensure compliance with
                  legislation for information handling and storage.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:214" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Infection prevention and control</h3>
                <p>Learn measures to prevent germ transmission. Practice infection prevention for procedures/devices.
                  Understand various infections and their impact. Emphasize good personal hygiene for effective germ
                  prevention.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:217" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Medication Awareness</h3>
                <p>Learn policies and procedures for medication administration. Explore common medication types and
                  their uses. Master administration procedures and techniques to ensure accurate and safe medication
                  delivery.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:220" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="items-col-3">
              <div class="course-card-inner">
                <h3>Moving and Handling in Care</h3>
                <p>
                  Identify relevant legislation. Safely move and handle individuals and objects while upholding dignity
                  and adhering to regulations. Understand personal responsibilities and reporting procedures for manual
                  handling incidents.</p>
                <div class="btn-fixed">
                  <a href="https://lms.skillikz.com/catalog/info/id:222" target="_blank">
                    <button type="button" class="btn btn-white-external" aria-label="Buy Course">Buy Course</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="show-all-wp">
          <div class="">
            <button type="button" class="btn btn-outline-blue showAllId" aria-label="Show All Courses">Show All
              Courses</button>
          </div>
        </div>


      </div>
      <!-- --------Hidden courses wrapper end here----------- -->
    </div>

  </div>

</section>


<section class="section-gradient-inner">
  <div class="container-main">


    <div class="mb-30">
      <h2 class="large">Social Media</h2>

      <div class="row-flex">
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-left">
            <div class="text-wrapper">
              <div class="textMore">
                <p> Health Care Essential Courses</p>
                <p>Understand their responsibilities and the duties of others relating to health and safety in the workplace. Understand procedures for responding to accidents and sudden illness. Understand medication and healthcare tasks. Promote fire safety, Work securely, and Manage stress.</p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.youtube.com/watch?v=qB5oBbCOzn8&list=PLAy395buT7V67f33Nl6kYIHJkycG-3VZ1" target="_blank">
              <div class="">
                <img src="assets/images/social-media-youtube-img2.webp" alt="social Media Youtube img" width="412"
                  height="232" onerror="this.src='assets/images/social-media-youtube-img2.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.youtube.com/watch?v=qB5oBbCOzn8&list=PLAy395buT7V67f33Nl6kYIHJkycG-3VZ1" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Go to Youtube</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem fade-in-bottom">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Is Compliance a Culture Issue? Building a Culture of Compliance in the Tech Industry</p>
                <p>Compliance goes beyond legalities in the tech industry - it's about fostering a culture of differentiation, talent attraction, and success.</p>
                <p>Steps to build a compliance culture: define expected behaviours, measure compliance, assess outcomes, and prevent escalation.</p>
                <p>Managers play a pivotal role in shaping and upholding the culture of compliance. </p>
                <p>Ethical standards should be embedded in purpose, brand, and culture to navigate new business realities.</p>
                <p>Empower employees to raise ethical concerns and create an environment that encourages open dialogue.</p>
                <p>Prioritize compliance culture for long-term success in the ever-evolving tech industry.</p>
                <p>Read our article <a href="https://buff.ly/3Nexuse" target="_blank">https://buff.ly/3Nexuse</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_complianceculture-techindustry-ethics-activity-7087008518704095234-VKsK?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img3.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img3.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_complianceculture-techindustry-ethics-activity-7087008518704095234-VKsK?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
        <div class="items-col-3">
          <div class="social-media-card scroll-elem slide-right">
            <div class="text-wrapper">
              <div class="textMore">
                <p>Unlocking the Power of Digital Transformation in SMEs</p>
                <p>In today's tech-driven landscape, SMEs have a unique opportunity to embrace digital transformation and revolutionise their businesses.</p>
                <p>Key enablers drive this journey:</p>
                <p>Internal Enablers:</p>
                <ol>
                  <li>Organisational Strategy: Clear goals and effective communication are crucial</li>
                  <li>Sustainable Tech Capabilities: Leveraging IT resources empowers digital initiatives</li>
                  <li>Skilled People: Expertise and leadership catalyse success</li>
                  <li>Agile Processes: Flexibility paves the way for innovation</li>
                </ol>                
                <p>External Enablers:</p>
                <ol>
                  <li>Accessibility to Partnerships: Collaborating for shared resources and growth</li>
                  <li>Digital Technology: Embracing cutting-edge tech drives transformation</li>
                  <li>Digital Competition: Leveraging customer feedback and agility</li>
                </ol>
                <p>Join the digital revolution and thrive in the dynamic market!</p>
                <p>Read article <a href="https://buff.ly/3K8Aplv" target="_blank">https://buff.ly/3K8Aplv</a></p>
              </div>
              <div class="readMore">
                <a href="javascript:void(0)" class="btnShowMore" role="button">Show More</a>
              </div>
            </div>
            <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7089492019617619968-oIHG?utm_source=share&utm_medium=member_desktop" target="_blank">
              <div class="">
                <img src="assets/images/social-media-img4.webp" alt="social Media img" width="412" height="232"
                  onerror="this.src='assets/images/social-media-img4.png'">
              </div>
            </a>
            
            <div class="btn-fixed">
              <a href="https://www.linkedin.com/posts/skillikz_digitaltransformation-technologyadoption-activity-7089492019617619968-oIHG?utm_source=share&utm_medium=member_desktop" target="_blank">
                <button type="button" class="btn btn-white-external" aria-label="Read Online">Read Online</button>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --Whitepaper/Articles component--- -->
    <app-articles-shared></app-articles-shared>

    <!-- ----Our Approach component--- -->
    <app-our-approach-shared></app-our-approach-shared>

    <!-- ---Other Course Catalogues component--- -->
    <app-other-course-catalogues [url]="'/health-care'"></app-other-course-catalogues>

  </div>
</section>