<!-- carousal section start -->

<div class="" *ngIf="videoSrc">
  <div class="">
    <video [muted]="'muted'" autoplay="autoplay" loop="loop" playsinline id="video-start" >
      <source [src]="videoSrc" type="video/mp4">
    </video>
  </div>

  <!-- <div class="onlyForWeb">
    <video [muted]="'muted'" autoplay="autoplay" loop="loop" playsinline id="video-start" >
      <source src="assets/images/banner/home-page-banner-large.mp4" type="video/mp4">
    </video>
  </div>
  
  <div class="onlyForMobile">
    <video [muted]="'muted'" autoplay="autoplay" loop="loop" playsinline id="video-start" >
      <source src="assets/images/banner/home-page-banner-small.mp4" type="video/mp4"> 
    </video>
  </div> -->
</div>


<section class="section-what-we-do">
  <div class="container-main">
    <h1><span>What We Do</span></h1>
    <div class="contentLeft whatWeDoBg-1 mb-60">
      <div>
        <h2>Product Engineering</h2>
        <p>Continuous change necessitates higher levels of corporate agility. Keeping Agile-led engineering and product mind-set, we speed up business value delivery and time to market by developing & upgrading business solutions, enhancing operations and development processes. </p>
        <p>With our robust Product Engineering service offerings, we are best positioned to respond to real-time business needs and to deliver innovation-led solutions to support business growth and scalability. </p>
      </div>
      <button type="button" class="btn btn-orange" aria-label="Discover Product Engineering "
        routerLink="product-engineering">
        <span>Discover Product Engineering </span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>

    <div class="border-b mb-60"></div>

    <div class="contentRight whatWeDoBg-2 mb-60">
      <div class="scroll-elem slide-right">
        <h2>Business Digital Transformation</h2>
        <p>With Business Digital Transformation (BDT), we help our customers to prosper in the digital era. This is done by analysing business process, customer expectations and enabling business functions to deliver value.</p>
        <P>With our proven BDT framework, we work with our customers to define current business & technology landscape. Keeping business vision and KPIs at the core, we plan for higher level of process automation and identify modernization opportunities of business applications. </P>
      </div>

      <button type="button" class="btn btn-orange scroll-elem slide-left" aria-label="Business Digital Transformation"
        routerLink="business-digital-transformation">
        <span>Business Digital Transformation</span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
    <div class="border-b mb-60"></div>
    <div class="contentLeft whatWeDoBg-3 mb-60">
      <div class="scroll-elem slide-left">
        <h2>Consulting</h2>
        <p>The role of technology is far greater than serving businesses and customers. It is reshaping the way businesses are run and the way customers interact with solutions. It's no more about “best-fit” but the “right-fit” approach while choosing a technology platform. </p>
        <p>Through our Technology Consulting services, we provide deep technology expertise to our customers in some of the areas like defining technology roadmap, architecture design, Cloud adoption, Security & Compliance and Software development and Testing.  </p>
        </div>
      <button type="button" class="btn btn-orange scroll-elem slide-right" aria-label="Discover Consulting " routerLink="technology-consulting">
        <span>Discover Consulting </span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
    <div class="border-b mb-60"></div>
    <div class="contentRight whatWeDoBg-4 mb-60">
      <div class="scroll-elem slide-right">
        <h2>Staff Augmentation</h2>
        <p>Hiring and managing resources is one of the key challenges in achieving the business objectives and delivering IT projects. </p>
        <p>We help organisations build or expand their team capabilities and expertise with our extensive talent pool. With our active in-house talent management team, we ensure that we provide our customers with quick access to top IT professionals from our resource pool.</p>
        <!-- <p>We offer, Team As A Service, Managed Team, Team Extension and Technology Consulting</p> -->
      </div>
      <button type="button" class="btn btn-orange scroll-elem slide-left" aria-label="Discover Staff Augmentation"
        routerLink="staff-augmentation">
        <span>Discover Staff Augmentation </span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
    <div class="border-b mb-60"></div>
    <div class="contentLeft whatWeDoBg-5">
      <div class="scroll-elem slide-left">
        <h2>Learning Services</h2>
        <p>Skillikz makes workplace learning accessible and exciting for everyone, delivering real-world skills for
          employees, and real value for employers.</p>
        <p>Our Courses is CPD approved and covers industry good practices and support employees to get acquainted with
          regulatory compliance. Our Learning App tracks progress and offers immense flexibility to professionals with
          busy work schedules.</p>
      </div>
      <button type="button" class="btn btn-orange scroll-elem slide-right" aria-label="Discover Learning Services"
        routerLink="learning-services">
        <span>Discover Learning Services</span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
  </div>
</section>

<!-- Testimony section starts -->
<section class="sectionForm">
  <div class="container-main">
    <h2 class="large mb-40">What Client Says</h2>
    <app-testimony-slider></app-testimony-slider>
  </div>
</section>
<!-- Testimony section ends -->

<!--latest work-->
<section class="section-latest-works">
  <div class="container-main no-scroll-y">
    <div class="row-flex ">
      <div class="items-col-1 mb-15">
        <h2 class="large">Latest Works</h2>
        <h3 class="subTitle scroll-elem slide-left">Below are some of our works, demonstrating value delivery to organisations. </h3>
      </div>
    </div>
    <div class="card-img-wp">
      <div class="targetCard">
        <div class="row-flex">
          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>Mortgage Process Automation </h2>
              <div class="img100">
                <img src="assets/images/bdt-small-img.webp" alt="Business Digital Transformation" width="512"
                  height="175" onerror="this.src='assets/images/bdt-small-img.png'">
              </div>
              <p>For a leading mortgage firm based in the USA, their vision to improve overall customer experience and engagement, is realised by delivering a fully automated multi-tenant platform for mortgage business process. 

              </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-1" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem fade-in-bottom">
              <h2>Smart Travel Recommendations</h2>
              <div class="img100">
                <img src="assets/images/amex-small-img.webp" alt="AMEX (BDT)" width="512" height="175"
                  onerror="this.src='assets/images/amex-small-img.png'">
              </div>
              <p>For one the largest Banking and Financial Services client, we delivered a recommendation engine using Artificial Intelligence (AI) and Machine Learning (ML) techniques, to enable data-driven tailored travel recommendations to its customers with personalized travel options. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-2" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-right">
              <h2>Cloud Transformation</h2>
              <div class="img100">
                <img src="assets/images/cloud-transformation-small-img.webp" alt="Cloud Transformation" width="512"
                  height="175" onerror="this.src='assets/images/cloud-transformation-small-img.png'">
              </div>
              <p>With the mission of improved performance, scalability, security, resiliency, with agility for a multi-tenant platform, we help one of our clients in moving their entire technology landscape and infrastructure to cloud - AWS. The key to success was “minimal business disruption”. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-3" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>Cloud Support</h2>
              <div class="img100">
                <img src="assets/images/cloud-support-img.webp" alt="Cloud Support" width="512" height="175"
                  onerror="this.src='assets/images/cloud-support-img.png'">
              </div>
              <p>As part of their digital transformation journey, one of our clients aimed to transition their operations to the cloud to achieve greater agility, cost savings, and improved disaster recovery capabilities. We collaborated with client’s IT team to define a process-driven Managed Cloud Support services. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-5" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem fade-in-bottom">
              <h2>Cyber Security</h2>
              <div class="img100">
                <img src="assets/images/cyber-security-img.webp" alt="Cyber Security" width="512" height="175"
                  onerror="this.src='assets/images/cyber-security-img.png'">
              </div>
              <p>Client, a UK-based lending institution, recognized the increasing importance of robust
                cybersecurity
                measures to protect their sensitive data and maintain regulatory compliance. Facing evolving
                cyber
                threats, they sought the expertise of Skillikz Cybersecurity Solutions to fortify their
                security
                posture.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-6" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-right">
              <h2>Customer Journey Automation</h2>
              <div class="img100">
                <img src="assets/images/bdt-customer-journey-img.webp" alt="BDT Customer Journey" width="512"
                  height="175" onerror="this.src='assets/images/bdt-customer-journey-img.png'">
              </div>
              <p>Process Opacity, full of friction Customer Interaction with business users, and Process delays were the key challenges which were hindering the growth. We designed an API-led integrated and easy to use solution to connect disparate internal and external applications and provide a seamless customer experience. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-7" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>Staff Augmentation</h2>
              <div class="img100">
                <img src="assets/images/staff-augmentation-img.webp" alt="Staff Augmentation" width="512" height="175"
                  onerror="this.src='assets/images/staff-augmentation-img.png'">
              </div>
              <p>A prominent UK-based lending customer encountered challenges in maintaining its legacy and standalone applications. The Client sought the expertise of Skillikz to leverage staff augmentation for the deployment of Azure Cloud infrastructure and DevOps practices. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-8" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem fade-in-bottom">
              <h2>Technology Consulting</h2>
              <div class="img100">
                <img src="assets/images/tech-consulting-underwriting-img.webp" alt="Tech Consulting Underwriting"
                  width="512" height="175" onerror="this.src='assets/images/tech-consulting-underwriting-img.png'">
              </div>
              <p>Our client is a lending finance organization with an objective to transition from labor-intensive
                underwriting
                methods to a streamlined automated process leveraging cutting-edge technologies. The integration of
                predictive
                analysis, open banking data, credit agency insights, and cognitive services has significantly
                transformed the
                underwriting landscape.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-9" class="btn-blue" aria-label="Read Case Study">Read Case
                  Study</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-right">
              <h2>Customer Experience</h2>
              <div class="img100">
                <img src="assets/images/customer-experience-img.webp" alt="Customer Experience" width="512" height="175"
                  onerror="this.src='assets/images/customer-experience-img.png'">
              </div>
              <p>With our deep expertise and experience in the field of customer experience (CX), we helped a mid-sized e-commerce company that specializes in selling fashion apparel and accessories to improve their online presence and capture a larger customer base. We employed a holistic approach encompassing research, measurement, ideation, storyboarding, mental model analysis, and prototyping. </p>
              <div class="btn-fixed">
                <button type="button" routerLink="/case-study-10" class="btn-blue" aria-label="Read Case Study">Read
                  Case Study</button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>
<!--latest work ends-->

<!--digital exp strats-->
<section class="commonSection marginNone border-b">
  <div class="container-main">
    <div class="mb-60">
      <div class="items-col-1 mb-15">
      <h2 class="large">We Serve Globally</h2>
      </div>
      <div class="diagram-wp scroll-elem fade-in-bottom">
        <img src="assets/images/globally-img.svg" class="onlyWeb" alt="We Serve You Globally" width="1032" height="472">
        <img src="assets/images/globally-mbl-img.svg" class="onlyMobile" alt="We Serve You Globally" width="320"
          height="1030">
      </div>
    </div>


    <div class="mb-20">
      <h2 class="large">Read More About Us</h2>
      <div class="scroll-elem slide-left">
        <button type="button" class="btn btn-orange" routerLink="/who-we-are" aria-label="Quick Help">
          <span>Know More</span>
          <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
        </button>

      </div>
    </div>

  </div>
</section>




<section class="section-digi-exp onlyForWeb--">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-5 digi-exp-wp">
        <div class="digi-exp-img scroll-elem fade-in-bottom">
          <!-- <img src="assets/images/business_transformation_new.webp" alt="business transformation" width="337" height="551"> -->
          <img loading="lazy" src="assets/images/business_transformation_new.webp" alt="business transformation"
            width="337" height="551" onerror="this.src='assets/images/business_transformation_new.png'">
        </div>
      </div>
      <div class="items-col-7 items-middle">
        <div class="">
          <h2 class="mb-15 scroll-elem fade-in">Build digital experiences efficiently through our Product Engineering
            and Business
            Transformation expertise.</h2>
          <h3 class="subTitle scroll-elem fade-in">We specialize in Organizational development, with a rich pedigree in
            Information Technology. We offer </h3>
          <ul class="list-red scroll-elem slide-right">
            <li><span>Product Engineering & Development</span></li>
            <li><span>Application Modernisation</span></li>
            <li><span>Process Automation</span></li>
            <li><span>Operation Support & Services </span></li>
          </ul>
          <div class="btn-wrapper scroll-elem fade-in">
            <button type="button" class="btn btn-orange" routerLink="contact-us" aria-label="Get In Touch">
              <span>Get In Touch</span>
              <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
            </button>
            <button type="button" routerLink="business-digital-transformation"
              class="btn btn-outline-primary btn-outline-blue" aria-label="Go To Business Transformation">Go To Business
              Transformation</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--work with starts-->
<section class="section-logos">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1 mb-15"> 
        <h2 class="large">We work with</h2>
      </div>
      <div class="items-col-1">
        <div class="logos-wrapper scroll-elem fade-in-bottom">
          <ul>
            <li>
              <img loading="lazy" src="assets/images/logos/accenture-logo.webp" alt="accenture-logo" width="250"
                height="111" onerror="this.src='assets/images/logos/accenture-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/swiggy-logo.webp" alt="swiggy logo" width="250" height="111"
                onerror="this.src='assets/images/logos/swiggy-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/sapiens-logo.webp" alt="sapiens logo" width="250"
                height="111" onerror="this.src='assets/images/logos/sapiens-logo.png'">
            <li>
              <img loading="lazy" src="assets/images/logos/siksha-logo.webp" alt="siksha logo" width="250" height="111"
                onerror="this.src='assets/images/logos/siksha-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/articulate-logo.webp" alt="articulate logo" width="250"
                height="111" onerror="this.src='assets/images/logos/articulate-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/locus-logo.webp" alt="locus logo" width="250" height="111"
                onerror="this.src='assets/images/logos/locus-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/stl-logo.webp" alt="stl logo" width="250" height="111"
                onerror="this.src='assets/images/logos/stl-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/microsoft-logo.webp" alt="Microsoft Logo" width="250"
                height="111" onerror="this.src='assets/images/logos/microsoft-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/salesforce-logo.webp" alt="salesforce logo" width="250"
                height="111" onerror="this.src='assets/images/logos/salesforce-logo.png'">
            </li>
            <li>
              <img loading="lazy" src="assets/images/logos/mercedes-benz-logo.webp" alt="mercedes benz logo" width="250"
                height="111" onerror="this.src='assets/images/logos/mercedes-benz-logo.png'">
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="sectionForm">
  <div class="container-main">
    <div class="row-flex">
      <div class="custom-col-55">
        <div class="formContent scroll-elem slide-left">
          <h2>Looking to work with a world class digital consultancy?
          </h2>
          <p>  Ready to collaborate with a world-class digital consultancy?
            Together we can unleash your potential.
            
            Your unique needs deserve tailored solutions. Complete the form to contact us and take the first step towards a successful transformation.</p>
            
           <br> <h2 class="mb-15 scroll-elem fade-in">Build The Future Together </h2>
            <!-- <h2 class="mb-15 scroll-elem fade-in">BUILD THE FUTURE TOGETHER</h2> -->
          <!-- <p class="small">BUILD THE FUTURE TOGETHER</p>
            <br>
            <p class="small">We understand that my personal data given in the contact form above will be processed for purposes of answering my inquiry and for any further correspondence regarding this inquiry. The controller of your personal data at Skillikz. For more information, see our <a routerLink="privacy-policy" style="color: #ffa000;">Privacy Policy</a> .</p> -->
        </div>
      </div>
      <div class="custom-col-45">

        <!-- Contact Us form component -->
        <app-form></app-form>
      </div>
      <div>
        
      </div>
    </div>
  </div>
</section>
<!--our partners-->